import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { FiUpload } from "react-icons/fi";
import Select, { components } from "react-select";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function BreakDownForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [breakdowntData, setBreakdowntData] = useState({
    department_name: "",
    problem_desc: "",
    tag_no: "",
  });
  const handleDeleteFile = (indexToDelete) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToDelete)
    );
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);
  };

  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBreakdowntData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setBreakdowntData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
  };
  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleCancelClick = () => {
    navigate("/breakdown-schedules");
  };

  // const handleSaveClick = async () => {
  //   const validationErrors = {};
  //   let hasError = false;
  
  //   // Validate each field
  //   if (!breakdowntData.department_name) {
  //     validationErrors.department_name = "Department is required";
  //     hasError = true;
  //   }
  
  //   if (!selectedOption) {
  //     validationErrors.tag_no = "Tag Selection is required";
  //     hasError = true;
  //   }
  
  //   if (!breakdowntData.problem_desc) {
  //     validationErrors.problem_desc = "Problem Description is required";
  //     hasError = true;
  //   }
  
  //   if (hasError) {
  //     setErrors(validationErrors);
  //     setSeverity("warning");
  //     showSnackbar("Please correct the errors before saving");
  //     return;
  //   }
  
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");
  
  //     const response = await axios.post(
  //       `${API_URL}/api/add_breakdown_schedule`,
  //       {
  //         ...breakdowntData,
  //         tag_no: selectedOption ? selectedOption.label : "",
  //         // Include other necessary fields
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Breakdown added successfully");
  //       setTimeout(() => {
  //         navigate("/breakdown-schedules");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving Breakdown:", error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    const validationErrors = {};
    let hasError = false;
  
    // Validate each field
    if (!breakdowntData.department_name) {
      validationErrors.department_name = "Department is required";
      hasError = true;
    }
  
    if (!selectedOption) {
      validationErrors.tag_no = "Tag Selection is required";
      hasError = true;
    }
  
    if (!breakdowntData.problem_desc) {
      validationErrors.problem_desc = "Problem Description is required";
      hasError = true;
    }
  
    if (hasError) {
      setErrors(validationErrors);
      setSeverity("warning");
      showSnackbar("Please correct the errors before saving");
      return;
    }
  
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Prepare form data
      const formData = new FormData();
      formData.append("department_name", breakdowntData.department_name);
      formData.append("problem_desc", breakdowntData.problem_desc);
      formData.append("tag_no", selectedOption ? selectedOption.label : "");
  
      // Append each selected file
      attachmentFile.forEach((file) => {
        formData.append("Attachments", file); // Ensure API expects 'attachments'
      });
  
      const response = await axios.post(`${API_URL}/api/add_breakdown_schedule`, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data", // Required for file upload
        },
      });
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Breakdown added successfully");
        setTimeout(() => {
          navigate("/breakdown-schedules");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving Breakdown:", error.message);
      setSeverity("error");
      showSnackbar("Error occurred while saving breakdown");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_equipment`, {
          headers: {
            Authorization: authKey,
          },
        });
        // Assuming response.data is an array of equipment objects
        const optionsData = response.data.Items.map((item) => ({
          value: item.ID, // Unique value for each option
          label: item.TagNo, // Displayed text in the dropdown
        }));
        setOptions(optionsData);
      } catch (error) {
        console.error("Error fetching options:", error);
        // Handle specific error messages or set errors in state if needed
      }
    };
    fetchOptions();
  }, []);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New BreakDown Schedules
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="tag_no">
                    Tag Selection<span className="text-danger">*</span>
                  </label>

                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select a tag"
                  />
                  {errors.tag_no && (
                    <div className="error-message">{errors.tag_no}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="problem_desc">
                    Problem Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="problem_desc"
                      rows={4}
                      value={breakdowntData.problem_desc}
                      onChange={handleInputChange}
                      placeholder="Description"
                    />
                    {errors.problem_desc && (
                      <div className="error-message">{errors.problem_desc}</div>
                    )}
                  </div>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="attachment">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input type="file" name="attachment" />
                    {errors.attachment && (
                      <div className="error-message">{errors.attachment}</div>
                    )}
                  </div>
                </div> */}
                <div className="form-group">
                  <label htmlFor="Attachment">
                    Attachment <span className="text-danger">*</span>
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="Attachment"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                type="button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default BreakDownForm;
