import React, { useState } from "react";
import "./ExportPopup.css";
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

function ExportPopup({ isOpen, onClose, onExport, selectedFilter, selectedStartDate, selectedEndDate, anchorEl }) {
  const [selectedFormat, setSelectedFormat] = useState("csv");

  const handleExport = () => {
    onExport(selectedFormat, selectedFilter, selectedStartDate, selectedEndDate);
    onClose();
  };

  if (!isOpen || !anchorEl) return null;

  // Calculate the popup's position
  const { top, left, height } = anchorEl.getBoundingClientRect();


  return (
    <div className="export-popup-overlay" >
      <div className="export-popup-content">
        {/* <button className="export-popup-close" onClick={onClose}>
          <IoCloseOutline />
        </button> */}
        <div className="export-header">
        <div className="header-name">Select Export Format</div>
        <button className="export-popup-close" onClick={onClose}>
          <IoCloseOutline />
        </button>
        </div>
        <FormControl component="fieldset" className="format-group" style={{ padding: '2px 0' }}>
          <FormLabel component="legend">File Format:</FormLabel>
          <RadioGroup
            aria-label="file-format"
            name="file-format"
            value={selectedFormat}
            onChange={(e) => setSelectedFormat(e.target.value)}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
            <FormControlLabel value="xlsx" control={<Radio />} label="Excel" />
          </RadioGroup>
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '8px 0 10px 0' }}>
        <Button onClick={handleExport} variant="contained" color="primary">
          Export
        </Button>
        </div>
      </div>
    </div>
  );
}

export default ExportPopup;
