import React, { useState, useEffect, useRef, useCallback  } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import "./AllPreventiveSchedules.css";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { Button } from "@mui/material";
import debounce from 'lodash/debounce';
import { Typography, Modal, Paper } from "@mui/material";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Pagination from "../components/Pagination";

function AllAmcSchedules({ isSidebarExpanded }) {
  const [amcschedules, setAmcSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalSchedules, setTotalSchedules] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    amc_name: true,
    status: true,
    service_date: true,
    service_provider_name: true,
  });
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };


  const handleSort = (column) => {
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [column]: !prevSortOrders[column],
    }));
    setIsSortPopup(false);
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const statusOptions = [
    { value: "all", label: "All" },
    { value: "active", label: "Active" },
    { value: "hold", label: "Hold" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
    { value: "overdue", label: "Overdue" },
  ];
   const filteredUsers = amcschedules.filter((amcschedule) => {
    return (
      amcschedule.amc_name.toLowerCase().includes(searchInput.toLowerCase()) ||
      amcschedule.service_provider_name.toLowerCase().includes(searchInput.toLowerCase()) ||
      amcschedule.status.toLowerCase().includes(searchInput.toLowerCase()) ||
      amcschedule.service_date.toLowerCase().includes(searchInput.toLowerCase())
    );
  });

  const sortedAmcschedules = () => {
    if (sortColumn) {
      return filteredUsers.slice().sort((a, b) => {
        const first = a[sortColumn].toUpperCase();
        const second = b[sortColumn].toUpperCase();

        if (first < second) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (first > second) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredUsers;
  };
  const fetchSchedulesByStatus = async (status, currentPage, itemsPerPage) => {
    setLoading(true);
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_amc_schedules`;
  
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          status: status,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
  
      if (response.status === 200) {
        setAmcSchedules(response.data.schedules || []);
        setTotalItems(response.data.total); // Ensure TotalItems is used here

      } else {
        console.error(
          "Error fetching schedules by status:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching schedules by status:", error.message);
    } finally {
      setLoading(false);
    }
  };
 

  // useEffect to handle fetching schedules and setting page and itemsPerPage
   useEffect(() => {
    localStorage.setItem("selectedStatus", selectedStatus);
    const storedPage = localStorage.getItem("currentPage");
    const storedItemsPerPage = localStorage.getItem("itemsPerPage");

    const page = storedPage ? parseInt(storedPage, 10) : 0;
    const itemsPerPage = storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 50;
    setPage(page);
    setItemsPerPage(itemsPerPage);

    fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);
  }, [selectedStatus, page, itemsPerPage]); 

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };
  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleCloseModal = () => {
    setSelectedSchedule(null);
    setModalOpen(false);
  };

  const containerRef = useRef(null);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  // const handleSearchInputChange = (event) => {
  //   const value = event.target.value;
  //   setSearchInput(value);
  //   setSelectedStatus("all");
  //   debouncedSearch(value, page, itemsPerPage);
  // };
  

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? amcschedules.map((amcschedule) => amcschedule.id) : []
    );
    setChecked(newSelectAll);
  };
  const debouncedSearch = useCallback(
    debounce(async (query, currentPage, itemsPerPage) => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/search_amc_schedules`, {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query,
            page: currentPage,
            per_page: itemsPerPage,
          },
        });
  
        if (response.status === 200) {
          setAmcSchedules(response.data.Items || []);
          setTotalItems(response.data.TotalItems);
          setPage(response.data.CurrentPage);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }, 500),  // 500ms delay for debouncing
    []
  );

  // Handle search input changes and trigger debounced search
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    setSelectedStatus("all");
    debouncedSearch(value, page, itemsPerPage);
  };

  // Fetch data when the component mounts or on pagination changes
  useEffect(() => {
    if (!searchInput) {
      fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);
    }
  }, [selectedStatus, page, itemsPerPage, searchInput]);


  const handleSelectRow = (amcscheduleID) => {
    const updatedSelectedRows = selectedRows.includes(amcscheduleID)
      ? selectedRows.filter((id) => id !== amcscheduleID)
      : [...selectedRows, amcscheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === amcschedules.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleArchiveschedule = async () => {};

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleRefresh = () => {
    setSearchInput("");
    setSelectedStatus("all");
    fetchSchedulesByStatus(selectedStatus, page, itemsPerPage);

  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleViewClick = async (schedule_id) => {
    navigate(`/amc-scheduler-view/${schedule_id}`);
  };

  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveschedule}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All AMC Schedules
                  </div>

                  <div className="data-list-heading-content-right-side">
                    <div className="dropdown-container">
                      <Select
                        value={statusOptions.find(option => option.value === selectedStatus)}
                        onChange={handleChange}
                        options={statusOptions}
                        placeholder="Select Status"
                        className="project-dropdown"
                      />
                    </div>
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("amc_name")}>
                              Name{" "}
                              {sortOrders.amc_name ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button
                              onClick={() =>
                                handleSort("service_provider_name")
                              }
                            >
                              Coontractor Name{" "}
                              {sortOrders.service_provider_name ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrders.status ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("service_date")}>
                              Service Date{" "}
                              {sortOrders.service_date ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                          {/* <button className='delete-item' onClick={handleArchiveDeparment}>Archived Sub Department</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-bodys">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : amcschedules.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>NAME</th>
                          <th>CONTRACTOR NAME</th>
                          <th>SERVICE DATE</th>
                          <th>STATUS</th>
                          <th>SERVICES</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {/* {sortedAmcschedules.map((amcschedule, index) => ( */}
                           {sortedAmcschedules().map((amcschedule, index) => (
                          <tr key={index} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(amcschedule.id)}
                                onChange={() => handleSelectRow(amcschedule.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{amcschedule.amc_name}</td>
                            <td>{amcschedule.service_provider_name}</td>
                            <td>
                              {formatDate(amcschedule.service_date || "NA ")}
                            </td>
                            <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    amcschedule.status === "Hold"
                                      ? "#D4AC0D"
                                      : amcschedule.status === "Cancelled"
                                      ? "#F81700"
                                      : amcschedule.status === "not in tenure"
                                      ? "#313639"
                                      : amcschedule.status === "overdue"
                                      ? "#C0612B"
                                      : amcschedule.status === "active"
                                      ? "#2980B9"
                                      : amcschedule.status === "Completed"
                                      ? "#2E8B57"
                                      : "transparent",
                                }}
                              >
                                {amcschedule.status || "NA"}
                              </div>
                            </td>

                            <td>{amcschedule.services || "NA "}</td>

                            <td>
                              <button
                                onClick={() => handleViewClick(amcschedule.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Amc Schedules Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Paper className="modal-container">
            <div className="modal-header">
              <Typography variant="h6">Equipment Details</Typography>
            </div>
            <div className="modal-body">
              {selectedSchedule && (
                <div>
                  <p>Make: {selectedSchedule.Make}</p>
                  <p>Model: {selectedSchedule.Model}</p>
                  <p>
                    Year of Installation: {selectedSchedule.InstallationYear}
                  </p>
                  <p>Warranty Expiry: {selectedSchedule.WarrantyExp}</p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <Button variant="contained" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </Paper>
        </Modal>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AllAmcSchedules;
