import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import API_URL from "../Config";

function UpdateMatCodeLevelOneForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { l1_id } = useParams(); 

  const [levelData, setLevelData] = useState({
    level: "",
    description: "",
  });

  const [levelOptions, setLevelOptions] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: i.toString().padStart(2, "0"),
      label: i.toString().padStart(2, "0"),
    }))
  );

  useEffect(() => {
    // Fetch the existing Level 1 data
    const fetchLevelData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");

        const response = await axios.get(`${API_URL}/api/get_mat_code_level_one/${l1_id}`, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.status === 200) {
          setLevelData(response.data);
        }
      } catch (error) {
        console.error("Error fetching Level 1 data:", error.message);
        setSeverity("error");
        showSnackbar("An error occurred while fetching the data");
      }
    };

    fetchLevelData();
  }, [l1_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLevelData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", 
    }));
  };

  const handleLevelChange = (selectedOption) => {
    setLevelData((prevState) => ({
      ...prevState,
      level: selectedOption ? selectedOption.value : "",
    }));
  
    setErrors((prevState) => ({
      ...prevState,
      level: "",
    }));
  };

  const handleCancelClick = () => {
    navigate("/equipment-type-mat-code-form");
  };

  const handleUpdateClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(levelData).forEach((key) => {
      if (!levelData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });

    if (error) {
      showSnackbar("Required fields are empty");
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.put(
        `${API_URL}/api/update_mat_code_level_one/${l1_id}`,
        levelData, 
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Level 1 updated successfully");
        setTimeout(() => {
          navigate("/equipment-type-mat-code-form");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating Level 1:", error.message);
      setSeverity("error");
      showSnackbar("An error occurred while updating");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Edit Level 1
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
              <div className="form-group-select">
                  <label htmlFor="level">
                    Level 1<span className="text-danger">*</span>
                  </label>
                 
                    <Select
                      name="level"
                      value={levelOptions.find(
                        (option) => option.value === levelData.level
                      )}
                      onChange={handleLevelChange}
                      options={levelOptions}
                      placeholder="Select Level 1"
                      isClearable
                    />
                    {errors.level && (
                      <div className="error-message">{errors.level}</div>
                    )}
                  
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="description"
                      value={levelData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                    {errors.description && (
                      <div className="error-message">
                        {errors.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleUpdateClick}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UpdateMatCodeLevelOneForm;
