import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import Select, { components } from 'react-select';
import API_URL from "../../src/Config";
// import "./DepartmentPopup.css";
import axios from 'axios';
import { CiCirclePlus } from "react-icons/ci";

const { Control, Menu } = components;

const AddEquipmentSubType = ({ closePopup, parentSelectedEquipmentType, handleNewEquipmentSubType  }) => {
 
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(parentSelectedEquipmentType);
    const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
    const [equipmentSubTypeData, setEquipmentSubTypeData] = useState({
      EquipmentType: parentSelectedEquipmentType ? parentSelectedEquipmentType.label : '',
      EquipmentSubType: '',
      EquipmentTypeId: parentSelectedEquipmentType ? parentSelectedEquipmentType.value : '',
  });
  
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setEquipmentSubTypeData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
    
    

    const handleSaveClick = async () => {
      const updatedEquipmentSubTypeData = {
        ...equipmentSubTypeData,
      };
  
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem('authKey');
  
  
        const response = await axios.post(
          `${API_URL}/api/add_equipment_sub_type`,
          updatedEquipmentSubTypeData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  


        console.log(response.data.message);
        if (response.status === 200) {
          const newSubEquipment = {
            label: equipmentSubTypeData.EquipmentSubType,
            value: response.data.id,
          };
          handleNewEquipmentSubType(newSubEquipment)
          showSnackbar('Equipment Sub Type Data addded successfully');
         
          closePopup();
        }
      } catch (error) {
        console.error('Error saving equipment sub type data:', error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };
  
    const showSnackbar = (message) => {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    };
  
    const handleEquipmentTypeMenuOpen = () => {
      setEquipmentTypeMenuIsOpen(true);
    };
  
    const CustomControl = ({ children, ...props }) => (
      <Control {...props}>{children}</Control>
    );
    // const CustomMenu = ({ children, ...props }) => (
    //   <Menu {...props}>
    //     {children}
    //     <button
    //       type="button"
    //       className="add-company-button"
    //       // onClick={handleAddNewPerson}
    //     >
    //       <CiCirclePlus className="new-plus-circle" />
    //       Add Equipment Type
    //     </button>
    //   </Menu>
    // );
  
    const handleEquipmentTypeChange = (selectedOption) => {
      setSelectEdequipmentType(selectedOption);
      setEquipmentSubTypeData((prevFormData) => ({
        ...prevFormData,
        EquipmentType: selectedOption.label,
        EquipmentTypeId: selectedOption.value,
        
      }));
    };
  
    const fetchEquipmentType = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_equipment_types`;
    
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          // Map the API response to the format expected by react-select
          const options = data.map((equipmentType) => ({
            label: equipmentType.EquipmentType,
            value: equipmentType.id
          }));
          setEquipmentTypes(options);
        } else {
          console.error("Failed to fetch Equipment Type");
        }
      } catch (error) {
        console.error("An error occurred while fetching Equipment Type:", error);
      }
    };
    
    
    useEffect(() => {
      fetchEquipmentType();
    }, []);
 
  return (
    <div className="inquiry-customerperson-popup-content">
      <div className="inquiry-customerperson-modal">
        <div className="inquiry-customerperson-modal-content">
          <div className='customerpersonpopupform-header'>
            <h4 className='customerpersonpopupform-title'>New Equipment Sub Type</h4>
            <span className='customerpersonpopupform-btn' onClick={closePopup}><IoCloseOutline /></span>
          </div>
          <div className="customerpersonpopupform-body">
          <form>
          <div className="customerpersonpopup-form">
          <div className="form-group-select">
                  <label htmlFor="EquipmentType">Equipment Type<span className="text-danger">*</span></label>
               
                  <Select
                    id="EquipmentTypee"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{ Control: CustomControl, }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                  />
                
                </div>
                <div className="form-group">
                  <label htmlFor="EquipmentSubType">Name<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="EquipmentSubType"
                      value={equipmentSubTypeData.EquipmentSubType}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
              </div>
            </form>
          </div>
          <div className="customerpersonpopupform-footer">
            <div className="button-group">
              <button className="customerpersonpopupform-save-button"   onClick={handleSaveClick}
                disabled={isLoading} >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
              <button className="customerpersonpopupform-cancel-button"     onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEquipmentSubType;
