import React, { useState, useEffect } from "react";
import "./History.css";
import Select from "react-select";
import axios from "axios";
import API_URL from "../../src/Config";
import { IoSearchOutline } from "react-icons/io5";
import moment from "moment-timezone";

function History({ isSidebarExpanded }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [historys, setHistorys] = useState([]);
  const [isDropdownAtTop, setIsDropdownAtTop] = useState(false); // State to track dropdown position

  // Fetch options for the dropdown (Equipment Tags)
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_equipment_tag_no`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const optionsData = response.data.Items.map((item) => ({
          value: item.ID,
          label: item.TagNo,
        }));
        setOptions(optionsData);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
    fetchOptions();
  }, []);

  // Fetch maintenance history data based on selected TagNo
  const handleChange = async (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      try {
        setLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_maintenance_history_by_tag/${selectedOption.value}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        setHistorys(response.data);
        setIsDropdownAtTop(true);
      } catch (error) {
        console.error("Error fetching maintenance history data:", error);
        setHistorys([]);
      } finally {
        setLoading(false);
      }
    } else {
      // Clear history when no option is selected
      setHistorys([]);
    }
  };

  const formatDate = (dateString) => {
    try {
      return moment.utc(dateString).local().format("DD/MM/YYYY hh:mm");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "NA";
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Clear data if there's no search input
      if (searchInput.trim() === "") {
        setHistorys([]);
        setTotalItems(0);
        return;
      }

      // Call search_maintenance_history API if searchInput is not empty
      const searchHistoryResponse = await axios.get(
        `${API_URL}/api/search_maintenance_history`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (searchHistoryResponse.status === 200) {
        const data = searchHistoryResponse.data.Items || [];
        setHistorys(data);
        setTotalItems(searchHistoryResponse.data.TotalItems || 0);
        setPage(searchHistoryResponse.data.CurrentPage || 1);
      } else {
        console.error(
          "Error fetching search data:",
          searchHistoryResponse.statusText
        );
        setHistorys([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setHistorys([]);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch(page, itemsPerPage);
    }, 600);

    return () => clearTimeout(delay);
  }, [searchInput, page, itemsPerPage]);

  const formatDates = (dateString) => {
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "NA";
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  return (
    <div
      className={`data-list-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-list-header">
        <div className="data-list-header-content">
          <div className="data-list-heading-content-left-side">
            Maintenance History
          </div>
          <div className="data-list-heading-content-right-sides">
            <div className="search-bar-container-history">
              <input
                type="text"
                placeholder="Search History..."
                value={searchInput}
                onChange={handleSearchInputChange}
                className="search-bar"
              />
              <IoSearchOutline className="search-bar-icon" />
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
      </div>
      <div className="body-separators"></div>
      <div className="dropdown-container-history-body">
        <div
          className={`dropdown-container-history ${
            isDropdownAtTop ? "at-top" : "centered"
          }`}
        >
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Please enter tag no"
            isClearable
          />
        </div>
      </div>
      {historys.length > 0 && (
        <div className="data-list-bodys">
          <div className="data-list-scroll-bodys-history">
            <div className="data-list-table-report">
              <table className="table multilevel-data">
                <thead className="data-list-head">
                  <tr>
                    <th>SR. NO.</th>
                    <th>FILE NAME</th>
                    <th>TAG NO</th>
                    <th>ACTIVITY</th>
                    <th>SCHEDULE TYPE</th>
                    <th>SERVICE DATE</th>
                    <th>DATE & TIME</th>
                  </tr>
                </thead>
                <tbody className="data-list-scroll-bodys">
                  {historys.map((item, index) => (
                    <tr key={item.id} className="hoverable-row">
                      <td>{index + 1}</td>
                      <td>{item.attachments.filename || "NA"}</td>
                      <td>{item.TagNo || "NA"}</td>
                      <td>{item.activity || "NA"}</td>
                      <td>{item.schedule_type || "NA"}</td>
                      <td>{formatDates(item.service_date || "NA")}</td>
                      <td>{formatDate(item.time || "NA")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default History;
