import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Select, { components } from "react-select";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

const { Control, Menu } = components;

function EditProject({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectTypes, setProjectTypes] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [projectData, setProjectData] = useState({
    project_name: "",
    customer_name: "",
    customer_location: "",
    po_number: "",
    end_date: null,
    project_type: "",
    start_date: null,
    location_id: null,
  });
  

  const [selectedProjectType, setSelectedProjectType] = useState(null);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [customerMenuIsOpen, setCustomerMenuIsOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_project/${id}`, {
          headers: {
            Authorization: authKey,
          },
        });
        const responseData = response.data;
  
        // Set project data and selected customer
        setProjectData({
          ...responseData,
          start_date: new Date(responseData.start_date),
          end_date: new Date(responseData.end_date),
        });
        setSelectedStartDate(new Date(responseData.start_date));
        setSelectedEndDate(new Date(responseData.end_date));
  
        // Set selected project type
        setSelectedProjectType({
          value: responseData.project_type_id,
          label: responseData.project_type,
        });
  
        // Set selected customer
        setSelectedCustomer({
          value: responseData.customer_id,
          label: responseData.customer_name,
        });
  
        // Fetch locations for the selected customer
        const locationResponse = await axios.get(
          `${API_URL}/api/get_locations/${responseData.customer_id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  
        const locationOptions = locationResponse.data.map((location) => ({
          value: location.id,
          label: location.location,
        }));
  
        setLocations(locationOptions);
        setSelectedLocation({
          label: responseData.customer_location,
        });
      } catch (error) {
        console.error("Error fetching project data:", error.message);
        setError("An error occurred while fetching project data");
      }
    };
  
    fetchProjectData();
  }, [id]);
  
  
  const fetchProjectType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_project_types`, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log(response.data);

      const projectTypeOptions = response.data.map((project) => ({
        value: project.id,
        label: project.type_name, 
      }));

      setProjectTypes(projectTypeOptions);
    } catch (error) {
      console.error("Error fetching project types data:", error.message);
    }
  };

  useEffect(() => {
    fetchProjectType();
  }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/project");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
    
   
  
  //   if (!error) {
  //     try {
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");
  
  //       // Format the dates before adding them to the payload
  //       const formattedStartDate = selectedStartDate ? format(new Date(selectedStartDate), 'yyyy-MM-dd') : '';
  //       const formattedEndDate = selectedEndDate ? format(new Date(selectedEndDate), 'yyyy-MM-dd') : '';
  
  //       const payload = {
  //         ...projectData,
  //         start_date: formattedStartDate,
  //         end_date: formattedEndDate,
  //         location_id: selectedLocation.value,
  //       };
  
  //       const response = await axios.put(
  //         `${API_URL}/api/update_project/${id}`,
  //         payload,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );
  
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Project updated successfully");
  //         setTimeout(() => {
  //           navigate("/project");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error updating project:", error.message);
  //       setError("An error occurred while updating project data");
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;
  
    // Perform form validation
    if (!projectData.project_name ||
        !projectData.customer_name ||
        !projectData.customer_location ||
        !projectData.po_number ||
        !selectedProjectType ||
        !selectedStartDate ||
        !selectedEndDate ||
        !selectedLocation) {
      error = true;
      setSeverity("error");
      showSnackbar("All fields are required"); // Show error message with red color
    }
  
    if (!error) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
  
        // Format the dates before adding them to the payload
        const formattedStartDate = selectedStartDate ? format(new Date(selectedStartDate), 'yyyy-MM-dd') : '';
        const formattedEndDate = selectedEndDate ? format(new Date(selectedEndDate), 'yyyy-MM-dd') : '';
  
        const payload = {
          ...projectData,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          project_type_id: selectedProjectType.value,
          customer_id: selectedCustomer.value,
          location_id: selectedLocation.value,
        };
  
        const response = await axios.put(
          `${API_URL}/api/update_project/${id}`,
          payload,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  
        if (response.status === 200) {
          setSeverity("success"); // Set severity back to "success" for success message
          showSnackbar("Project updated successfully");
          setTimeout(() => {
            navigate("/project");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating project:", error.message);
        setError("An error occurred while updating project data");
        setIsLoading(false);
      }
    }
  };
  
  
  
  const handleBackProject = () => {
    navigate("/project");
  };
  const handleChange = (selectedOption) => {
    setSelectedProjectType(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      project_type_id: selectedOption.value,
      project_type: selectedOption.label,
    }));
  };
  const handleCustomerMenuOpen = () => {
    setCustomerMenuIsOpen(true);
  };
  const handleCustomerChange = async (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      customer_name: selectedOption.label,
      customer_id: selectedOption.value,
      customer_location: "", // Clear previous location selection
    }));
  
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_locations/${selectedOption.value}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      console.log("Locations response data:", response.data); // Debugging log
  
      const locationOptions = response.data.map((location) => ({
        value: location.id,
        label: location.location, // Map `location.location` to `label`
      }));
  
      setLocations(locationOptions);
      console.log("Updated locations state:", locationOptions); // Debugging log
    } catch (error) {
      console.error("Error fetching locations data:", error.message);
      // Handle error state or notify the user
    }
  };
  
  const fetchCustomerData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_customers`, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log(response.data);

      // Assuming response.data is an array of customer objects with locations
      const customerOptions = response.data.map((customer) => ({
        value: customer.id,
        label: customer.customer_name,
      
      }));

      setCustomers(customerOptions);
    } catch (error) {
      console.error("Error fetching customers data:", error.message);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
    setProjectData((prevState) => ({
      ...prevState,
      customer_location: selectedOption ? selectedOption.label : "",
    }));
  };
  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setProjectData((prevState) => ({
      ...prevState,
      start_date: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setProjectData((prevState) => ({
      ...prevState,
      end_date: date,
    }));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Project
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="project_name">
                    Project Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="project_name"
                      value={projectData?.project_name || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="customer_name">
                    Customer Name<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="customer_name"
                    options={customers}
                    value={selectedCustomer}
                    onChange={handleCustomerChange}
                    components={{}}
                    placeholder="Select Customer"
                    isSearchable
                    menuIsOpen={customerMenuIsOpen}
                    onMenuOpen={handleCustomerMenuOpen}
                    onMenuClose={() => setCustomerMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="customer_location">
                    Customer Location<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="customer_location"
                    options={locations}
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    placeholder="Select Location"
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="PONumber">
                    PO Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                  <input
                      type="text"
                      name="po_number"
                      value={projectData.po_number}
                      onChange={handleInputChange}
                      placeholder="Enter PO number"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="ProjectType">
                    Project Type<span className="text-danger">*</span>
                  </label>
                  <Select
    id="projectType"
    options={projectTypes}
    value={selectedProjectType}
    onChange={handleChange}
    placeholder="Select project type"
    isSearchable
  />
                </div>
             
                <div className="form-group">
                  <label htmlFor="start_date">
                    Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="start_date"
                      id="start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select start date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="end_date">
                    End Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="end_date"
                      id="end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select end date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              
                
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditProject;
