import React, { useState, useRef } from "react";
// import "./ReportPage.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Level1 from "../Matcode/Level1";
import Level2 from "../Matcode/Level2";
import Level3 from "../Matcode/Level3";
import { Button, TextField, Tabs, Tab } from "@mui/material";

function ReportPage({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State for tab navigation
  const containerRef = useRef(null);


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
     
          
          <div className="data-list-content" ref={containerRef}>
             <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Level1" />
              <Tab label="Level2" />
              <Tab label="Level3" />
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
            {tabValue === 0 && <div><Level1/></div>}
              {tabValue === 1 && <div><Level2/></div>}
             
              {tabValue === 2 && <div><Level3/></div>}
            </div>
          </div>
    
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ReportPage;
