import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./FrequencyConfig.css";
import "./EquipmentTypeVariantForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import AddDepartmentPopup from "../popup/AddDepartmentPopup";
import AddEquipmentType from "../popup/AddEquipmentType";
import AddEquipmentSubType from "../popup/AddEquipmentSubType";
import AddSubTypeVariant from "../popup/AddSubTypeVariant";
import AddSubDepartmentPopup from "../popup/AddSubDepartmentPopup";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Button, TextField, Tabs, Tab } from "@mui/material";

const { Control, Menu } = components;

function FrequencyConfig({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [
    EquipmentSubTypeVariantsMenuIsOpen,
    setEquipmentSubTypeVariantsMenuIsOpen,
  ] = useState(false);
  const [showSubTypeVariantPopup, setShowSubTypeVariantPopup] = useState(false);
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [EquipmentSubTypeMenuIsOpen, setEquipmentSubTypeMenuIsOpen] =
    useState(false);
  const [tabValue, setTabValue] = useState(0); // State for tab navigation
  const containerRef = useRef(null);
  const [equipmentSubTypeVariantData, setEquipmentSubTypeVariantData] =
    useState({
      EquipmentSubType: "",
      EquipmentSubTypeId: "",
      SubTypeVariant: null,
      VariantNameId: "",
      oisd_frequencies: [
        { OISDPoint: "", Frequency: "", Description: ""},
      ],
      factory_act_frequencies: [
        { factory_act_rule_no: "", Frequency: "" },
      ],
      oem_frequencies: [{ Frequency: ""}],
      peso_frequencies: [
        { peso_rule_no: "", Frequency: "" },
      ],
      DepartmentId: "",
      DepartmentName: "",
      EquipmentType: "",
      EquipmentTypeId: "",
      SubDepartmentId: "",
      SubDepartmentName: "",
      // oisd_no: "",
    });
  const [showSubTypePopup, setShowSubTypePopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedSubDepartmentId, setSelectedSubDepartmentId] = useState(null);
  const [currentOpenPopup, setCurrentOpenPopup] = useState(null);
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [showequipmentTypePopup, setShowequipmentTypePopup] = useState(false);
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);
  const [departments, setDepartments] = useState([]);
  const [departmentError, setDepartmentError] = useState("");
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [subDepartmentMenuIsOpen, setSubDepartmentMenuIsOpen] = useState(false);
  const [showSubDepartmentPopup, setShowSubDepartmentPopup] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedDepartment) {
      setSelectedDepartment(null);
    }
  };

  const closeEquipmentTypePopup = () => {
    setShowequipmentTypePopup(false);
  };

  const CustomSubControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Sub Type
      </button>
    </Menu>
  );

  const CustomVariantControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomVariantMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewTypeVariant}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Sub Type Variant
      </button>
    </Menu>
  );

  const handleAddNewTypeVariant = () => {
    setShowSubTypeVariantPopup(true);
    setEquipmentSubTypeVariantsMenuIsOpen(false);
  };
  const handleNewEquipmentSubTypeVariant = (newSubTypeVariant) => {
    setEquipmentTypeVariants((prevEquipmentTypeVariants) => [
      ...prevEquipmentTypeVariants,
      newSubTypeVariant,
    ]);
    setSelectEdequipmentTypeVariants(newSubTypeVariant);
    setEquipmentSubTypeVariantData((prevEquipmentTypeVariants) => ({
      ...prevEquipmentTypeVariants,
      SubTypeVariant: newSubTypeVariant.label,
      VariantNameId: newSubTypeVariant.value,
    }));
    showSnackbar("Equipment sub type variant added successsfully");
  };
  const closeEquipmentTypeVariantPopup = () => {
    setShowSubTypeVariantPopup(false);
  };

  const handleSubDepartmentKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedSubDepartment) {
      setSelectedSubDepartment(null);
    }
  };

  const handleTypeKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedEquipmentTypes) {
      setSelectEdequipmentType(null);
    }
  };
  const handleSubTypeKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedEquipmentSubType) {
      setSelectedEquipmentSubType(null);
    }
  };
  const handleTypeVariantsKeyDown = (event) => {
    if (
      event.key === "Backspace" &&
      !inputValue &&
      selectedEquipmentSubTypeVariants
    ) {
      setSelectEdequipmentTypeVariants(null);
    }
  };

  const closeSubDepartmentPopup = (newSubDepartment) => {
    setShowSubDepartmentPopup(false);
    fetchSubDepartments();
    if (newSubDepartment) {
      // If a new sub-department is added
      const updatedSubDepartment = {
        label: newSubDepartment.SubDepartment,
        value: newSubDepartment.id,
      };
      setSelectedSubDepartment(updatedSubDepartment);
    }
  };

  const handleNewEquipmentType = (newEquipment) => {
    setEquipmentTypes((prevEquipmentTypes) => [
      ...prevEquipmentTypes,
      newEquipment,
    ]);
    setSelectEdequipmentType(newEquipment);
    setEquipmentSubTypeVariantData((prevEquipmentSubTypes) => ({
      ...prevEquipmentSubTypes,
      EquipmentType: newEquipment.label,
      EquipmentTypeId: newEquipment.value,
    }));
    showSnackbar("Equipment type added successsfully");
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleCancelClick = () => {
    navigate("/frequency");
  };

  const CustomSubDepartmentControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomSubDepartmentMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewSubDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Sub Department
      </button>
    </Menu>
  );
  const handleAddNewSubDepartment = () => {
    setShowSubDepartmentPopup(true);
    setSubDepartmentMenuIsOpen(false);
  };

  const CustomTypeControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomTypeMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddEquipmentType}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Equipment Type
      </button>
    </Menu>
  );
  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );

  const handleAddNewDepartment = () => {
    setShowDepartmentPopup(true);
    setDepartmentMenuIsOpen(false);
  };

  const handleAddEquipmentType = () => {
    setShowequipmentTypePopup(true);
    setEquipmentTypeMenuIsOpen(false);
  };

  const handleDepartmentMenuOpen = () => {
    closeAllPopups();
    setDepartmentMenuIsOpen(true);
    setCurrentOpenPopup("department");
  };

  const closeAllPopups = () => {
    setDepartmentMenuIsOpen(false);
    setSubDepartmentMenuIsOpen(false);
    setEquipmentTypeMenuIsOpen(false);
    setEquipmentSubTypeMenuIsOpen(false);
  };

  const handleAddFrequencyRow = () => {
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      oisd_frequencies: [
        ...prevData.oisd_frequencies,
        { OISDPoint: "", Frequency: "", Description: "" },
      ],
    }));
  };
  
  // Add a new Factory Act frequency row
  const handleAddFactoryActRow = () => {
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      factory_act_frequencies: [
        ...prevData.factory_act_frequencies,
        { factory_act_rule_no: "", Frequency: "" },
      ],
    }));
  };
  const handleAddOEMRow = () => {
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      oem_frequencies: [
        ...prevData.oem_frequencies,
        { Frequency: "" },
      ],
    }));
  };
  const handleAddPESORow = () => {
    setEquipmentSubTypeVariantData((prevData) => ({
      ...prevData,
      peso_frequencies: [
        ...prevData.peso_frequencies,
        { peso_rule_no: "", Frequency: "" },
      ],
    }));
  };

const handleCloseFrequencyRow = (index) => {
  setEquipmentSubTypeVariantData((prevData) => {
    const newFrequencies = prevData.oisd_frequencies.filter(
      (_, i) => i !== index
    );
    return { ...prevData, oisd_frequencies: newFrequencies };
  });
};

// Remove a Factory Act frequency row
const handleCloseFectoryActRow = (index) => {
  setEquipmentSubTypeVariantData((prevData) => {
    const newFrequencies = prevData.factory_act_frequencies.filter(
      (_, i) => i !== index
    );
    return { ...prevData, factory_act_frequencies: newFrequencies };
  });
};

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  const closeDepartmentPopup = () => {
    setShowDepartmentPopup(false);
  };

  const handleNewDepartment = (newDepartment) => {
    if (newDepartment) {
      setDepartments((prevDepartments) => [...prevDepartments, newDepartment]);
      setSelectedDepartment(newDepartment);
      setEquipmentSubTypeVariantData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: newDepartment.label,
        DepartmentId: newDepartment.value,
      }));
      showSnackbar("Department added successfully");
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const fetchSubDepartments = async () => {
    try {
      if (selectedDepartment) {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department/${selectedDepartment.value}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const options = data.map((subDepartment) => ({
            label: subDepartment.Name,
            value: subDepartment.id,
          }));
          setSubDepartments(options);
        } else {
          console.error("Failed to fetch sub-departments");
        }
      } else {
        setSubDepartments([]);
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments();
      setSelectedSubDepartment(null);
    } else {
      setSubDepartments([]);
      setSelectedSubDepartment(null);
    }
  }, [selectedDepartment]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
    setSelectedEquipmentSubType(null);
    setSelectEdequipmentTypeVariants(null);
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    fetchSubDepartments(selectedOption.value);
    setSelectedSubDepartment(null);
    setSelectEdequipmentType(null);
    setSelectedEquipmentSubType(null);
    setDepartmentError("");
  };

  const handleEquipmentTypeMenuOpen = () => {
    closeAllPopups();
    setEquipmentTypeMenuIsOpen(true);
    setCurrentOpenPopup("equipmentType");
  };

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectEdequipmentTypeVariants(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      SubTypeVariant: selectedOption.label,
      VariantNameId: selectedOption.value,
    }));
  };

  const handleSubDepartmentMenuOpen = () => {
    closeAllPopups();
    setSubDepartmentMenuIsOpen(true);
    setCurrentOpenPopup("subDepartment");
  };

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  const fetchEquipmentSubTypeVariants = async (equipmentSubTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_type_variants_by_sub_type/${equipmentSubTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubTypeVariant) => ({
          label: equipmentSubTypeVariant.VariantName,
          value: equipmentSubTypeVariant.id,
        }));
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentSubType) {
      fetchEquipmentSubTypeVariants(selectedEquipmentSubType.value);
    }
  }, [selectedEquipmentSubType]);

  useEffect(() => {
    if (selectedSubDepartmentId) {
      fetchEquipmentTypes(selectedSubDepartmentId);
    }
  }, [selectedSubDepartmentId]);

  // const handleSaveClick = async () => {
  //   const updatedSubTypeVariantData = { ...equipmentSubTypeVariantData };

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/add_frequency_config`,
  //       updatedSubTypeVariantData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Frequency configuration added successfully");
  //       setTimeout(() => {
  //         navigate("/frequency");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving user:", error.message);
  //     // Handle error (e.g., show an error message to the user)
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const handleSaveClick = async () => {
  //   setDepartmentError("");

  //   // Validate department selection
  //   if (!selectedDepartment) {
  //     setDepartmentError("Department is required");
  //     return;
  //   }
  //   // Filter out the empty frequency rows
  //   const filteredFrequencies =
  //     equipmentSubTypeVariantData.oisd_frequencies.filter(
  //       (frequency) =>
  //         frequency.OISDPoint.trim() !== "" ||
  //         frequency.Frequency.trim() !== "" ||
  //         frequency.Description.trim() !== "" ||
  //         frequency.oisd_no.trim() !== ""
  //     );

  //   // Create a new data object with the filtered frequencies
  //   const updatedSubTypeVariantData = {
  //     ...equipmentSubTypeVariantData,
  //     oisd_frequencies: filteredFrequencies,
  //   };

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const response = await axios.post(
  //       `${API_URL}/api/add_frequency_config`,
  //       updatedSubTypeVariantData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Frequency configuration added successfully");
  //       setTimeout(() => {
  //         navigate("/frequency");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving user:", error.message);
  //     // Handle error (e.g., show an error message to the user)
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSaveClick = async () => {
    setDepartmentError("");
  
    // Validate department selection
    if (!selectedDepartment) {
      setDepartmentError("Department is required");
      return;
    }
  
    // Filter out empty OISD frequency rows (without using trim)
    const filteredOisdFrequencies = equipmentSubTypeVariantData.oisd_frequencies.filter(
      (frequency) =>
        frequency.OISDPoint !== "" ||
        frequency.Frequency !== "" ||
        frequency.Description !== "" ||
        (frequency.oisd_no && frequency.oisd_no !== "")
    );
  
    // Assign `frequency_type: 'oisd'` to each OISD frequency
    const updatedOisdFrequencies = filteredOisdFrequencies.map((frequency) => ({
      ...frequency,
      frequency_type: "oisd", // Set frequency type to oisd
    }));
  
    // Filter out empty Factory Act frequency rows (without using trim)
    const filteredFactoryActFrequencies =
      equipmentSubTypeVariantData.factory_act_frequencies.filter(
        (frequency) =>
          frequency.factory_act_rule_no !== "" || frequency.Frequency !== ""
      );
  
    // Assign `frequency_type: 'factory_act'` to each Factory Act frequency
    const updatedFactoryActFrequencies = filteredFactoryActFrequencies.map(
      (frequency) => ({
        ...frequency,
        frequency_type: "factory_act", // Set frequency type to factory_act
      })
    );

    const filteredOEMFrequencies =
      equipmentSubTypeVariantData.oem_frequencies.filter(
        (frequency) =>
           frequency.Frequency !== ""
      );
  
    // Assign `frequency_type: 'factory_act'` to each Factory Act frequency
    const updatedOEMFrequencies = filteredOEMFrequencies.map(
      (frequency) => ({
        ...frequency,
        frequency_type: "oem", // Set frequency type to factory_act
      })
    );
  

    const filteredPESOFrequencies =
    equipmentSubTypeVariantData.peso_frequencies.filter(
      (frequency) =>
         frequency.Frequency !== ""
    );

  // Assign `frequency_type: 'factory_act'` to each Factory Act frequency
  const updatedPESOFrequencies = filteredPESOFrequencies.map(
    (frequency) => ({
      ...frequency,
      frequency_type: "peso", // Set frequency type to factory_act
    })
  );


  
    // Create a new data object with the updated frequencies
    const updatedSubTypeVariantData = {
      ...equipmentSubTypeVariantData,
      oisd_frequencies: updatedOisdFrequencies, // Update OISD frequencies with type
      factory_act_frequencies: updatedFactoryActFrequencies,
      oem_frequencies: updatedOEMFrequencies, // Update Factory Act frequencies with type
      peso_frequencies: updatedPESOFrequencies,
    };
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Make the API request with the updated data
      const response = await axios.post(
        `${API_URL}/api/add_frequency_config`,
        updatedSubTypeVariantData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Frequency configuration added successfully");
        setTimeout(() => {
          navigate("/frequency");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleEquipmentSubTypeMenuOpen = () => {
    setEquipmentSubTypeMenuIsOpen(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const CustomControl = ({ children, ...props }) => (
  //   <Control {...props}>{children}</Control>
  // );
  // const CustomMenu = ({ children, ...props }) => (
  //   <Menu {...props}>
  //     {children}
  //     <button
  //       type="button"
  //       className="add-company-button"
  //       onClick={handleAddNewSubType}
  //     >
  //       <CiCirclePlus className="new-plus-circle" />
  //       Add Equipment Sub Type
  //     </button>
  //   </Menu>
  // );
  const handleAddNewSubType = () => {
    setShowSubTypePopup(true);
    setEquipmentSubTypeMenuIsOpen(false);
  };

  const handleNewSubDepartment = (newSubDepartment) => {
    setSubDepartments((prevSubDepartments) => [
      ...prevSubDepartments,
      newSubDepartment,
    ]);
    setSelectedSubDepartment(newSubDepartment);
    setEquipmentSubTypeVariantData((prevSubDepartments) => ({
      ...prevSubDepartments,
      SubDepartmentName: newSubDepartment.label,
      SubDepartmentId: newSubDepartment.value,
    }));

    showSnackbar("Sub Department added successfully");
  };
  const closeSubTypePopup = (newSubType) => {
    setShowSubTypePopup(false);
    if (newSubType) {
      setEquipmentSubTypes((prevDepartments) => [
        ...prevDepartments,
        newSubType,
      ]);
      setSelectedEquipmentSubType(newSubType);
      setEquipmentSubTypeVariantData((prevFormData) => ({
        ...prevFormData,
        EquipmentSubType: newSubType.label,
        EquipmentSubTypeId: newSubType.value,
      }));
    }
  };

  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setEquipmentSubTypeVariantData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
  };

  // const handleNewEquipmentSubType = (newSubEquipment) => {
  //   setEquipmentSubTypes((prevEquipmentSubTypes) => [
  //     ...prevEquipmentSubTypes,
  //     newSubEquipment,
  //   ]);

  //   const updatedEquipmentSubType = {
  //     label: newSubEquipment.label,
  //     value: newSubEquipment.value,
  //   };

  //   setSelectedEquipmentSubType(updatedEquipmentSubType);
  // };

  const handleNewEquipmentSubType = (newSubEquipment) => {
    setEquipmentSubTypes((prevEquipmentSubTypes) => [
      ...prevEquipmentSubTypes,
      newSubEquipment,
    ]);
    setSelectedEquipmentSubType(newSubEquipment);
    setEquipmentSubTypeVariantData((prevEquipmentSubTypes) => ({
      ...prevEquipmentSubTypes,
      EquipmentSubType: newSubEquipment.label,
      EquipmentSubTypeId: newSubEquipment.value,
    }));
    showSnackbar("Equipment type added successsfully");
  };

  const fetchEquipmentSubType = async (EquipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_sub_types_by_type/${EquipmentTypeId}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the format expected by react-select
        const options = data.map((equipmentSubType) => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Type:", error);
    }
  };
  useEffect(() => {
    fetchEquipmentSubType();
  }, []);

  const handleFrequencyChange = (index, field, value) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const updatedFrequencies = [...prevData.oisd_frequencies];
      updatedFrequencies[index][field] = value;
      return { ...prevData, oisd_frequencies: updatedFrequencies };
    });
  };
  
  // Handle changes in Factory Act frequencies
  const handleFectoryActChange = (index, field, value) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const updatedFrequencies = [...prevData.factory_act_frequencies];
      updatedFrequencies[index][field] = value;
      return { ...prevData, factory_act_frequencies: updatedFrequencies };
    });
  };
  const handleOEMChange = (index, field, value) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const updatedFrequencies = [...prevData.oem_frequencies];
      updatedFrequencies[index][field] = value;
      return { ...prevData, oem_frequencies: updatedFrequencies };
    });
  };

  const handlePESOChange = (index, field, value) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const updatedFrequencies = [...prevData.peso_frequencies];
      updatedFrequencies[index][field] = value;
      return { ...prevData, peso_frequencies: updatedFrequencies };
    });
  };
  const handleRemoveFrequencyRow = (index) => {
    setEquipmentSubTypeVariantData((prevData) => {
      const updatedFrequencies = prevData.Frequencies.filter(
        (_, i) => i !== index
      );
      return { ...prevData, Frequencies: updatedFrequencies };
    });
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Frequency configuration as per OISD
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                {/* <div className="form-group-select">
                  <label htmlFor="Department">
                    Department
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                </div> */}
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    onInputChange={handleInputChange}
                    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    onKeyDown={handleKeyDown}
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                  {departmentError && (
                    <span className="error-message">{departmentError}</span>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="SubDepartment">Sub Department</label>
                  <Select
                    id="subDepartment"
                    options={subDepartments}
                    value={selectedSubDepartment}
                    onChange={handleSubDepartmentChange}
                    components={{
                      Control: CustomSubDepartmentControl,
                      Menu: CustomSubDepartmentMenu,
                    }}
                    placeholder="Select Sub Department"
                    isSearchable
                    onKeyDown={handleSubDepartmentKeyDown}
                    onInputChange={handleInputChange}
                    menuIsOpen={subDepartmentMenuIsOpen}
                    onMenuOpen={handleSubDepartmentMenuOpen}
                    onMenuClose={() => setSubDepartmentMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentType">Equipment Type </label>

                  <Select
                    id="EquipmentType"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    components={{
                      Control: CustomTypeControl,
                      Menu: CustomTypeMenu,
                    }}
                    placeholder="Select Equipment Type"
                    isSearchable
                    onKeyDown={handleTypeKeyDown}
                    onInputChange={handleInputChange}
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="EquipmentSubType">Sub Type</label>

                  <Select
                    id="EquipmentSubType"
                    options={equipmentSubTypes}
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    components={{
                      Control: CustomSubControl,
                      Menu: CustomSubMenu,
                    }}
                    placeholder="Select Sub Type"
                    isSearchable
                    onKeyDown={handleSubTypeKeyDown}
                    onInputChange={handleInputChange}
                    menuIsOpen={EquipmentSubTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentSubTypeMenuOpen}
                    onMenuClose={() => setEquipmentSubTypeMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="ContactNo">Sub Type Variant</label>

                  <Select
                    id="EquipmentSubTypeVariant"
                    options={equipmentSubTypeVariants}
                    onKeyDown={handleTypeVariantsKeyDown}
                    onInputChange={handleInputChange}
                    components={{
                      Control: CustomVariantControl,
                      Menu: CustomVariantMenu,
                    }}
                    value={selectedEquipmentSubTypeVariants}
                    onChange={handleTypeSubTypeVariantsChange}
                    placeholder="Select Sub Type Variants"
                    isSearchable
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="ContactNo"></label>
                  <div className="data-list-contentss" ref={containerRef}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="report tabs"
                      className="tabs-container"
                    >
                      <Tab
                        className={`config-tab ${
                          tabValue === 0 ? "selected" : ""
                        }`}
                        label="OISD"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 1 ? "selected" : ""
                        }`}
                        label="Factory Act"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 2 ? "selected" : ""
                        }`}
                        label="OEM Recommendation"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 3 ? "selected" : ""
                        }`}
                        label="PESO"
                      />
                    </Tabs>
                  </div>
                </div>
                {tabValue === 0 && (
                  <div>
                    {equipmentSubTypeVariantData.oisd_frequencies.map(
                      (frequency, index) => (
                        <>
                          <div className="form-group">
                            <label htmlFor="oisd_no">OISD No</label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="oisd_no"
                                value={frequency.oisd_no}
                                onChange={(e) =>
                                  handleFrequencyChange(
                                    index,
                                    "oisd_no",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter OISD no"
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="frequencies">
                              Frequencies (As per OISD){" "}
                            </label>
                            <div className="new-frequency-row">
                              <>
                                <div
                                  key={index}
                                  className="frequency-row input-group"
                                >
                                  <input
                                    type="text"
                                    className="point-fr"
                                    name="OISDPoint"
                                    placeholder="Enter OISD Point"
                                    value={frequency.OISDPoint}
                                    onChange={(e) =>
                                      handleFrequencyChange(
                                        index,
                                        "OISDPoint",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <input
                                    type="number"
                                    name="Frequency"
                                    placeholder="Frequency (in days)"
                                    value={frequency.Frequency}
                                    onChange={(e) =>
                                      handleFrequencyChange(
                                        index,
                                        "Frequency",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <button
                                    className="add-close-button"
                                    type="button"
                                    onClick={() =>
                                      handleCloseFrequencyRow(index)
                                    }
                                    disabled={
                                      equipmentSubTypeVariantData
                                        .oisd_frequencies.length === 1
                                    }
                                  >
                                    <IoClose className="frequency-close-icon" />
                                  </button>
                                </div>

                                <div className="form-group">
                                  <textarea
                                    type="message"
                                    rows={4}
                                    cols={1}
                                    className="textarea"
                                    value={frequency.Description}
                                    onChange={(e) =>
                                      handleFrequencyChange(
                                        index,
                                        "Description",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Description"
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                        </>
                      )
                    )}

                    <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddFrequencyRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}

                {tabValue === 1 && (
                  <div>
                    {equipmentSubTypeVariantData.factory_act_frequencies.map(
                      (frequency, index) => (
                        <>
                          <div className="form-group">
                            <label htmlFor="factory_act_rule_no">
                              Rule No:
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="factory_act_rule_no"
                                value={frequency.factory_act_rule_no}
                                onChange={(e) =>
                                  handleFectoryActChange(
                                    index,
                                    "factory_act_rule_no",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter factory act rule no"
                              />
                            
                            </div>
                            <button
                                className="add-close-button"
                                type="button"
                                onClick={() => handleCloseFectoryActRow(index)}
                                disabled={
                                  equipmentSubTypeVariantData.oisd_frequencies
                                    .length === 1
                                }
                              >
                                <IoClose className="frequency-close-icon" />
                              </button>
                          </div>

                          <div className="form-group">
                            <label htmlFor="freuqency">Frequency</label>
                            <div className="input-group">
                              <input
                                type="number"
                                name="freuqency"
                                value={frequency.Frequency}
                                onChange={(e) =>
                                  handleFectoryActChange(
                                    index,
                                    "Frequency",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter freuqency (in days)"
                              />
                            </div>
                          </div>
                        </>
                      )
                    )}
                    <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddFactoryActRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}

                {tabValue === 2 && (
                   <div>
                   {equipmentSubTypeVariantData.oem_frequencies.map(
                     (frequency, index) => (
                  <>
                    <div className="form-group">
                      <label htmlFor="Frequency">Frequency</label>
                      <div className="input-group">
                        <input
                          type="number"
                          name="Frequency"
                          value={frequency.Frequency}
                          onChange={(e) =>
                            handleOEMChange(
                              index,
                              "Frequency",
                              e.target.value
                            )
                          }
                          placeholder="Enter frequency (in days)"
                        />
                      </div>
                    </div>
                  </>
                     ))}
                       <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddOEMRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                     </div>
                )}

                {tabValue === 3 && (
                    <div>
                    {equipmentSubTypeVariantData.peso_frequencies.map(
                      (frequency, index) => (
                  <>
                    <div className="form-group">
                      <label htmlFor="peso_rule_no">Rule No</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="peso_rule_no"
                          value={frequency.peso_rule_no}
                          onChange={(e) =>
                            handlePESOChange(
                              index,
                              "peso_rule_no",
                              e.target.value
                            )
                          }
                          placeholder="Enter peso rule no"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="Frequency">Frequency</label>
                      <div className="input-group">
                        <input
                          type="number"
                          name="Frequency"
                          value={frequency.Frequency}
                          onChange={(e) =>
                            handlePESOChange(
                              index,
                              "Frequency",
                              e.target.value
                            )
                          }
                          placeholder="Enter frequency (in days)"
                        />
                      </div>
                    </div>

                  </>

                      ))}
                          <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddPESORow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                      </div>
                )}
              </div>
            </form>

            {showDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddDepartmentPopup
                  closePopup={closeDepartmentPopup}
                  setSelectedDepartment={setSelectedDepartment}
                  handleNewDepartment={handleNewDepartment}
                />
              </div>
            )}
            {showSubDepartmentPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubDepartmentPopup
                  closePopup={closeSubDepartmentPopup}
                  selectedDepartment={selectedDepartment}
                  parentSelectedDepartment={selectedDepartment}
                  handleNewSubDepartment={handleNewSubDepartment}
                />
              </div>
            )}
            {showequipmentTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentType
                  closePopup={closeEquipmentTypePopup}
                  setSelectEdequipmentType={setSelectEdequipmentType}
                  handleNewEquipmentType={handleNewEquipmentType}
                  parentSelectedDepartment={selectedDepartment}
                  parentSelectedSubDepartment={selectedSubDepartment}
                />
              </div>
            )}
            {showSubTypePopup && (
              <div className="inquiry-customerperson-popup">
                <AddEquipmentSubType
                  closePopup={closeSubTypePopup}
                  setSelectedEquipmentSubType={setSelectedEquipmentSubType}
                  parentSelectedEquipmentType={selectedEquipmentTypes}
                  handleNewEquipmentSubType={handleNewEquipmentSubType}
                />
              </div>
            )}
            {showSubTypeVariantPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubTypeVariant
                  closePopup={closeEquipmentTypeVariantPopup}
                  setSelectEdequipmentTypeVariants={
                    selectedEquipmentSubTypeVariants
                  }
                  handleNewEquipmentSubTypeVariant={
                    handleNewEquipmentSubTypeVariant
                  }
                  parentSelectedEquipmentSubType={selectedEquipmentSubType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default FrequencyConfig;
