import React, { useState, useEffect } from "react";
import "./UserForm.css";
import "./MatCodeForm.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";

function MatCodeForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [departments, setDepartments] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [ratingType, setRatingType] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedRatingType, setSelectedRatingType] = useState(null);
  const [errors, setErrors] = useState({});
  const [manufacturer, setManufacturer] = useState([]);
  const [digitPart, setDigitPart] = useState("");

  const navigate = useNavigate();

  const [matCodeData, setMatcodeData] = useState({
    equipment_type_name: "",
    department_id: "",
    manufacturer_name: "",
    mat_equipment_type_id: "",
    manufacturer_id: "",
    whole_part: "",
    rating_type_id: "",
    last_four_digits: "",
    mat_code:"",
  });

  const formatCode = ({
    departmentCode = "",
    equipmentTypeCode = "",
    manufacturerCode = "",
    whole_part = "",
    ratingTypeCode = "",
    last_four_digits = "",
  }) => {
    const wholeAndPartCode =
    whole_part === "Whole" ? "W" : whole_part === "part" ? "P" : "";

    // Ensure the digitPart is always 4 digits
    const paddedDigitPart = last_four_digits.padStart(4, "0");

    return `${departmentCode}-${equipmentTypeCode}-${manufacturerCode}-${wholeAndPartCode}-${ratingTypeCode}-${paddedDigitPart}`;
  };

  const handleDigitPartChange = (event) => {
    const value = event.target.value;
  
    // Allow only digits and update state
    if (/^\d*$/.test(value)) {
      setDigitPart(value);
  
      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: matCodeData.departmentCode || "",
        equipmentTypeCode: matCodeData.equipmentTypeCode || "",
        manufacturerCode: matCodeData.manufacturerCode || "",
        whole_part: matCodeData.whole_part || "",
        ratingTypeCode: matCodeData.ratingTypeCode || "",
        last_four_digits: value,
      });
  
      setMatcodeData((prevData) => ({
        ...prevData,
        last_four_digits: value, // Ensure last_four_digits is updated
        mat_code,
      }));
    }
  };
  

  const handleEquipmentTypeChange = (selectedOption) => {
    fetchManufacturerData(selectedEquipmentType)
    setSelectedEquipmentType(selectedOption);
    const equipmentTypeCode = selectedOption.equipmentTypeCode; // Ensure this code is included in selectedOption

    // Format and update matcodeData
    setMatcodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        equipment_type_name: selectedOption.label,
        mat_equipment_type_id: selectedOption.value,
        equipmentTypeCode,
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        whole_part: prevFormData.whole_part,
        ratingTypeCode: prevFormData.ratingTypeCode,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });
  };

  const handleDepartmentChange = (selectedOption) => {
    fetchEquipmentTypes(selectedOption.value);
    setSelectedDepartment(selectedOption);
    const departmentCode = selectedOption.DepartmentCode; // Ensure this code is included in selectedOption

    // Format and update matcodeData
    setMatcodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        department_code: selectedOption.label,
        department_id: selectedOption.value,
        departmentCode,
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        whole_part: prevFormData.whole_part,
        ratingTypeCode: prevFormData.rating_type_code,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });

  };

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
          DepartmentCode: department.DepartmentCode, // Include DepartmentCode here
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const [selectedOption, setSelectedOption] = useState("");

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const whole_part = event.target.value;

    // Format and update matcodeData
    setMatcodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        whole_part, // Assume this value is either 'whole' or 'part'
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        whole_part: whole_part,
        ratingTypeCode: prevFormData.ratingTypeCode,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMatcodeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate("/mat-code");
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasErrors = false;
  
    Object.keys(matCodeData).forEach((key) => {
      if (!matCodeData[key]) {
        errors[key] = `${key} is required`;
        hasErrors = true;
      }
    });
  
    // if (hasErrors) {
    //   setErrors(errors); // Set errors for fields
    //   showSnackbar("Required fields are empty");
    //   return;
    // }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.post(
        `${API_URL}/api/add_mat_code`,
        matCodeData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Mat Code added successfully");
        setTimeout(() => navigate("/mat-code"), 1000);
      }
    } catch (error) {
      console.error("Error saving mat code:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save mat code");
    } finally {
      setIsLoading(false);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const fetchEquipmentTypes = async () => {
    try {
      if (selectedDepartment) {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_mat_equipment_type_by_department/${selectedDepartment.value}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.equipment_type_name,
          value: equipmentType.id,
          equipmentTypeCode: equipmentType.equipment_type_code, 
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch equipment types");
      }
    } else {
      setEquipmentTypes([]);
    }
    } catch (error) {
      console.error("An error occurred while fetching equipment types:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchEquipmentTypes();
      setSelectedEquipmentType(null);
    } else {
      setEquipmentTypes([]);
      setSelectedEquipmentType(null);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedEquipmentType) {
      fetchManufacturerData();
    }
  }, [selectedEquipmentType]);
  

  useEffect(() => {
    fetchRatingTypeData();
  }, []);
  const fetchManufacturerData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_manufacturer_by_equipment/${selectedEquipmentType.value}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((manufacturer) => ({
          label: manufacturer.manufacturer_name,
          value: manufacturer.id,
          manufacturerCode: manufacturer.manufacturer_code, // Ensure this code is included
        }));
        setManufacturer(options);
      } else {
        console.error("Failed to fetch manufacturers");
      }
    } catch (error) {
      console.error("An error occurred while fetching manufacturers:", error);
    }
  };

  const fetchRatingTypeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_rating_types`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((ratingType) => ({
          label: ratingType.rating_type,
          value: ratingType.id,
          ratingTypeCode: ratingType.rating_type_code, // Ensure this code is included
        }));
        setRatingType(options);
      } else {
        console.error("Failed to fetch rating types");
      }
    } catch (error) {
      console.error("An error occurred while fetching rating types:", error);
    }
  };

  const handleRatingTypeChange = (selectedOption) => {
    setSelectedRatingType(selectedOption);
    const ratingTypeCode = selectedOption.ratingTypeCode; // Ensure this code is included in selectedOption

    // Format and update matcodeData
    setMatcodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        rating_type: selectedOption.label,
        rating_type_id: selectedOption.value,
        ratingTypeCode,
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        whole_part: prevFormData.whole_part,
        ratingTypeCode: ratingTypeCode,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });
  };

  const handleManufacturerChange = (selectedOption) => {
    setSelectedManufacturer(selectedOption);
    const manufacturerCode = selectedOption.manufacturerCode; // Ensure this code is included in selectedOption

    // Format and update matcodeData
    setMatcodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        manufacturer_name: selectedOption.label,
        manufacturer_id: selectedOption.value,
        manufacturerCode,
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: manufacturerCode,
        whole_part: prevFormData.whole_part,
        ratingTypeCode: prevFormData.ratingTypeCode,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Mat Code
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    //    components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                </div>

                <div className="form-group-select">
                  <label htmlFor="equipment_type">
                    Equipment Type<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="equipment_type"
                    options={equipmentTypes}
                    value={selectedEquipmentType}
                    onChange={handleEquipmentTypeChange}
                    placeholder="Select Equipment Type"
                    isSearchable
                  />
                  {errors.equipment_type && (
                    <div className="error-message">{errors.equipment_type}</div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="manufacturer">
                    Manufacturer Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="manufacturer"
                    options={manufacturer}
                    value={selectedManufacturer}
                    onChange={handleManufacturerChange}
                    placeholder="Select Manufacturer"
                    isSearchable
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="WholePart">
                    Whole/Part<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="WholePart"
                      value="Whole"
                      checked={selectedOption === "Whole"}
                      onChange={handleOptionChange}
                    />
                    <label>Whole</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      value="part"
                      checked={selectedOption === "part"}
                      onChange={handleOptionChange}
                    />
                    <label>Part</label>
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="RatingType">
                    Rating/Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="RatingType"
                    options={ratingType}
                    value={selectedRatingType}
                    onChange={handleRatingTypeChange}
                    placeholder="Select Rating Type"
                    isSearchable
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="last_four_digits">Last 4 Digits</label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="last_four_digits"
                      value={digitPart}
                      onChange={handleDigitPartChange}
                      maxLength="4" // Limit to 4 digits
                      placeholder="Enter digits"
                    />
                  </div>
                </div>

                <div className="mate-code-button">
               
                    <input
                      type="text"
                      id="mat_code"
                      value={matCodeData.mat_code || ""}
                      placeholder="xx-xx-xx-x-xx-xxxx"
                      readOnly
                    />
                 
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MatCodeForm;
