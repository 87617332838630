import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditMatCode({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [manufacturerData, setManufacturerData] = useState({});
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRatingType, setSelectedRatingType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [errors, setErrors] = useState({});
  const [manufacturer, setManufacturer] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [ratingData, setRatingData] = useState({});
  const [matCodeData, setMatCodeData] = useState({});
  const [digitPart, setDigitPart] = useState("");
  const [ratingType, setRatingType] = useState([]);
  const [selectedOption, setSelectedOption] = useState("wall");
  const handleManufacturerChange = (selectedOption) => {
    setSelectedManufacturer(selectedOption);
    setManufacturerData((prevData) => ({
      ...prevData,
      manufacturer_name: selectedOption.label,
      manufacturer_id: selectedOption.value,
    }));
  };
  const fetchRatingTypeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_rating_types`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((ratingType) => ({
          label: ratingType.rating_type,
          value: ratingType.id,
          ratingTypeCode: ratingType.rating_type_code, // Ensure this code is included
        }));
        setRatingType(options);
      } else {
        console.error("Failed to fetch rating types");
      }
    } catch (error) {
      console.error("An error occurred while fetching rating types:", error);
    }
  };


  useEffect(() => {
    const fetchMatCodeData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api//${id}`, {
          headers: { Authorization: authKey },
        });
        const responseData = response.data;

        setManufacturerData({
          department_name: responseData.department_name,
          department_id: responseData.department_id,
          equipment_type_id: responseData.equipment_type_id,
          equipment_type_name: responseData.equipment_type_name,
          manufacturer_name: responseData.manufacturer_name,
          manufacturer_code: responseData.manufacturer_code,
        });

        setSelectedDepartment({ value: responseData.department_id, label: responseData.department_name });
        setSelectedEquipmentType({ value: responseData.equipment_type_id, label: responseData.equipment_type_name });
      } catch (error) {
        console.error("Error fetching manufacturer data:", error.message);
      }
    };

    fetchMatCodeData();
  }, [id]);
  const handleBackProject = () => {
    navigate("/mat-code");
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectedEquipmentType(selectedOption);
    setManufacturerData((prevData) => ({
      ...prevData,
      equipment_type_name: selectedOption.label,
      equipment_type_id: selectedOption.value,
    }));
  };

  const fetchDepartments = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_departments`, {
        headers: { Authorization: authKey },
      });

      const departmentOptions = response.data.map((department) => ({
        value: department.ID,
        label: department.DepartmentName,
        code: department.DepartmentCode,
      }));

      setDepartments(departmentOptions);
    } catch (error) {
      console.error("Error fetching departments:", error.message);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchEquipmentTypes = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_equipment_types`, {
        headers: { Authorization: authKey },
      });

      const options = response.data.map((equipmentType) => ({
        label: equipmentType.equipment_type_name,
        value: equipmentType.ID,
      }));

      setEquipmentTypes(options);
    } catch (error) {
      console.error("Error fetching equipment types:", error.message);
    }
  };

  useEffect(() => {
    fetchEquipmentTypes();
    fetchRatingTypeData();
    fetchManufacturerData();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const whole_part = event.target.value;

    // Format and update matcodeData
    setMatCodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        whole_part, // Assume this value is either 'whole' or 'part'
      };

      // Generate formatted code
      const mat_code = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        whole_part: whole_part,
        ratingTypeCode: prevFormData.ratingTypeCode,
        last_four_digits: digitPart,
      });

      return {
        ...updatedData,
        mat_code,
      };
    });
  };

  const handleCancelClick = () => {
    navigate("/mat-code");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    if (!selectedDepartment || !manufacturerData.equipment_type_name || !manufacturerData.manufacturer_code) {
      showSnackbar("Required fields are empty", "warning");
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api//${id}`,
        {
          ...manufacturerData,
          department_id: selectedDepartment.value,
          DepartmentCode: selectedDepartment.code,
          department_name: selectedDepartment.label,
        },
        {
          headers: { Authorization: authKey },
        }
      );

      if (response.status === 200) {
        showSnackbar("Data updated successfully", "success");
        setTimeout(() => {
          navigate("/mat-code");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating manufacturer:", error.message);
      showSnackbar("An error occurred while updating data", "error");
      setIsLoading(false);
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };

  const formatCode = ({
    departmentCode = "",
    equipmentTypeCode = "",
    manufacturerCode = "",
    wholeAndPart = "",
    ratingTypeCode = "",
    digitPart = "",
  }) => {
    const wholeAndPartCode =
      wholeAndPart === "Whole" ? "W" : wholeAndPart === "part" ? "P" : "";

    // Ensure the digitPart is always 4 digits
    const paddedDigitPart = digitPart.padStart(4, "0");

    return `${departmentCode}-${equipmentTypeCode}-${manufacturerCode}-${wholeAndPartCode}-${ratingTypeCode}-${paddedDigitPart}`;
  };

  const handleDigitPartChange = (event) => {
    const value = event.target.value;

    // Allow only digits and update state
    if (/^\d*$/.test(value)) {
      setDigitPart(value);

      // Generate formatted code
      const formattedCode = formatCode({
        departmentCode: matCodeData.departmentCode || "",
        equipmentTypeCode: matCodeData.equipmentTypeCode || "",
        manufacturerCode: matCodeData.manufacturerCode || "",
        wholeAndPart: matCodeData.wholeAndPart || "",
        ratingTypeCode: matCodeData.ratingTypeCode || "",
        digitPart: value,
      });

      setMatCodeData((prevData) => ({
        ...prevData,
        formattedCode,
      }));
    }
  };

  const handleRatingTypeChange = (selectedOption) => {
    setSelectedRatingType(selectedOption);
    const ratingTypeCode = selectedOption.ratingTypeCode; // Ensure this code is included in selectedOption

    // Format and update matcodeData
    setMatCodeData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        rating_type: selectedOption.label,
        rating_type_id: selectedOption.value,
        ratingTypeCode,
      };

      // Generate formatted code
      const formattedCode = formatCode({
        departmentCode: prevFormData.departmentCode,
        equipmentTypeCode: prevFormData.equipmentTypeCode,
        manufacturerCode: prevFormData.manufacturerCode,
        wholeAndPart: prevFormData.wholeAndPart,
        ratingTypeCode: ratingTypeCode,
        digitPart: digitPart,
      });

      return {
        ...updatedData,
        formattedCode,
      };
    });
  };
  const fetchManufacturerData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_manufacturers`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((manufacturer) => ({
          label: manufacturer.manufacturer_name,
          value: manufacturer.id,
          manufacturerCode: manufacturer.manufacturer_code, // Ensure this code is included
        }));
        setManufacturer(options);
      } else {
        console.error("Failed to fetch manufacturers");
      }
    } catch (error) {
      console.error("An error occurred while fetching manufacturers:", error);
    }
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack className="back-company" onClick={handleBackProject} />
                </Tooltip>
                Edit Mat Code
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select a department"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="equipment_type_name">
                    Equipment Type <span className="text-danger">*</span>
                  </label>
                 
                  <Select
                    id="equipment_type"
                    options={equipmentTypes}
                    value={selectedEquipmentType}
                    onChange={handleEquipmentTypeChange}
                    placeholder="Select Equipment Type"
                    isSearchable
                  />
              
                </div>
                <div className="form-group-select">
                <label htmlFor="manufacturer">
                  Manufacturer Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="manufacturer"
                    options={manufacturer}
                    value={selectedManufacturer}
                    onChange={handleManufacturerChange}
                    placeholder="Select Manufacturer"
                    isSearchable
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="WholePart">
                    Whole/Part<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="WholePart"
                      value="Whole"
                      checked={ratingData.WholePart === "Whole"}
                      onChange={handleOptionChange}
                    />
                    <label>
                      Whole
                    </label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="WholePart"
                      value="Part"
                      checked={ratingData.WholePart === "Part"}
                      onChange={handleOptionChange}
                    />
                    <label>
                      Part
                    </label>
                    {errors.WholePart && (
                      <div className="error-message">{errors.WholePart}</div>
                    )}
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="RatingType">
                    Rating/Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="RatingType"
                    options={ratingType}
                    value={selectedRatingType}
                    onChange={handleRatingTypeChange}
                    placeholder="Select Rating Type"
                    isSearchable
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="digitPart">Last 4 Digits</label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="digitPart"
                      value={digitPart}
                      onChange={handleDigitPartChange}
                      maxLength="4" // Limit to 4 digits
                      placeholder="Enter digits"
                    />
                  </div>
                </div>

                <div className="mate-code-button">
               
                    <input
                      type="text"
                      id="formattedCode"
                      value={matCodeData.formattedCode || ""}
                      placeholder="xx-xx-xx-x-xx-xxxx"
                      readOnly
                    />
                 
                </div>
             
               
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditMatCode;
