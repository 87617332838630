import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./NewEquipmentForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AddSubTypeVariant from "../popup/AddSubTypeVariant";
import { PiArrowElbowDownRightFill } from "react-icons/pi";
import { TbPointFilled } from "react-icons/tb";
import { GiGearHammer } from "react-icons/gi";
import { format } from 'date-fns';
const { Control, Menu } = components;

function NewEquipmentForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedMaintanceDate, setSelectedMaintanceDate] = useState(null);
  const [selectedWarrantyDate, setselectedWarrantyDate] = useState(null);
  const [currentOpenPopup, setCurrentOpenPopup] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [frequencyData, setFrequencyData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [tagNoError, setTagNoError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [maintenanceDateError, setMaintenanceDateError] = useState('');
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);
  const [
    EquipmentSubTypeVariantsMenuIsOpen,
    setEquipmentSubTypeVariantsMenuIsOpen,
  ] = useState(false);
  const [showSubTypeVariantPopup, setShowSubTypeVariantPopup] = useState(false);
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const fileInputRef = useRef(null);
  const [equipmentData, setEquipmentData] = useState({
    DepartmentId: "",
    DepartmentName: "",
    EquipmentTypeId: "",
    SubDepartmentName: "",
    SubDepartmentId: "",
    EquipmentType: "",
    EquipmentSubTypeId: "",
    EquipmentSubType: "",
    VariantNameId: "",
    SubTypeVariant: "",
    TagNo: "",
    Attachment: "",
    Make: "",
    Model: "",
    frequency_id: "",
    InstallationYear: null,
    WarrantyExp: "",
    LastMaintenanceDate: "",
    Location: "",
    Description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMaintanaceDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedMaintanceDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        LastMaintenanceDate: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        InstallationYear: year,
      }));
    }
  };

  const handleWarrantyDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setselectedWarrantyDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        WarrantyExp: formattedDate,
      }));
    }
  };

  const handleCancelClick = () => {
    navigate("/new-equipment");
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const formData = new FormData();
  //     formData.append("DepartmentId", equipmentData.DepartmentId);
  //     formData.append("DepartmentName", equipmentData.DepartmentName);
  //     formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
  //     formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
  //     formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
  //     formData.append("EquipmentType", equipmentData.EquipmentType);
  //     formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
  //     formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
  //     formData.append("VariantNameId", equipmentData.VariantNameId);
  //     formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
  //     formData.append("TagNo", equipmentData.TagNo);
  //     formData.append("Category", equipmentData.Category);
  //     formData.append("Make", equipmentData.Make);
  //     formData.append("Model", equipmentData.Model);
  //     formData.append("InstallationYear", equipmentData.InstallationYear);
  //     formData.append("WarrantyExp", equipmentData.WarrantyExp);
  //     formData.append("FrequencyOfMaintenance", equipmentData.FrequencyOfMaintenance);
  //     formData.append("LastMaintenanceDate", equipmentData.LastMaintenanceDate);
  //     formData.append("Location", equipmentData.Location);
  //     formData.append("Description", equipmentData.Description);

  //     // Append each file to the form data
  //     // attachmentFile.forEach((file) => {
  //     //   formData.append("Datasheet", file);
  //     // });

  //     // Assuming frequency is an array of objects
  //     formData.append("Frequency", JSON.stringify(equipmentData.Frequency));

  //     const response = await axios.post(
  //       `${API_URL}/api/add_equipment`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log(response.data.message);

  //     if (response.status === 200) {
  //       showSnackbar("Equipment added successfully");
  //       setTimeout(() => {
  //         navigate("/new-equipment");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       showSnackbar(
  //         "TagNo already exists. Please use a different TagNo.",
  //         "error"
  //       );
  //     } else {
  //       console.error("Error saving equipment:", error.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSaveClick = async () => {
    // Reset error messages
    setTagNoError('');
    setCategoryError('');
    setMaintenanceDateError('');

    // Validate required fields
    let isValid = true;

    if (!equipmentData.TagNo) {
      setTagNoError('Tag No is required');
      isValid = false;
    }

    if (!selectedEquipmentSubTypeVariants) {
      setCategoryError('Category is required');
      isValid = false;
    }

    if (!selectedMaintanceDate) {
      setMaintenanceDateError('Last Maintenance Date is required');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const formData = new FormData();
      formData.append("DepartmentId", equipmentData.DepartmentId);
      formData.append("DepartmentName", equipmentData.DepartmentName);
      formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
      formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
      formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
      formData.append("EquipmentType", equipmentData.EquipmentType);
      formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
      formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
      formData.append("VariantNameId", equipmentData.VariantNameId);
      formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
      formData.append("TagNo", equipmentData.TagNo);
      formData.append("Category", equipmentData.Category);
      formData.append("Make", equipmentData.Make);
      formData.append("Model", equipmentData.Model);
      formData.append("InstallationYear", equipmentData.InstallationYear);
      formData.append("WarrantyExp", selectedWarrantyDate ? format(new Date(selectedWarrantyDate), 'yyyy-MM-dd') : null);
      formData.append("LastMaintenanceDate", selectedMaintanceDate ? format(new Date(selectedMaintanceDate), 'yyyy-MM-dd') : null);
      formData.append("Location", equipmentData.Location);
      formData.append("Description", equipmentData.Description);
      formData.append("frequency_id", equipmentData.frequency_id);
      //  Append each file to the form data
      //  attachmentFile.forEach((file) => {
      //   formData.append("Datasheet", file);
      // });

      // Assuming frequency is an array of objects
      // formData.append("Frequency", JSON.stringify(equipmentData.Frequency));

      const response = await axios.post(
        `${API_URL}/api/add_equipment`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.message);

      if (response.status === 200) {
        showSnackbar("Equipment added successfully");
        setTimeout(() => {
          navigate("/new-equipment");
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        showSnackbar(
          "TagNo already exists. Please use a different TagNo.",
          "error"
        );
      } else {
        console.error("Error saving equipment:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleEquipmentSubTypeVariantMenuOpen = () => {
    setEquipmentSubTypeVariantsMenuIsOpen(true);
    setCurrentOpenPopup("equipmentSubTypeVariants");
  };

  const CustomVariantControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomVariantMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewTypeVariant}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Frequency Configuration
      </button>
    </Menu>
  );

  const handleAddNewTypeVariant = () => {
    // setShowSubTypeVariantPopup(true);
    navigate("/frequency-config");
    setEquipmentSubTypeVariantsMenuIsOpen(false);
  };

  const closeEquipmentTypeVariantPopup = () => {
    setShowSubTypeVariantPopup(false);
  };

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectEdequipmentTypeVariants(selectedOption);
    const {
      DepartmentName,
      SubDepartmentName,
      VariantName,
      EquipmentSubType,
      EquipmentType,
      frequency,
      id,
    } = selectedOption;

    // Determine the category based on the availability of different properties
    let category = "";
    if (VariantName) {
      category = VariantName;
    } else if (EquipmentSubType) {
      category = EquipmentSubType;
    } else if (EquipmentType) {
      category = EquipmentType;
    } else if (SubDepartmentName) {
      category = SubDepartmentName;
    } else if (DepartmentName) {
      category = DepartmentName;
    }

    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      Category: category,
      SubTypeVariant: selectedOption.SubTypeVariant,
      VariantNameId: selectedOption.variant_id,
      DepartmentId: selectedOption.department_id,
      DepartmentName: selectedOption.DepartmentName,
      EquipmentTypeId: selectedOption.equipment_type_id,
      SubDepartmentId: selectedOption.sub_department_id,
      SubDepartmentName: selectedOption.SubDepartmentName,
      EquipmentType: selectedOption.EquipmentType,
      EquipmentSubTypeId: selectedOption.equipment_sub_type_id,
      EquipmentSubType: selectedOption.EquipmentSubType,
      Frequency: frequency, // Update Frequency field
      frequency_id: id,
    }));

    setFrequencyData(frequency);
  };

  const fetchEquipmentSubTypeVariants = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_all_frequency_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        // Directly set the data in state as it is
        setFrequencyData(data);
  
        const options = data.map((equipmentSubTypeVariant) => {
          const {
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            VariantName,
            id,
            department_id,
            sub_department_id,
            equipment_type_id,
            equipment_sub_type_id,
            variant_id,
            frequency,
            Frequencies, // Added this to make sure frequencies are included
          } = equipmentSubTypeVariant;
          const labelParts = [
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            VariantName,
          ];
          const label = labelParts.filter((part) => part).join(" >> ");
          return {
            label,
            value: id,
            variant_id,
            id,
            frequency,
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            department_id,
            VariantName,
            equipment_type_id,
            sub_department_id,
            equipment_sub_type_id,
            Frequencies, // Added this to make sure frequencies are included
          };
        });
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    fetchEquipmentSubTypeVariants();
  }, []);

  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);

    setOfferDocumentError("");
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleNewEquipmentSubTypeVariant = (newSubTypeVariant) => {
    setEquipmentTypeVariants((prevEquipmentTypeVariants) => [
      ...prevEquipmentTypeVariants,
      newSubTypeVariant,
    ]);
    setSelectEdequipmentTypeVariants(newSubTypeVariant);
    setEquipmentData((prevEquipmentTypeVariants) => ({
      ...prevEquipmentTypeVariants,
      SubTypeVariant: newSubTypeVariant.label,
      VariantNameId: newSubTypeVariant.value,
    }));
    showSnackbar("Equipment sub type variant added successsfully");
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipment
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="newperson-user-form">
                <div className="form-header-seperator">
                  <div className="form-header-seperator-content equip">
                    <div className="equipment-image equip1">
                      <div className="box">
                        <GiGearHammer />
                      </div>
                    </div>
                    <div className="equipment-image equip2">
                      <div className="form-group-top-select">
                        <label htmlFor="ContactNo">
                          Category <span className="text-danger">*</span>
                        </label>

                        <Select
                          id="EquipmentSubTypeVariant"
                          options={equipmentSubTypeVariants}
                          value={selectedEquipmentSubTypeVariants}
                          onChange={handleTypeSubTypeVariantsChange}
                          components={{
                            Control: CustomVariantControl,
                            Menu: CustomVariantMenu,
                          }}
                          placeholder="Select category "
                          isSearchable
                          menuIsOpen={EquipmentSubTypeVariantsMenuIsOpen}
                          onMenuOpen={handleEquipmentSubTypeVariantMenuOpen}
                          onMenuClose={() =>
                            setEquipmentSubTypeVariantsMenuIsOpen(false)
                          }
                        />
                        {categoryError && <p className="error-message">{categoryError}</p>}
                      </div>

                      <div className="form-group-top">
                        <label htmlFor="TagNo">
                          Tag No.<span className="text-danger">*</span>
                        </label>
                        <div className="input-group-top">
                          <input
                            type="text"
                            name="TagNo"
                            onChange={handleInputChange}
                            placeholder="Enter tag no"
                          />
                        </div>
                      </div>
                      {tagNoError && <p className="error-message">{tagNoError}</p>}
                    </div>
                    {selectedEquipmentSubTypeVariants && (
                      <div className="selected-category equip3">
                        <div className="equip3-box">
                          <>
                            <div className="equip-category">
                              {selectedEquipmentSubTypeVariants.DepartmentName && (
                                <div className="parent">
                                  {
                                    selectedEquipmentSubTypeVariants.DepartmentName
                                  }
                                  <div className="equip-icon">
                                    <TbPointFilled />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.SubDepartmentName && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.EquipmentType &&
                                      !selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                      !selectedEquipmentSubTypeVariants.VariantName
                                      ? "bold-text"
                                      : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.SubDepartmentName
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.EquipmentType && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                      !selectedEquipmentSubTypeVariants.VariantName
                                      ? "bold-text"
                                      : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.EquipmentType
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.EquipmentSubType && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.VariantName
                                      ? "bold-text"
                                      : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.EquipmentSubType
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.VariantName && (
                                <div className="child bold-text">
                                  {selectedEquipmentSubTypeVariants.VariantName}
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                            </div>
                          </>

                          {selectedEquipmentSubTypeVariants && (
        <div className="frequency-table-container">
          <table className="frequency-table">
            <thead>
              <tr>
                <th>OISD Point</th>
                <th>Frequency (In days)</th>
              </tr>
            </thead>
            <tbody>
              {selectedEquipmentSubTypeVariants.Frequencies && selectedEquipmentSubTypeVariants.Frequencies.length > 0 ? (
                selectedEquipmentSubTypeVariants.Frequencies.map((item, index) => (
                  <tr key={index}>
                    <td>{item.oisd_point}</td>
                    <td>{item.frequency}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No data available</td>
                </tr>
              )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-seperator">
                  <div className="form-seperator-content">
                    Asset Information
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Make">
                    Make
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Make"
                      onChange={handleInputChange}
                      placeholder="Enter make"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Model">
                    Model
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Model"
                      onChange={handleInputChange}
                      placeholder="Enter model"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Installation">
                    Year Of Installation
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedInstallationDate}
                      onChange={handleInstallationDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      isClearable={false}
                      showYearPicker
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setSelectedInstallationDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Warranty">
                    Warranty Expiry
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedWarrantyDate}
                      onChange={handleWarrantyDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a expiry date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setselectedWarrantyDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <label htmlFor="FrequencyOfMaintenance">
                    Frequency of Maintenance
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="FrequencyOfMaintenance"
                      onChange={handleInputChange}
                      placeholder="Enter frequency"
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  <label htmlFor="Maintenance_Date">
                    Last Maintenance Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedMaintanceDate}
                      onChange={handleMaintanaceDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a maintenance date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      todayButton="Today"
                      maxDate={new Date()} // Set the max date to today
                    />
                  </div>
                  {maintenanceDateError && <p className="error-message">{maintenanceDateError}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="Location">
                    Location
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Location"
                      onChange={handleInputChange}
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">
                    Description
                  </label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>

            {showSubTypeVariantPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubTypeVariant
                  closePopup={closeEquipmentTypeVariantPopup}
                  setSelectEdequipmentTypeVariants={
                    selectedEquipmentSubTypeVariants
                  }
                  handleNewEquipmentSubTypeVariant={
                    handleNewEquipmentSubTypeVariant
                  }
                  parentSelectedEquipmentSubType={selectedEquipmentSubType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default NewEquipmentForm;
