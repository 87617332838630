import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select, { components } from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Button, TextField, Tabs, Tab } from "@mui/material";

function EditFrequency({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [frequencyData, setFrequencyData] = useState({});
  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null);
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [oisdNo, setOisdNo] = useState("");
  const [oisdFrequencies, setOisdFrequencies] = useState([
    { oisd_point: "", frequency: "", description: "" },
  ]);
  const [factoryActFrequencies, setFactoryActFrequencies] = useState([
    { factory_act_rule_no: "", frequency: "" },
  ]);
  const [oemFrequencies, setOemFrequencies] = useState([{ frequency: "" }]);
  const [pesoFrequencies, setPesoFrequencies] = useState([
    { peso_rule_no: "", frequency: "" },
  ]);
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [inputValue, setInputValue] = useState("");
  const [frequencyRows, setFrequencyRows] = useState([]);
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [selectedSubTypeVariant, setSelectedSubTypeVariant] = useState(null);
  const [tabValue, setTabValue] = useState(0); // State for tab navigation
  const containerRef = useRef(null);
  const [originalOisdFrequencies, setOriginalOisdFrequencies] = useState([]);
  const [originalFactoryActFrequencies, setOriginalFactoryActFrequencies] =
    useState([]);
  const [originalOemFrequencies, setOriginalOemFrequencies] = useState([]);
  const [originalPesoFrequencies, setOriginalPesoFrequencies] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchFrequencyData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_frequency_configs_by_id/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          const frequencies = response.data[0].Frequencies || [];

          // Filter frequencies based on their type
          const oisd = frequencies.filter(
            (freq) => freq.frequency_type === "oisd"
          );
          const factoryAct = frequencies.filter(
            (freq) => freq.frequency_type === "factory_act"
          );
          const oem = frequencies.filter(
            (freq) => freq.frequency_type === "oem"
          );
          const peso = frequencies.filter(
            (freq) => freq.frequency_type === "peso"
          );

          setFrequencyData(response.data[0]);
          setSelectedDepartment({
            label: response.data[0].DepartmentName,
            value: response.data[0].department_id,
          });
          setSelectedSubDepartment({
            label: response.data[0].SubDepartmentName,
            value: response.data[0].sub_department_id,
          });
          setSelectedEquipmentTypes({
            label: response.data[0].EquipmentType,
            value: response.data[0].equipment_type_id,
          });
          setSelectedEquipmentSubType({
            label: response.data[0].EquipmentSubType,
            value: response.data[0].equipment_sub_type_id,
          });
          setSelectedSubTypeVariant({
            label: response.data[0].VariantName,
            value: response.data[0].variant_id,
          });

          // Set original frequencies
          setOriginalOisdFrequencies(oisd);
          setOriginalFactoryActFrequencies(factoryAct);
          setOriginalOemFrequencies(oem);
          setOriginalPesoFrequencies(peso);

          // Set current frequencies
          setOisdFrequencies(oisd);
          setFactoryActFrequencies(factoryAct);
          setOemFrequencies(oem);
          setPesoFrequencies(peso);
        } else {
          setError("No frequency data found");
        }
      } catch (error) {
        console.error("Error fetching frequency data:", error.message);
        setError("An error occurred while fetching frequency data");
      }
    };
    fetchFrequencyData();
  }, [ID]);


  const handleSaveClick = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");

        // Ensure default values are set if any field is empty
        const defaultOisdFrequency = {
          oisd_point: "",
          frequency: "0",
          description: "",
          oisd_no: "",
          frequency_type: "oisd",
        };
        const defaultPesoFrequency = {
          peso_rule_no: "",
          frequency: "0",
          frequency_type: "peso",
        };
        const defaultFactoryactFrequency = {
          factory_act_rule_no: "",
          frequency: "0",
          frequency_type: "factory_act",
        };
        const defaultOemFrequency = {
          frequency: "0",
          frequency_type: "oem",
        };

        // Helper function to map frequencies and rename fields appropriately
        const mapFrequencies = (frequencies, defaultFrequency) => {
          return frequencies
            .filter((row) => row.isUpdated || !row.id) // Only include updated or new rows
            .filter((row) => {
              // Check if all relevant fields are empty, exclude those rows
              return (
                row.oisd_point ||
                row.frequency ||
                row.description ||
                row.oisd_no ||
                row.factory_act_rule_no ||
                row.peso_rule_no
              );
            })
            .map((row) => {
              // Map fields to the desired format
              const mappedRow = {
                ...defaultFrequency, // Include default structure
                // Rename fields: oisd_point -> OISDPoint, frequency -> Frequency
                oisd_point: row.oisd_point || defaultFrequency.oisd_point,
                frequency: row.frequency || defaultFrequency.frequency,
                description: row.description || defaultFrequency.description,
                oisd_no: row.oisd_no || defaultFrequency.oisd_no,
                factory_act_rule_no: row.factory_act_rule_no || defaultFrequency.factory_act_rule_no,
                peso_rule_no: row.peso_rule_no || defaultFrequency.peso_rule_no,
                frequency_type: row.frequency_type || defaultFrequency.frequency_type,
              };
        
              // Include the row id if available
              if (row.id) {
                mappedRow.frequency_id = row.id;
              }
        
              return mappedRow;
            });
        };
        

        // Prepare the payload with the correctly formatted frequencies
        const payload = {
          DepartmentId: selectedDepartment?.value || "",
          SubDepartmentId: selectedSubDepartment?.value || "",
          EquipmentTypeId: selectedEquipmentTypes?.value || "",
          EquipmentSubTypeId: selectedEquipmentSubType?.value || "",
          VariantNameId: selectedSubTypeVariant?.value || "",
          DepartmentName: selectedDepartment?.label || "",
          SubDepartmentName: selectedSubDepartment?.label || "",
          EquipmentType: selectedEquipmentTypes?.label || "",
          EquipmentSubType: selectedEquipmentSubType?.label || "",
          SubTypeVariant: selectedSubTypeVariant?.label || "",

          oisd_frequencies: mapFrequencies(
            oisdFrequencies,
            defaultOisdFrequency
          ),
          factory_act_frequencies: mapFrequencies(
            factoryActFrequencies,
            defaultFactoryactFrequency
          ),
          oem_frequencies: mapFrequencies(oemFrequencies, defaultOemFrequency),
          peso_frequencies: mapFrequencies(
            pesoFrequencies,
            defaultPesoFrequency
          ),
        };

        console.log("Data being sent to backend:", payload);

        const response = await axios.put(
          `${API_URL}/api/update_frequency_config/${ID}`,
          payload,
          {
            headers: {
              Authorization: authKey,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/frequency");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating frequency data:", error.message);
        setSeverity("error");
        showSnackbar("Failed to update data");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCancelClick = () => {
    navigate("/frequency");
  };
  const handleInputChanges = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedDepartment) {
      setSelectedDepartment(null);
      setSelectedSubDepartment(null);
      setSelectedEquipmentTypes(null);
      setSelectedEquipmentSubType(null);
      setSelectedSubTypeVariant(null);
    }
  };
  const handleSubDepartmentKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedSubDepartment) {
      setSelectedSubDepartment(null);
      setSelectedEquipmentTypes(null);
      setSelectedEquipmentSubType(null);
      setSelectedSubTypeVariant(null);
    }
  };
  const handleTypeKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedEquipmentTypes) {
      setSelectedEquipmentTypes(null);
      setSelectedEquipmentSubType(null);
      setSelectedSubTypeVariant(null);
    }
  };
  const handleSubTypeKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedEquipmentSubType) {
      setSelectedEquipmentSubType(null);
      setSelectedSubTypeVariant(null);
    }
  };
  const handleTypeVariantsKeyDown = (event) => {
    if (event.key === "Backspace" && !inputValue && selectedSubTypeVariant) {
      setSelectedSubTypeVariant(null);
    }
  };

  const handleBackProject = () => {
    navigate("/frequency");
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setFrequencyData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
    fetchSubDepartments(selectedOption.value);
    setSelectedSubDepartment(null);
    setSelectedEquipmentTypes(null);
    setSelectedEquipmentSubType(null);
    setSelectedSubTypeVariant(null);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchSubDepartments = async (departmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_department/${departmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const options = data.map((subDepartment) => ({
          label: subDepartment.Name,
          value: subDepartment.id,
        }));
        setSubDepartments(options);
      } else {
        console.error("Failed to fetch sub-departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments(selectedDepartment.value);
    }
  }, [selectedDepartment]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setFrequencyData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
    setSelectedEquipmentTypes(null);
    setSelectedEquipmentSubType(null);
    setSelectedSubTypeVariant(null);
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectedEquipmentTypes(selectedOption);
    setFrequencyData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
    setSelectedEquipmentSubType(null);
    setSelectedSubTypeVariant(null);
  };

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  useEffect(() => {
    if (selectedSubDepartment) {
      fetchEquipmentTypes(selectedSubDepartment.value);
    }
  }, [selectedSubDepartment]);

  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setFrequencyData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
  };

  const fetchEquipmentSubType = async (equipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_sub_types_by_type/${equipmentTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubType) => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentTypes) {
      fetchEquipmentSubType(selectedEquipmentTypes.value);
    }
  }, [selectedEquipmentTypes]);

  const fetchEquipmentSubTypeVariants = async (equipmentSubTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_type_variants_by_sub_type/${equipmentSubTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubTypeVariant) => ({
          label: equipmentSubTypeVariant.VariantName,
          value: equipmentSubTypeVariant.id,
        }));
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentSubType) {
      fetchEquipmentSubTypeVariants(selectedEquipmentSubType.value);
    }
  }, [selectedEquipmentSubType]);

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectedSubTypeVariant(selectedOption);
    setFrequencyData((prevFormData) => ({
      ...prevFormData,
      SubTypeVariant: selectedOption.label,
      VariantNameId: selectedOption.value,
    }));
  };

  const handleFrequencyRowChange = (type, index, field, value) => {
    // Update the frequency rows for the specified index
    const updateFrequencies = frequencyRows.map((row, i) =>
      i === index ? { ...row, [field]: value, isUpdated: true } : row
    );

    // Set the updated frequency rows
    setFrequencyRows(updateFrequencies);

    // Function to handle updating specific frequency types based on the 'type' passed in
    const updateSpecificFrequencies = (frequencies, setFrequencies) => {
      const updatedFrequencies = frequencies.map((row, i) =>
        i === index ? { ...row, [field]: value, isUpdated: true } : row
      );
      setFrequencies(updatedFrequencies);
    };

    // Switch case to handle different frequency types
    switch (type) {
      case "oisd":
        updateSpecificFrequencies(oisdFrequencies, setOisdFrequencies);
        break;

      case "factory_act":
        updateSpecificFrequencies(
          factoryActFrequencies,
          setFactoryActFrequencies
        );
        break;

      case "oem":
        updateSpecificFrequencies(oemFrequencies, setOemFrequencies);
        break;

      case "peso":
        updateSpecificFrequencies(pesoFrequencies, setPesoFrequencies);
        break;

      // Add additional cases for other frequency types if needed
      default:
        console.warn(`Unhandled frequency type: ${type}`);
    }
  };


  const handleAddFrequencyRow = () => {
    setOisdFrequencies([
      ...oisdFrequencies,
      {
        oisd_point: "",
        frequency: "",
        description: "",
        frequency_type: "oisd",
      },
    ]);
  };
  const handleCloseFrequencyRow = (index) => {
    setOisdFrequencies((prevRows) => prevRows.filter((_, i) => i !== index));
  };
  const handleAddFactoryActRow = () => {
    setFactoryActFrequencies([
      ...factoryActFrequencies,
      {
        factory_act: "",
        frequency: "",
        frequency_type: "factory_act",
      },
    ]);
  };
  const handleCloseFectoryActRow = (index) => {
    setFactoryActFrequencies((prevRows) =>
      prevRows.filter((_, i) => i !== index)
    );
  };

  const handleAddOEMRow = () => {
    setOemFrequencies([
      ...oemFrequencies,
      {
        frequency: "",
        frequency_type: "oem",
      },
    ]);
  };
  const handleCloseOemRow = (index) => {
    setOemFrequencies((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleAddPESORow = () => {
    setPesoFrequencies([
      ...pesoFrequencies,
      {
        peso_rule_no: "",
        frequency: "",
        frequency_type: "peso",
      },
    ]);
  };
  const handleClosePesoRow = (index) => {
    setPesoFrequencies((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Frequency
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="Department">Department</label>
                  <Select
                    id="department"
                    value={selectedDepartment}
                    onInputChange={handleInputChanges}
                    onKeyDown={handleKeyDown}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select a department"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">Sub Department</label>
                  <Select
                    value={selectedSubDepartment} // Corrected variable name
                    onChange={handleSubDepartmentChange}
                    options={subDepartments}
                    onKeyDown={handleSubDepartmentKeyDown}
                    onInputChange={handleInputChanges}
                    placeholder="Select sub department"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="Department">Equipment Type</label>
                  <Select
                    value={selectedEquipmentTypes} // Corrected variable name
                    onChange={handleEquipmentTypeChange}
                    options={equipmentTypes}
                    onKeyDown={handleTypeKeyDown}
                    onInputChange={handleInputChanges}
                    placeholder="Select equipment type"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="Department">Equipment Sub Type</label>
                  <Select
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    options={equipmentSubTypes}
                    onKeyDown={handleSubTypeKeyDown}
                    onInputChange={handleInputChanges}
                    placeholder="Select equipment sub type"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubTypeVariant">Sub Type Variant</label>
                  <Select
                    value={selectedSubTypeVariant}
                    onChange={handleTypeSubTypeVariantsChange}
                    options={equipmentSubTypeVariants}
                    onKeyDown={handleTypeVariantsKeyDown}
                    onInputChange={handleInputChanges}
                    placeholder="Select equipment sub type variant"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ContactNo"></label>
                  <div className="data-list-contentss" ref={containerRef}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="report tabs"
                      className="tabs-container"
                    >
                      <Tab
                        className={`config-tab ${
                          tabValue === 0 ? "selected" : ""
                        }`}
                        label="OISD"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 1 ? "selected" : ""
                        }`}
                        label="Factory Act"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 2 ? "selected" : ""
                        }`}
                        label="OEM Recommendation"
                      />
                      <Tab
                        className={`config-tab ${
                          tabValue === 3 ? "selected" : ""
                        }`}
                        label="PESO"
                      />
                    </Tabs>
                  </div>
                </div>

                {tabValue === 0 && (
                  <div>
                    {oisdFrequencies.map((row, index) => (
                      <>
                        <div className="form-group">
                          <label htmlFor="oisd_no">OISD No</label>
                          <div className="input-group">
                            <input
                              type="text"
                              value={row.oisd_no || ""}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "oisd",
                                  index,
                                  "oisd_no",
                                  e.target.value
                                )
                              }
                               placeholder="Enter OISD no"
                            />
                          </div>
                          <button
                            className="add-close-button"
                            type="button"
                            onClick={() => handleCloseFrequencyRow(index)}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>

                        <div className="form-group">
                          <label>Frequencies (As per OISD)</label>
                          <div className="new-frequency-row">
                            <>
                              <div
                               
                                className="frequency-row input-group"
                              >
                                <input
                                  type="text"
                                  className="point-fr"
                                  value={row.oisd_point}
                                  onChange={(e) =>
                                    handleFrequencyRowChange(
                                      "oisd",
                                      index,
                                      "oisd_point",
                                      e.target.value
                                    )
                                  }
                                   placeholder="Enter OISD Point"
                                />
                                <input
                                  type="number"
                                  className="point-fr"
                                  value={row.frequency}
                                  onChange={(e) =>
                                    handleFrequencyRowChange(
                                      "oisd",
                                      index,
                                      "frequency",
                                      e.target.value
                                    )
                                  }
                                placeholder="Frequency (in days)"
                                />
                              </div>
                              <div className="form-group">
                                <textarea
                                  type="message"
                                  rows={4}
                                  cols={1}
                                  className="textarea"
                                  value={row.description}
                                  onChange={(e) =>
                                    handleFrequencyRowChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Description"
                                />
                              </div>
                            </>
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="add-new-frequency">
                      <button
                        type="button"
                        className="add-new-buttons"
                        onClick={handleAddFrequencyRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}

                {tabValue === 1 && (
                  <div>
                    {factoryActFrequencies.map((row, index) => (
                      <>
                        <div className="form-group">
                          <label htmlFor="factory_act_rule_no">
                          Rule No
                          </label>
                          <div className="input-group">
                         
                            <input
                              type="text"
                              name="factory_act_rule_no"
                              value={row.factory_act_rule_no}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "factory_act",
                                  index,
                                  "factory_act_rule_no",
                                  e.target.value
                                )
                              }
                              placeholder="Enter factory act rule no"
                            />
                          </div>
                          <button
                            className="add-close-button"
                            type="button"
                            onClick={() => handleCloseFectoryActRow(index)}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>

                        <div className="form-group">
                          <label htmlFor="freuqency">Frequency</label>
                          <div className="input-group">
                            <input
                              type="number"
                              name="freuqency"
                              value={row.frequency}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "factory_act",
                                  index,
                                  "frequency",
                                  e.target.value
                                )
                              }
                            placeholder="Enter freuqency (in days)"
                            />
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddFactoryActRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}

                {tabValue === 2 && (
                  <div>
                    {oemFrequencies.map((row, index) => (
                      <>
                        <div className="form-group">
                          <label htmlFor="Frequency">Frequency</label>
                          <div className="input-group">
                            <input
                              type="number"
                              name="Frequency"
                              value={row.frequency}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "oem",
                                  index,
                                  "frequency",
                                  e.target.value
                                )
                              }
                            placeholder="Enter frequency (in days)"
                            />
                          </div>
                          <button
                            className="add-close-button"
                            type="button"
                            onClick={() => handleCloseOemRow(index)}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </>
                    ))}
                    <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddOEMRow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}

                {tabValue === 3 && (
                  <div>
                    {pesoFrequencies.map((row, index) => (
                      <>
                        <div className="form-group">
                          <label htmlFor="peso_rule_no">Rule No</label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="peso_rule_no"
                              value={row.peso_rule_no}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "peso",
                                  index,
                                  "peso_rule_no",
                                  e.target.value
                                )
                              }
                              placeholder="Enter peso rule no"
                            />
                          </div>
                          <button
                            className="add-close-button"
                            type="button"
                            onClick={() => handleClosePesoRow(index)}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>

                        <div className="form-group">
                          <label htmlFor="Frequency">Frequency</label>
                          <div className="input-group">
                            <input
                              type="number"
                              name="Frequency"
                              value={row.frequency}
                              onChange={(e) =>
                                handleFrequencyRowChange(
                                  "peso",
                                  index,
                                  "frequency",
                                  e.target.value
                                )
                              }
                             placeholder="Enter frequency (in days)"
                            />
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="add-new-frequency">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddPESORow}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditFrequency;
