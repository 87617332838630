
import React, { useState } from 'react';
import './UserForm.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import API_URL from "../../src/Config";

function UserTypeForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [userTypeData, setUserTypeData] = useState({
    Access: '',
    Name: '' 
  });

  const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  };

  const handleCancelClick = () => {
    navigate('/user-type');
  };

  const handleSaveClick = async () => {



    const updatedUserTypeData = {
      ...userTypeData,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem('authKey');


      const response = await axios.post(
        `${API_URL}/api/add_user`,
        updatedUserTypeData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        showSnackbar('Usertype addded successfully');
        setTimeout(() => {
          navigate('/user-type');
        }, 1000);

      }
    } catch (error) {
      console.error('Error saving user:', error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };




  return (
    <>
      <div className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New User Types
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
          <form className="form-detail">
              <div className="newperson-user-form">
            

                <div className="form-group">
                  <label htmlFor="EquipementType">Name<span className="text-danger">*</span></label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Name"
                      value={userTypeData.Name}
                      onChange={handleInputChange}
                      placeholder="Enter equipement type"
                    />

                  </div>
                
                </div>
                <div className="form-group">
                  <label htmlFor="Access">Access<span className="text-danger">*</span></label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Access"
                      value={userTypeData.Name}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: '35px', marginLeft: '20px' }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UserTypeForm;