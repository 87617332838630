import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./PreventiveSchedulesView.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function AmcSchedulesView({ isSidebarExpanded }) {
  const { schedule_id } = useParams();
  const [amcSchedulesData, setAmcSchedulesData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [docketError, setDocketError] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isOtherInfoPopupOpen, setIsOtherInfoPopupOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({
    manpower: "",
    consumables: "",
    spares: "",
    remarks: "",
  });
  const [otherInfo, setOtherInfo] = useState({
    manpower: "",
    consumables: "",
    spares: "",
    remarks: "",
  }); // State for other information
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleEditButtonClick = () => {
    // Initialize edit info with current values from schedulesData, assuming the first item for simplicity
    const currentInfo = amcSchedulesData.length > 0 ? amcSchedulesData[0] : {};
    setEditInfo({
      manpower: currentInfo.manpower || "",
      consumables: currentInfo.consumables || "",
      spares: currentInfo.spares || "",
      remarks: currentInfo.remarks || "",
    });
    setIsEditPopupOpen(true);
  };

  //   const handleEditInfoChange = (e) => {
  //     const { name, value } = e.target;
  //     setEditInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [name]: value,
  //     }));
  //   };
  const fetchAmcSchedules = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(
        `${API_URL}/api/get_amc_schedule/${schedule_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAmcSchedulesData(Array.isArray(data) ? data : []);
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  };

  useEffect(() => {
    fetchAmcSchedules();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/amc-schedules");
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);

    setOfferDocumentError("");
  };

  const handleIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const element = document.getElementById(`docket-file`);
    if (element) {
      element.click();
    }
  }, []);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/update_amc_schedule_status/${schedule_id}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const updatedSchedulesData = amcSchedulesData.map((amc) => ({
          ...amc,
          status: newStatus,
        }));
        setAmcSchedulesData(updatedSchedulesData);
        console.log("Scheduler status updated successfully");
      } else {
        console.error(
          "Failed to update scheduler status:",
          response.data.message
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while updating scheduler status:",
        error
      );
    }
  };

  //   const handleOtherInfoChange = (e) => {
  //     const { name, value } = e.target;
  //     setOtherInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [name]: value,
  //     }));
  //   };

  //   const handleOtherInfoSubmit = async () => {
  //     const authKey = localStorage.getItem("authKey");
  //     try {
  //       const response = await axios.put(
  //         `${API_URL}/api/update_other_information/${schedule_id}`,
  //         otherInfo,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setSnackbarMessage("Other information submitted successfully");
  //         setSnackbarSeverity("success");
  //         setSnackbarOpen(true);
  //         setIsOtherInfoPopupOpen(false);
  //         fetchAmcSchedules();
  //       } else {
  //         console.error(
  //           "Failed to submit other information:",
  //           response.data.message
  //         );
  //         setSnackbarMessage("Failed to submit other information");
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       console.error(
  //         "An error occurred while submitting other information:",
  //         error
  //       );
  //       setSnackbarMessage(
  //         "An error occurred while submitting other information"
  //       );
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   };

  //   const handleEditInfoSubmit = async () => {
  //     const authKey = localStorage.getItem("authKey");
  //     try {
  //       const response = await axios.put(
  //         `${API_URL}/api/update_other_information/${schedule_id}`,
  //         editInfo,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         setSnackbarMessage("Other information updated successfully");
  //         setSnackbarSeverity("success");
  //         setSnackbarOpen(true);
  //         setIsEditPopupOpen(false);
  //         fetchAmcSchedules();
  //       } else {
  //         console.error(
  //           "Failed to update other information:",
  //           response.data.message
  //         );
  //         setSnackbarMessage("Failed to update other information");
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       console.error(
  //         "An error occurred while updating other information:",
  //         error
  //       );
  //       setSnackbarMessage("An error occurred while updating other information");
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                AMC schedule details
              </div>
            </div>
            <div className="action-purchase-header">
              <div className="action-purchase-header-content">
              {amcSchedulesData.map((amc, index) => (
                    <div key={index}>
                      <div className="project-detail">
                        <div className="detail-header">AMC ID:</div>
                        <div className="detail-part">{amc.amc_id}</div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">AMC Name:</div>
                        <div className="detail-part">{amc.amc_name}</div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Company ID:</div>
                        <div className="detail-part">{amc.company_id}</div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Contractor Name:</div>
                        <div className="detail-part">{amc.contractor_name}</div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Created At:</div>
                        <div className="detail-part">
                          {new Date(amc.created_at).toLocaleString()}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Service Date:</div>
                        <div className="detail-part">
                          {new Date(amc.service_date).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Status:</div>
                        <div className="detail-part">{amc.status}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="invoice-body-container">
              <div className="invoice-body-container-content">
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    Attachments
                  </label>
                  <div className="file-name-label">
                    <label htmlFor="" className="file-name-lists">
                      File Name:
                    </label>
                    <span className="file-name"></span>
                  </div>
                  <div className="chalann-input">
                    <div className="input-group-attachments">
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleIconClick}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedFiles.length}`}
                        </span>
                      )}
                      {isPopupOpen && (
                        <div className="attach-popups">
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleCloseAttachment}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div className="file-delete-btn">
                                        {/* <span> <MdDeleteOutline className="file-delete-icon" /></span> */}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="invoice-upload-files">
                  {amcSchedulesData.map((amc, index) => (
                    <div key={index}>
                      <label className="upload-label" htmlFor="docket-file">
                        Current Status :
                        <span
                          className={
                            amc.status === "Completed"
                              ? "status-completed"
                              : amc.status === "Cancelled"
                              ? "status-cancelled"
                              : amc.status === "Hold"
                              ? "status-hold"
                              : ""
                          }
                        >
                          {amc.status}
                        </span>
                      </label>
                    </div>
                  ))}
                  <div>
                    <label className="upload-label" htmlFor="docket-file">
                      Change Status:
                    </label>
                    <select
                      className="custom-select"
                      onChange={handleStatusChange}
                    >
                      <option value="" disabled selected>
                        Select desire status
                      </option>
                      <option value="Completed">Completed</option>
                      <option value="Hold">Hold</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                    <button
                      type="button"
                      className="info-btn"
                      onClick={() => setIsOtherInfoPopupOpen(true)}
                    >
                      Add Other Information
                    </button>
                    <button
                      type="button"
                      className="info-btn"
                      onClick={handleEditButtonClick}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="action-purchase-header">
              <div className="action-purchase-header-content">
                <div className="additional-details-section">
                  <h3>Additional Details</h3>
                  {amcSchedulesData.map((equipment, index) => (
                    <div key={index} className="additional-detail-item">
                      <div className="detail-header">Manpower:</div>
                      <div className="detail-part">{equipment.manpower}</div>
                      <div className="detail-header">Consumables:</div>
                      <div className="detail-part">{equipment.consumables}</div>
                      <div className="detail-header">Spares:</div>
                      <div className="detail-part">
                        {equipment.spares || "N/A"}
                      </div>
                      <div className="detail-header">Remarks:</div>
                      <div className="detail-part">
                        {equipment.remarks || "N/A"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* {isOtherInfoPopupOpen && (
        <div className="other-info-popup">
          <div className="other-info-popup-content">
            <h3>Add Other Information</h3>
            <div className="input-group">
              <label htmlFor="manpower">Manpower:</label>
              <input
                type="text"
                id="manpower"
                name="manpower"
                value={otherInfo.manpower}
                onChange={handleOtherInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="consumables">Consumables:</label>
              <input
                type="text"
                id="consumables"
                name="consumables"
                value={otherInfo.consumables}
                onChange={handleOtherInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="spares">Spares:</label>
              <input
                type="text"
                id="spares"
                name="spares"
                value={otherInfo.spares}
                onChange={handleOtherInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="remarks">Remarks:</label>
              <textarea
                id="remarks"
                name="remarks"
                value={otherInfo.remarks}
                onChange={handleOtherInfoChange}
              ></textarea>
            </div>
            <div className="button-groups">
              <button
                type="button"
                className="info-btn"
                onClick={handleOtherInfoSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="close-btn"
                onClick={() => setIsOtherInfoPopupOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}
      {/* {isEditPopupOpen && (
        <div className="other-info-popup">
          <div className="other-info-popup-content">
            <h3>Edit Other Information</h3>
            <div className="input-group">
              <label htmlFor="manpower">Manpower:</label>
              <input
                type="text"
                id="manpower"
                name="manpower"
                value={editInfo.manpower}
                onChange={handleEditInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="consumables">Consumables:</label>
              <input
                type="text"
                id="consumables"
                name="consumables"
                value={editInfo.consumables}
                onChange={handleEditInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="spares">Spares:</label>
              <input
                type="text"
                id="spares"
                name="spares"
                value={editInfo.spares}
                onChange={handleEditInfoChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="remarks">Remarks:</label>
              <textarea
                id="remarks"
                name="remarks"
                value={editInfo.remarks}
                onChange={handleEditInfoChange}
              ></textarea>
            </div>
            <div className="button-groups">
              <button
                type="button"
                className="info-btn"
                onClick={handleEditInfoSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="close-btn"
                onClick={() => setIsEditPopupOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AmcSchedulesView;
