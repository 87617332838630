import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import Select, { components } from 'react-select';
import API_URL from "../../src/Config";
import { CiCirclePlus } from "react-icons/ci";
import axios from 'axios';

const { Control } = components;

const AddSubDepartmentPopup = ({ closePopup, handleNewSubDepartment, parentSelectedDepartment }) => {
   
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(parentSelectedDepartment); 
    const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
    const [subDepartmentData, setSubDepartmentData] = useState({
      DepartmentName: '',
      DepartmentId: '',
      SubDepartment: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setSubDepartmentData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    // const handleSaveClick = async () => {
    //   try {
    //     setIsLoading(true);
    //     const authKey = localStorage.getItem('authKey');
    
    //     // Ensure that DepartmentId and DepartmentName are properly set
    //     const updatedSubDepartmentData = {
    //       ...subDepartmentData,
    //       DepartmentId: selectedDepartment?.value,
    //       DepartmentName: selectedDepartment?.label,
    //     };
    
    //     const response = await axios.post(
    //       `${API_URL}/api/add_sub_department`,
    //       updatedSubDepartmentData,
    //       {
    //         headers: {
    //           Authorization: authKey,
    //         },
    //       }
    //     );
    
    //     console.log(response.data.message);
    //     if (response.status === 200) {
    //       handleNewSubDepartment(response.data); // Pass the new sub department data
    //       closePopup();
    //     }
    //   } catch (error) {
    //     console.error('Error saving department:', error.message);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };


    const handleSaveClick = async () => {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem('authKey');
    
        // Ensure that DepartmentId and DepartmentName are properly set
        const updatedSubDepartmentData = {
          ...subDepartmentData,
          DepartmentId: selectedDepartment?.value,
          DepartmentName: selectedDepartment?.label,
        };
    
        const response = await axios.post(
          `${API_URL}/api/add_sub_department`,
          updatedSubDepartmentData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
    
        console.log(response.data.message);
        if (response.status === 200) {
          const newSubDepartment = { 
            label: response.data.Name,
            value: response.data.id, 
          };
          handleNewSubDepartment(newSubDepartment); 
          closePopup();
        }
      } catch (error) {
        console.error('Error saving department:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
    
    
    const handleDepartmentMenuOpen = () => {
      setDepartmentMenuIsOpen(true);
    };
  
    const CustomControl = ({ children, ...props }) => (
      <Control {...props}>{children}</Control>
    );

    const handleDepartmentChange = (selectedOption) => {
      setSelectedDepartment(selectedOption);
      setSubDepartmentData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: selectedOption.label,
        DepartmentId: selectedOption.value,
      }));
    };
  
    const fetchDepartment = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_departments`;
    
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const options = data.map((department) => ({
            label: department.DepartmentName,
            value: department.ID
          }));
          setDepartments(options);
        } else {
          console.error("Failed to fetch departments");
        }
      } catch (error) {
        console.error("An error occurred while fetching departments:", error);
      }
    };
    
    useEffect(() => {
      fetchDepartment();
    }, []);

    return (
      <div className="inquiry-customerperson-popup-content">
        <div className="inquiry-customerperson-modal">
          <div className="inquiry-customerperson-modal-content">
            <div className='customerpersonpopupform-header'>
              <h4 className='customerpersonpopupform-title'>New Sub Department</h4>
              <span className='customerpersonpopupform-btn' onClick={closePopup}><IoCloseOutline /></span>
            </div>
            <div className="customerpersonpopupform-body">
              <form>
                <div className="customerpersonpopup-form">
                  <div className="form-group-select">
                    <label htmlFor="Department">Department<span className="text-danger">*</span></label>
                    <Select
                      id="departments"
                      options={departments}
                      value={selectedDepartment} // Set initial value based on selectedDepartment
                      onChange={handleDepartmentChange}
                      components={{ Control: CustomControl }}
                      placeholder="Select Department"
                      isSearchable
                      menuIsOpen={departmentMenuIsOpen}
                      onMenuOpen={handleDepartmentMenuOpen}
                      onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="SubDepartment">Sub Department<span className="text-danger">*</span></label>
                    <div className="input-groups">
                      <input
                        type="text"
                        name="SubDepartment"
                        value={subDepartmentData.SubDepartment}
                        onChange={handleInputChange}
                        placeholder="Enter Sub Department"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="customerpersonpopupform-footer">
              <div className="button-group">
                <button className="customerpersonpopupform-save-button" onClick={handleSaveClick} disabled={isLoading}>
                  {isLoading ? 'Saving...' : 'Save'}
                </button>
                <button className="customerpersonpopupform-cancel-button" onClick={closePopup}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AddSubDepartmentPopup;
