import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import ProjectView from "../view/ProjectView";

function Peoject({ isSidebarExpanded, projectID }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [popupUserID, setPopupUserID] = useState(null);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const containerRef = useRef(null);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_projects`, {
        headers: {
          Authorization: authKey,
        },
      });

      setProjects(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching project data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
        },
      });

      if (response.status === 200) {
        setProjects(response.data);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch();
    }, 600);
    return () => clearTimeout(delay);
  }, [searchInput]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? projects.map((project) => project.id) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (projectID) => {
    const updatedSelectedRows = selectedRows.includes(projectID)
      ? selectedRows.filter((id) => id !== projectID)
      : [...selectedRows, projectID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === projects.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveProject = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedProjects = projects.filter(
          (project) => !selectedRows.includes(project.ID)
        );
        setProjects(updatedProjects);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete projects");
      }
    } catch (error) {
      console.error("An error occurred while deleting projects:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-project");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const filteredUsers = departments.filter((user) => {
  //   return (
  //     user.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Designation.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.ContactNo.toLowerCase().includes(searchInput.toLowerCase()) ||
  //     user.Department.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  // });

  // const sortedUsers = () => {
  //   if (sortColumn) {
  //     return filteredUsers.slice().sort((a, b) => {
  //       const first = a[sortColumn].toUpperCase();
  //       const second = b[sortColumn].toUpperCase();

  //       if (first < second) {
  //         return sortOrder === "asc" ? -1 : 1;
  //       }
  //       if (first > second) {
  //         return sortOrder === "asc" ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return filteredUsers;
  // };
  // const handleSortOrderToggle = () => {
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  // };

  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  const togglePopup = (projectID) => {
    setPopupUserID(projectID);
    setIsMainContainerVisible(true); 
  };
  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); // Close the user view
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveProject}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Projects
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search Projects..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/project-form" className="data-list-new">
                      <Tooltip title="New Project" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          <button
                            className="delete-item"
                            onClick={handleArchiveDeparment}
                          >
                            Archived Project
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : projects.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>PROJECT NAME</th>
                          <th>CUSTOMER NAME</th>

                          <th>PO NUMBER</th>
                          <th>PROJECT TYPE</th>
                          <th>START DATE</th>
                          <th>END DATE</th>
                          <th>LOCATION</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {projects.map((project, index) => (
                          <tr key={project.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(project.id)}
                                onChange={() => handleSelectRow(project.id)}
                              />
                            </td>

                            <td>{index + 1}</td>
                            {/* <td>{project.project_name}</td> */}
                            <td className="view-data" onClick={() => togglePopup(project.id)}>{project.project_name ||"NA"}</td>
                            <td>{project.customer_name}</td>

                            <td>{project.po_number}</td>

                            <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    project.project_type === "Construction"
                                      ? "#6AAB9C"
                                      : project.project_type === "O & M"
                                      ? "#E06C78"
                                      : project.project_type === "Maintenance"
                                      ? "#384E78"
                                      : "transparent",
                                }}
                              >
                                {project.project_type || "NA"}
                              </div>
                            </td>

                            <td>{formatDate(project.start_date)}</td>
                            <td>{formatDate(project.end_date)}</td>
                            <td>{project.customer_location}</td>

                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-project/${project.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Project Found</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isMainContainerVisible && (
          <div className="main-containers">
            <ProjectView projectID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Peoject;
