import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from "react-select";
import DatePicker from "react-datepicker";
import API_URL from "../../src/Config";
import { format } from 'date-fns';

function ExpenseForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [technikPersons, setTechnikPersons] = useState([]);
  const [expenseType, setExpenseType] = useState("Fuel");
  const [projectError, setProjectError] = useState("");
  const [personError, setPersonError] = useState("");
  const [dateError, setDateError] = useState("");
  const [valueError, setValueError] = useState("");
  
  const [selectedTechnikPerson, setSelectedTechnikPerson] = useState(null);
  const [formData, setFormData] = useState({
    project_id: '',
    project_name: '',
    person_name: '',
    value: '',
    description: '',
    type_of_expense: '',


  });

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, 'yyyy/MM/dd'); // Format the date
    handleChange("date", formattedDate); // Update formData with formatted date
  };



  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear error message for the corresponding field
    switch (name) {
      case 'project_id':
        setProjectError('');
        break;
      case 'person_name':
        setPersonError('');
        break;
      case 'date':
        setDateError('');
        break;
      case 'value':
        setValueError('');
        break;
      default:
        break;
    }
  };

  const options = [
    {
      value: "Travel",
      label: "Travel",
    },
    { value: "Material", label: "Material" },
    {
      value: "Food",
      label: "Food",
    },
    {
      value: "MISC",
      label: "MISC",
    },
  ];

  const handleCancelClick = () => {
    navigate('/expense-detail');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_projects`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        setProjects(response.data);
      } else {
        console.error("Failed to fetch projects");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching projects:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

 
  const fetchTechnikPersons = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_user`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log("Response data:", response.data);

      if (response.status === 200) {
        const users = response.data; 
        const technikPersonsNames = users.map((person) => ({
          label: person.Name,
          value: person.ID,
        }));
        setTechnikPersons(technikPersonsNames);
      } else {
        console.error("Failed to fetch sales persons");
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    fetchTechnikPersons();
  }, []);

  const handleSaveClick = async () => {
    const isProjectValid = validateProject();
    const isPersonValid = validatePerson();
    const isDateValid = validateDate();
    const isValueValid = validateValue();
    
    if (isProjectValid &&
      isPersonValid &&
      isDateValid &&
      isValueValid
    ) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem('authKey');
        const apiUrl = `${API_URL}/api/add_expense`;

        // Format the date before sending it in the request
        const formattedDate = selectedDate ? format(selectedDate, 'yyyy/MM/dd') : '';

        const updatedFormData = {
          ...formData,
          date: formattedDate, // Update the date field in formData
        };

        const response = await axios.post(apiUrl, updatedFormData, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.status === 200) {
          showSnackbar('Expense added successfully');
          setTimeout(() => {
            navigate('/expense-detail');
          }, 1000);
        } else {
          console.error('Failed to add expense');
        }
        setIsLoading(false);
      } catch (error) {
        console.error('An error occurred while adding expense:', error);
        setIsLoading(false);
      }
    }
  };

  const validateProject = () => {
    if (!selectedProject) {
      setProjectError("Select Project");
      return false;
    }
    setProjectError("");
    return true;
  };
  const validatePerson = () => {
    if (!selectedTechnikPerson) {
      setPersonError("Select Person");
      return false;
    }
    setPersonError("");
    return true;
  };
  const validateDate = () => {
    if (!selectedDate) {
      setDateError("Select Date");
      return false;
    }
    setDateError("");
    return true;
  };
  const validateValue = () => {
    if (!formData.value) {
      setValueError("Enter Value");
      return false;
    }
    setValueError("");
    return true;
  };


  return (
    <>
      <div className={`data-form-content-container ${isSidebarExpanded ? "expanded" : "collapsed"}`}>
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Add Expense
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form action="">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="companyName">
                    Select Project<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={projects.map((project) => ({
                      value: project.ID,
                      label: project.ProjectName,
                    }))}
                    value={selectedProject}
                    onChange={(selectedOption) => {
                      handleChange("project_id", selectedOption.value);
                      handleChange("project_name", selectedOption.label);
                      setSelectedProject(selectedOption);
                    }}
                    placeholder="Select Project"
                  />
                  {projectError && (
                    <span className="error-messages">{projectError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="Technik_Company">
                    Select Person<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={technikPersons}
                    value={selectedTechnikPerson}
                    onChange={(selectedOption) => {
                      handleChange("person_name", selectedOption.label);
                      setSelectedTechnikPerson(selectedOption);
                    }}
                    placeholder="Select Person"
                  />
                  {personError && (
                    <span className="error-messages">{personError}</span>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="Technik_Company">
                    Type Of Expense <span className="text-danger">*</span>
                  </label>
                  <Select
                    name="type_of_expense"
                    value={options.find((option) => option.value === formData.type_of_expense)}
                    options={options}
                    onChange={(selectedOption) => handleChange("type_of_expense", selectedOption.value)} 
                    placeholder="Select expence type"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Date">Date<span className='text-danger'>*</span></label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                      minDate={new Date(new Date().setDate(new Date().getDate() - 3))}
                    />

                  </div>
                  {dateError && (
                    <span className="error-messages">{dateError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="Value">Value<span className='text-danger'>*</span></label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="value"
                      name="value"
                      value={formData.value}
                      onChange={(e) => handleChange("value", e.target.value)}
                      placeholder="Enter Value"
                    />

                  </div>
                  {valueError && (
                    <span className="error-messages">{valueError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="Description">Description</label>
                  <div className="input-group">
                    <textarea
                      cols="23"
                      rows="4"
                      name="description"
                      value={formData.description} // Add this line to bind with formData
                      onChange={(e) => handleChange("description", e.target.value)}

                      placeholder="Enter Description"
                    />
                  </div>
                </div>

              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ExpenseForm;
