import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import API_URL from "../../src/Config";

function UpdateLevelThreeForm({ isSidebarExpanded }) {
  const { l3_id } = useParams(); // Assume the Level 3 ID is passed via URL params
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [levelOneData, setLevelOneData] = useState([]);
  const [levelTwoData, setLevelTwoData] = useState([]);
  const [formData, setFormData] = useState({
    levelThree: "",
    Description: "",
    levelOneId: "",
    levelTwoId: "",
  });

  const navigate = useNavigate();

  const [levelOptions, setLevelOptions] = useState(
    Array.from({ length: 100 }, (_, i) => ({
      value: i.toString().padStart(2, "0"),
      label: i.toString().padStart(2, "0"),
    }))
  );

  const handleLevelThreeChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelThree: selectedOption ? selectedOption.value : "", 
    }));
    setErrors((prevState) => ({
      ...prevState,
      levelTwo: "",
    }));
  };

  const fetchLevelOneData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_mat_code_level_ones`, {
        headers: {
          Authorization: authKey,
        },
      });

      const options = response.data.map((level) => ({
        label: level.level,
        value: level.id,
      }));

      setLevelOneData(options);
    } catch (error) {
      console.error("Error fetching level1 data:", error.message);
    }
  };

  const fetchLevelTwoData = async (levelOneId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_two_by_level_one/${levelOneId}`, {
        headers: {
          Authorization: authKey,
        },
      });
      const options = response.data.map((item) => ({
        label: item.level,
        value: item.id,
      }));
      setLevelTwoData(options);
    } catch (error) {
      console.error("Error fetching Level 2 data:", error.message);
    }
  };

  const fetchLevelThreeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_level_three/${l3_id}`, {
        headers: {
          Authorization: authKey,
        },
      });

      const { level, description, level_one_id, level_two_id } = response.data;

      setFormData({
        levelThree: level,
        Description: description,
        levelOneId: level_one_id,
        levelTwoId: level_two_id,
      });

      // Fetch Level 2 data based on Level 1 ID
      fetchLevelTwoData(level_one_id);
    } catch (error) {
      console.error("Error fetching Level 3 data:", error.message);
    }
  };

  useEffect(() => {
    fetchLevelOneData();
    fetchLevelThreeData(); // Fetch existing Level 3 data when component loads
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleLevelOneChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelOneId: selectedOption ? selectedOption.value : "",
      levelTwoId: "", // Reset Level 2 when Level 1 changes
    }));
    fetchLevelTwoData(selectedOption ? selectedOption.value : "");
  };

  const handleLevelTwoChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      levelTwoId: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleCancelClick = () => {
    navigate("/equipment-type-mat-code-form");
  };

  const handleUpdateClick = async () => {
    const errors = {};
    let error = false;
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning");
        error = true;
      }
    });
  
    if (error) {
      showSnackbar("Required fields are empty");
      return; 
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const response = await axios.put(
        `${API_URL}/api/update_mat_code_level_three/${l3_id}`,
        {
          level: formData.levelThree,
          description: formData.Description,
          level_two_id: formData.levelTwoId,
          level_one_id: formData.levelOneId, 
        },
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Level 3 updated successfully");
        setTimeout(() => {
          navigate("/equipment-type-mat-code-form");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating Level 3:", error.message);
      setSeverity("error");
      showSnackbar("Failed to update Level 3");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                Edit Level 3
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="LevelOne">
                    Level 1<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelOneData}
                    value={levelOneData.find(option => option.value === formData.levelOneId)}
                    onChange={handleLevelOneChange}
                    placeholder="Select Level 1"
                    isClearable
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="LevelTwo">
                    Level 2<span className="text-danger">*</span>
                  </label>
                  <Select
                    options={levelTwoData}
                    value={levelTwoData.find(option => option.value === formData.levelTwoId)}
                    onChange={handleLevelTwoChange}
                    placeholder="Select Level 2"
                    isClearable
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="level">
                    Level 3<span className="text-danger">*</span>
                  </label>

                  <Select
                    name="levelThree"
                    value={levelOptions.find(
                      (option) => option.value === formData.levelThree
                    )}
                    onChange={handleLevelThreeChange}
                    options={levelOptions}
                    placeholder="Select Level 3"
                    isClearable
                  />
                  {errors.levelThree && (
                    <div className="error-message">{errors.levelThree}</div>
                  )}

                </div>
                <div className="form-group">
                  <label htmlFor="DepartmentCode">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Description"
                      value={formData.Description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                    {errors.Description && (
                      <div className="error-message">
                        {errors.Description}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleUpdateClick}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UpdateLevelThreeForm;
