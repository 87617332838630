import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { GiBackwardTime } from "react-icons/gi";
import API_URL from "../../src/Config";
import "./PRRequestView.css";
import { LuDownload, LuEye } from "react-icons/lu";

function PRRequest({ isSidebarExpanded }) {
  const { pr_request_id } = useParams();
  const [prRequestData, setPRRequestData] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPRRequestData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await fetch(
          `${API_URL}/api/get_pr_request_list/${pr_request_id}`,
          {
            method: "GET",
            headers: {
              Authorization: authKey,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setPRRequestData(data);
        } else {
          console.error("Error fetching PR request data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching PR request data:", error.message);
      }
    };

    fetchPRRequestData();
  }, [pr_request_id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAction = async (action) => {
    const authKey = localStorage.getItem("authKey");
    const status = action;
    if (
      (status === "Rejected" || status === "Returned") &&
      remark.trim() === ""
    ) {
      setSnackbarMessage(`Remarks are required for ${status} action`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}/api/accept_pr_request/${prRequestData.pr_request_id}`,
        {
          pr_status: status,
          remarks: remark,
        },
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage(`PR Request ${status}ed successfully`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        navigate("/pr-requests-list");
      } else {
        setSnackbarMessage(`Failed to ${status} PR Request`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`An error occurred while ${status}ing PR Request`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  if (!prRequestData) {
    return <div>Loading...</div>;
  }

  const handleBackScheduler = () => {
    navigate("/pr-requests-list");
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#D4AC0D";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      case "Returned":
        return "#ff9800";
      default:
        return "black";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Pending":
        return (
          <MdOutlineWatchLater
            style={{
              fontSize: "1.1rem",
              color: "#D4AC0D",
              verticalAlign: "top",
            }}
          />
        );
      case "Approved":
        return (
          <FaRegCheckCircle
            style={{
              fontSize: "1.1rem",
              color: "green",
              verticalAlign: "top",
              marginTop: "2px",
            }}
          />
        );
      case "Rejected":
        return (
          <RxCrossCircled
            style={{
              fontSize: "1.1rem",
              color: "red",
              verticalAlign: "top",
              marginTop: "2px",
            }}
          />
        );
      case "Returned":
        return (
          <GiBackwardTime
            style={{
              fontSize: "1.1rem",
              color: "#ff9800",
              verticalAlign: "top",
              marginTop: "2px",
            }}
          />
        );
      default:
        return null;
    }
  };

  const handleViewAttachment = (attachment) => {
    window.open(
      `${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`,
      "_blank"
    );
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                PR Request Details
              </div>
            </div>
            <div className="action-purchase-header">
              <div className="action-purchase-header-content">
                {prRequestData && (
                  <div>
                    <div className="project-detailss">
                      <div className="project-detail-arround">
                        <div className="project-details-left-side">
                          <div className="project-detail">
                            <div className="detail-header">PR Request ID:</div>
                            <div className="detail-part">
                              {prRequestData.pr_request_id}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Description:</div>
                            <div className="detail-part">
                              {prRequestData.vendordescription_type || "NA"}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Est. Cost:</div>
                            <div className="detail-part">
                              {prRequestData.est_cost}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">
                              Expected Delivery Date:
                            </div>
                            <div className="detail-part">
                              {prRequestData.expected_delivery_date}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Qty:</div>
                            <div className="detail-part">
                              {prRequestData.qty}
                            </div>
                          </div>
                          <div className="project-detail">
                            <div className="detail-header">Status:</div>
                            <div className="detail-part">
                              {prRequestData.pr_status}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="invoice-body-container">
              <div className="invoice-body-container-content">
            
              <div className="invoice-upload-files">
  <label className="upload-label" htmlFor="docket-file">
    Attachments:
  </label>
  {prRequestData.attachments?.length > 0 ? (
    prRequestData.attachments.map((attachment, index) => (
      <div key={index} className="">
        <div className="file-name-label">
          <div className="filename">
            <label htmlFor="" className="file-name-lists">
              File Name:
            </label>
            <span className="file-name">
              {attachment.filename.length > 15
                ? `${attachment.filename.substring(0, 25)}...`
                : attachment.filename}
            </span>
          </div>
          <div className="button-fleex">
            <button
              className="view-attachment"
              onClick={() => handleViewAttachment(attachment)}
            >
              <LuEye className="view-attachment-icon" />
            </button>
            <a
              href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
              download
              className="download-attachment"
            >
              <LuDownload className="download-attachment-icon" />
            </a>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="file-name-message">Document details not found</div>
  )}
</div>

                
                <div class="invoice-upload-files">
                {prRequestData.pr_status === "Pending" ? (
                    <>
                  <div class="form-remark-group">
                    <label>Remark:</label>
                    <input
                      type="text"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      className="remark-input"
                    />
                  </div>
                  
                  
                      <div className="status-view-button form-remark-group">
                        <label>Action:</label>
                        <div className="action-button">
                          <button
                            onClick={() => handleAction("Approved")}
                            className="accept-btn"
                          >
                            Approve
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => handleAction("Rejected")}
                            className="reject-btn"
                          >
                            Reject
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => handleAction("Returned")}
                            className="return-btn"
                          >
                            Return
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                    <div class="form-remark-group">
                    <label>Remark:</label>
                    <input
                      type="text"
                      value={prRequestData.remarks || ""} // Set the value attribute to the 'remark' variable
                      readOnly // Make the input readonly if you don't want it to be editable
                      className="remark-input"
                    />
                  </div>
                  <label>Request Status:</label>
                    <span
                      className="status-color"
                      style={{
                        color: getStatusColor(prRequestData.pr_status),
                      }}
                    >
                      {" "}
                      {getStatusIcon(prRequestData.pr_status)}{" "}
                      {prRequestData.pr_status || "NA"}
                    </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequest;
