import React, { useState, useRef } from "react";
import "./ReportPage.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SchedulesReport from "../Pages/SchedulesReport";
import AmcReports from "../Pages/AmcReports";
import BreakdownReports from "../Pages/BreakdownReports";
import ProcurmentReports from "../Pages/ProcurmentReports";
import { Button, TextField, Tabs, Tab } from "@mui/material";

function ReportPage({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State for tab navigation
  const containerRef = useRef(null);


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

 

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          
          <div className="data-list-content" ref={containerRef}>
             <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="report tabs"
              className="tabs-container"
            >
              <Tab label="Preventive reports" />
              <Tab label="AMC reports" />
              <Tab label="breakdown reports" />
              <Tab label="procurement reports" />
            </Tabs>
            <div className="header-separater"></div>
            <div className="tabs-content">
            {tabValue === 1 && <div><AmcReports/></div>}
              {tabValue === 0 && <div><SchedulesReport/></div>}
             
              {tabValue === 2 && <div><BreakdownReports/></div>}
              {tabValue === 3 && <div><ProcurmentReports/></div>}
            </div>
          </div>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ReportPage;
