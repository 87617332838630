import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns";
import { IoCloseOutline } from "react-icons/io5";

function EditPRRequest({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [prRequestData, setPrRequestData] = useState({
    department_name: "",
    department_id: "",
    pr_type: "",
    description: "",
    mat_code: "",
    qty: "",
    unit: "",
    est_unit_rate: "",
    preferred_vendor: "",
    vendor_name: "",
    vendor_id: "",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [matCodes, setMatCodes] = useState([]);
  const [selectedMatCode, setSelectedMatCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [vendorType, setVendorType] = useState("");
  const fileInputRef = useRef(null);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    const fetchPRRequestData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_pr_request/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;
        setSelectedDepartment({
          value: responseData.department_id,
          label: responseData.department_name,
        });
        setSelectedVendor({
          value: responseData.vendor_id,
          label: responseData.vendor_name,
        });
        setSelectedMatCode({
          label: responseData.mat_code,
        });
        const formattedData = {
          department_name: responseData.department_name,
          vendor_name: responseData.vendor_name,
          pr_type: responseData.pr_type,
          description: responseData.description,
          mat_code: responseData.mat_code,
          qty: responseData.qty,
          unit: responseData.unit,
          est_unit_rate: responseData.est_unit_rate,
          preferred_vendor: responseData.preferred_vendor,
          expected_delivery_date: new Date(responseData.expected_delivery_date),
        };
        setPrRequestData(formattedData);
        setSelectedDeliveryDate(new Date(responseData.expected_delivery_date));
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchPRRequestData();
  }, [id]);

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_details`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Transform data to match Select component's expected format
      const transformedData = response.data.mat_code_details.map((matCode) => ({
        value: matCode.mat_code_id,
        label: matCode.mat_code,
      }));

      setMatCodes(transformedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToDelete)
    );
  };
  const handleVendorTypeChange = (event) => {
    const vendorTypeValue = event.target.value;
    setVendorType(vendorTypeValue);
    setPrRequestData((prevState) => ({
      ...prevState,
      preferred_vendor: vendorTypeValue,
    }));
  };

  const prTypeOptions = [
    { value: "AMC", label: "AMC" },
    { value: "Spare", label: "Spare" },
    { value: "Service", label: "Service" },
    { value: "Work Order", label: "Work Order" },
  ];

  const unitOptions = [
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "m", label: "Meter (m)" },
    { value: "centimeter", label: "Centimeter (cm)" },
    { value: "millimeter", label: "Millimiter (mm)" },
    { value: "liters", label: "Liters (L)" },
    { value: "inch", label: "Inch (in)" },
    { value: "gram", label: "Gram (g)" },
    { value: "milligram", label: "Milligram (mg)" },
    { value: "kilometer", label: "Kilometer (km)" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrRequestData({ ...prRequestData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/pr-request");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUnitChange = (selectedOption) => {
    setPrRequestData((prevState) => ({
      ...prevState,
      unit: selectedOption ? selectedOption.value : "",
    }));
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setPrRequestData((prevFormData) => ({
      ...prevFormData,
      vendor_name: selectedOption ? selectedOption.label : "",
      vendor_id: selectedOption ? selectedOption.value : "",
    }));
  };
  const handleSaveClick = async () => {
    const errors = {};
    let error = false;

    if (!error) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");

        // Make sure all fields from prRequestData are included in the payload
        const payload = {
          ...prRequestData,
          expected_delivery_date: selectedDeliveryDate
            ? format(selectedDeliveryDate, "yyyy-MM-dd")
            : "",
        };
        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
          formData.append(key, payload[key]);
        });
        // Append PR request data to FormData
        // for (const key in prRequestData) {
        //   formData.append(key, prRequestData[key]);
        // }

        if (attachmentFile) {
          attachmentFile.forEach((file) => {
            formData.append("attachments", file);
            formData.append("filetype", "pr_specification");
          });
        }

        const response = await axios.put(
          `${API_URL}/api/update_pr_request/${id}`,
          formData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/pr-request");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating PR request:", error.message);
        setError("An error occurred while updating PR request data");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackProject = () => {
    navigate("/pr-request");
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setPrRequestData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const handleSelectChange = (selectedOption) => {
    setPrRequestData({ ...prRequestData, pr_type: selectedOption.value });
  };
  const fetchVendors = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Assuming response.data is already parsed as JSON
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleDeliveryDateChange = (date) => {
    setSelectedDeliveryDate(date);
    setPrRequestData((prevState) => ({
      ...prevState,
      expected_delivery_date: date,
    }));
  };

  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  const handleGenerateRequest = async (id) => {
    try {
      const authKey = localStorage.getItem("authKey");

      // Prepare payload
      const payload = {
        ...prRequestData,
        expected_delivery_date: selectedDeliveryDate
          ? format(selectedDeliveryDate, "yyyy-MM-dd")
          : "",
      };

      const response = await axios.put(
        `${API_URL}/api/update_direct_pr_request/${id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Scheduler generated successfully");
      } else {
        console.error("Failed to generate scheduler");
      }
    } catch (error) {
      console.error("An error occurred while generating scheduler:", error);
    }
  };
  const handleMatCodeChange = (selectedOption) => {
    setSelectedMatCode(selectedOption);
    setPrRequestData((prevState) => ({
      ...prevState,
      mat_code: selectedOption ? selectedOption.label : "",
    }));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit PR Request
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={() => setDepartmentMenuIsOpen(true)}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="pr_type">
                    PR Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="pr_type"
                    value={prTypeOptions.find(
                      (option) => option.value === prRequestData.pr_type
                    )}
                    onChange={handleSelectChange}
                    options={prTypeOptions}
                    placeholder="Select PR Type"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="mat_code">Mat Code</label>

                  <Select
                    id="mat_code"
                    options={matCodes}
                    value={selectedMatCode}
                    onChange={handleMatCodeChange}
                    placeholder="Select Mat code"
                    isSearchable
                  />
                </div>
                <div className="form-group-select">
                  <label>Unit</label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (option) => option.value === prRequestData.unit
                    )}
                    onChange={handleUnitChange}
                    placeholder="Select Unit"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="qty">
                    Quanity <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="qty"
                      value={prRequestData.qty}
                      onChange={handleInputChange}
                      placeholder="Enter quantity"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="est_unit_rate">
                    Estimated Unit Rate (E.U.R)
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="est_unit_rate"
                      value={prRequestData.est_unit_rate}
                      onChange={handleInputChange}
                      placeholder="Enter unit rate"
                    />
                  </div>
                </div>

                <div className="form-group-select">
                  <label htmlFor="preferred_vendor">
                    Prefered Vendor<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="oem"
                      name="preferred_vendor"
                      value="OEM"
                      checked={prRequestData.preferred_vendor === "OEM"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="oem">OEM</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="openMarket"
                      name="preferred_vendor"
                      value="Open Market"
                      checked={prRequestData.preferred_vendor === "Open Market"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="openMarket">OpenMarket</label>
                  </div>
                </div>
                {prRequestData.preferred_vendor === "OEM" && (
                  <div className="form-group-select">
                    <label htmlFor="vendor_name">
                      Preferred Vendor Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="vendor_name"
                      options={vendors}
                      value={selectedVendor}
                      onChange={handleVendorChange}
                      placeholder="Select vendor name"
                      isSearchable
                    />
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="expected_delivery_date">
                    Expected Delivery Date{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDeliveryDate}
                      onChange={handleDeliveryDateChange}
                      name="expected_delivery_date"
                      id="expected_delivery_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select delivery date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      value={prRequestData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Technical Specification Attachement{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={handleUploadButtonClick}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="attachments"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup-amc">
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save as Draft"}
              </button>
              <button
                className="request-button"
                onClick={() => handleGenerateRequest(id)}
              >
                Send Request
              </button>

              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditPRRequest;
