import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import API_URL from "../../src/Config";

const AddMenuItemAccess = ({ onClose }) => {
  const [projectTypeId, setProjectTypeId] = useState("");
  const [menuItemIds, setMenuItemIds] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProjectTypes = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_project_types`, {
          headers: {
            Authorization: authKey,
          },
        });
        setProjectTypes(response.data);
      } catch (error) {
        console.error("Error fetching project types:", error.message);
      }
    };

    const fetchMenuItems = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_all_menu_items`, {
          headers: {
            Authorization: authKey,
          },
        });
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchProjectTypes();
    fetchMenuItems();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const authKey = localStorage.getItem("authKey");
      await axios.post(`${API_URL}/api/insert_menu_item_access`, {
        project_type_id: projectTypeId,
        menu_item_ids: menuItemIds.map(item => item.value),
      }, {
        headers: {
          Authorization: authKey,
        },
      });
      onClose();
    } catch (error) {
      setError("An error occurred while saving the data");
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <h2>Add Menu Item Access</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Project Type:</label>
          <select
            value={projectTypeId}
            onChange={(e) => setProjectTypeId(e.target.value)}
            required
          >
            <option value="">Select Project Type</option>
            {projectTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.type_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Menu Items:</label>
          <Select
            isMulti
            value={menuItemIds}
            onChange={(selected) => setMenuItemIds(selected)}
            options={menuItems.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
};

export default AddMenuItemAccess;
