import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./PreventiveSchedulesView.css";
import "../forms/AmcSchedules.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Select from "react-select"; // Import react-select
import DatePicker from "react-datepicker";
import { AiOutlineDownload } from "react-icons/ai";
import { format } from "date-fns";

function PreventiveSchedulesView({ isSidebarExpanded }) {
  const { scheduler_id } = useParams();
  const [schedulesData, setSchedulesData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dateError, setDateError] = useState("");
  const [status, setStatus] = useState("");
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [completedDate, setCompletedDate] = useState(null);
  const [isOtherInfoPopupOpen, setIsOtherInfoPopupOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [editInfo, setEditInfo] = useState({
    manpower: "",
    consumables: "",
    spares: "",
    remarks: "",
  });
  const [otherInfo, setOtherInfo] = useState({
    manpower: "",
    consumables: "",
    spares: "",
    remarks: "",
  });
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleEditInfoChange = (e) => {
    const { name, value } = e.target;
    setEditInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handlePreviewClick = (url) => {
    setPreviewUrl(url);
  };

  // Close the preview modal
  const handleCloseModal = () => {
    setPreviewUrl(null);
  };
  const today = new Date();

  const fetchScheduler = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(
        `${API_URL}/api/get_scheduler/${scheduler_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSchedulesData(data);
        const { status } = response.data;
        setStatus(status);
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  };

  useEffect(() => {
    fetchScheduler();
  }, [scheduler_id]);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
      return string; // Return as is if not a valid string or empty
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleSubmit = async () => {
    if (status === "Completed" && !completedDate) {
      setDateError("Please select a service date.");
      return;
    }

    const formattedDate = completedDate
      ? format(new Date(completedDate), "yyyy-MM-dd")
      : undefined;

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_scheduler_status/${scheduler_id}`,
        {
          status: status,
          service_date: formattedDate,
        },
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Scheduler status updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchScheduler(); // Refresh the scheduler data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to update scheduler status");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating scheduler status";
      console.error("An error occurred while updating scheduler status:", error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDefaultStatus = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/reset_status_to_default/${scheduler_id}`,
        {},
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Status reset to default successfully");
        setSnackbarSeverity("success");
        fetchScheduler(); // Refresh the data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to reset status to default");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while resetting status";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const formatter = new Intl.DateTimeFormat("en-GB", {
  //     day: "2-digit",
  //     month: "2-digit",
  //     year: "2-digit",
  //   });
  //   return formatter.format(date);
  // };

  const formatDate = (dateString) => {
    // Check if dateString is valid
    if (!dateString || isNaN(Date.parse(dateString))) {
      return "N/A";
    }

    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formatter.format(date);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "overdue", label: "Overdue" },
    { value: "Completed", label: "Completed" },
    { value: "Hold", label: "Hold" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "In Progress", label: "In Progress" },
    { value: "not in tenure", label: "Not in Tenure" },
  ];



  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/all-schedules");
  };

  const handleCancel = () => {
    navigate("/all-schedules");
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newFileDetails = Array.from(files).map((file) => ({
        name: file.name,
        size: file.size,
      }));

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFileDetails]);
      setAttachmentFiles((prevFiles) => [...prevFiles, ...files]);
    }
    setOfferDocumentError("");
  };

  useEffect(() => {
    if (attachmentFiles.length > 0) {
      handleUploadSubmit();
    }
  }, [attachmentFiles]);


  // const handleStatusChange = async (selectedOption) => {
  //   if (!selectedOption) return;

  //   const newStatus = selectedOption.value;
  //   const authKey = localStorage.getItem("authKey");

  //   // Format the completedDate if status is "Completed"
  //   const formattedDate =
  //     newStatus === "Completed" && completedDate
  //       ? format(new Date(completedDate), "yyyy-MM-dd")
  //       : undefined;

  //   setStatus(newStatus); 
  //   try {
  //     const response = await axios.put(
  //       `${API_URL}/api/update_scheduler_status/${scheduler_id}`,
  //       {
  //         status: newStatus,
  //         // service_date: formattedDate, // Include formatted date if status is "Completed"
  //         service_date: formattedDate,
  //       },
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const { message } = response.data;
  //       const updatedSchedulesData = schedulesData.map((equipment) => ({
  //         ...equipment,
  //         status: newStatus,
  //         service_date: formattedDate,
  //       }));
  //       setSchedulesData(updatedSchedulesData);
  //       setSnackbarMessage(message || "Scheduler status updated successfully");
  //       setSnackbarSeverity("success");
  //     } else {
  //       const { message } = response.data;
  //       setSnackbarMessage(message || "Failed to update scheduler status");
  //       setSnackbarSeverity("error");
  //     }
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message ||
  //       "An error occurred while updating scheduler status";
  //     console.error(
  //       "An error occurred while updating scheduler status:",
  //       error
  //     );
  //     setSnackbarMessage(errorMessage);
  //     setSnackbarSeverity("error");
  //     setSnackbarOpen(true);
  //   }
  // };
  // const handleStatusChange = async (selectedOption) => {
  //   if (!selectedOption) return;

  //   const newStatus = selectedOption.value;
  //   setStatus(newStatus);

  //   // If the new status is not "Completed", make an API call immediately
  //   if (newStatus !== "Completed") {
  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.put(
  //         `${API_URL}/api/update_scheduler_status/${scheduler_id}`,
  //         {
  //           status: newStatus,
  //         },
  //         {
  //           headers: {
  //             Authorization: authKey,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         const { message } = response.data;
  //         setSnackbarMessage(message || "Scheduler status updated successfully");
  //         setSnackbarSeverity("success");
  //         setSnackbarOpen(true);
  //         fetchScheduler(); // Refresh the scheduler data
  //       } else {
  //         const { message } = response.data;
  //         setSnackbarMessage(message || "Failed to update scheduler status");
  //         setSnackbarSeverity("error");
  //         setSnackbarOpen(true);
  //       }
  //     } catch (error) {
  //       const errorMessage =
  //         error.response?.data?.message ||
  //         "An error occurred while updating scheduler status";
  //       console.error("An error occurred while updating scheduler status:", error);
  //       setSnackbarMessage(errorMessage);
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //     }
  //   }
  // };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
    // Reset completedDate and dateError if status is not "Completed"
    if (selectedOption.value !== 'Completed') {
      setCompletedDate(null);
      setDateError('');
    }
  };

  const handleOtherInfoChange = (e) => {
    const { name, value } = e.target;
    setOtherInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleOtherInfoSubmit = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/update_other_information/${scheduler_id}`,
        otherInfo,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Other information submitted successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setIsOtherInfoPopupOpen(false);
        fetchScheduler();
      } else {
        console.error(
          "Failed to submit other information:",
          response.data.message
        );
        setSnackbarMessage("Failed to submit other information");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while submitting other information:",
        error
      );
      setSnackbarMessage(
        "An error occurred while submitting other information"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleEditInfoSubmit = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/update_other_information/${scheduler_id}`,
        editInfo,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("Other information updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setIsEditPopupOpen(false);
        fetchScheduler();
      } else {
        console.error(
          "Failed to update other information:",
          response.data.message
        );
        setSnackbarMessage("Failed to update other information");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(
        "An error occurred while updating other information:",
        error
      );
      setSnackbarMessage("An error occurred while updating other information");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleUploadSubmit = async () => {
    if (attachmentFiles.length === 0) return;

    const authKey = localStorage.getItem("authKey");
    const formData = new FormData();
    formData.append("schedule_id", scheduler_id);

    // Append all files to FormData
    attachmentFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    try {
      const response = await axios.post(
        `${API_URL}/api/upload_schedule_datasheet/${scheduler_id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAttachmentFiles([]);
        setSelectedFiles([]);
        fetchScheduler();
      } else {
        setSnackbarMessage("Failed to upload file");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading file");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                Scheduler View
              </div>
            </div>
          </div>
          <div className="action-purchase-header">
            <div className="action-purchase-header-content">
              {schedulesData.map((equipment, index) => (
                <div key={index}>
                  <div className="project-detailss">
                    <div className="project-detail-arround">
                      <div className="project-details-left-side">
                        <div className="project-detail">
                          <div className="detail-header">Contractor Name:</div>
                          <div className="detail-part">
                            {equipment.contractor_details.contractor_name}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Equipment tag no:</div>
                          <div className="detail-part">
                            {equipment.equipment_tag_no}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Equipment Location:</div>
                          <div className="detail-part">
                            {equipment.equipment_location || "N/A"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Status:</div>
                          <div className="detail-part"> {capitalizeFirstLetter(equipment.status)}</div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">
                            Service Date:
                          </div>
                          <div className="detail-part">
                            {formatDate(equipment.last_serviced_date || "N/A")}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Service Due Date:</div>
                          <div className="detail-part">
                            {formatDate(equipment.service_due_date || "N/A")}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Product Description:</div>
                          <div className="detail-part">
                            {equipment.description || "N/A"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Frequency:</div>
                          <div className="detail-part">
                            {equipment.frequency ? `Every ${equipment.frequency} days` : "N/A"}
                          </div>
                        </div>

                        <div className="project-detail">
                          <div className="detail-header">OISD Point:</div>
                          <div className="detail-part">
                            {equipment.oisd_point || "N/A"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Frequency Description:</div>
                          <div className="detail-part">
                            {equipment.frequency_description || "N/A"}
                          </div>
                        </div>
                        <div className="project-detail">
                          <div className="detail-header">Uploaded File:</div>
                          <div className="detail-part">
                            {equipment.attachments && equipment.attachments.length > 0 ? (
                              equipment.attachments.map((file, fileIndex) => (
                                <div key={fileIndex}>
                                  <span>{file.filename} ({file.file_size} KB)</span>
                                </div>
                              ))
                            ) : (
                              "No files uploaded"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="invoice-body-container">
            <div className="invoice-body-container-content-schedule">
              <div className="invoice-upload-files-schedules">
                {schedulesData.map((equipment, index) => (
                  <div className="current-status" key={index}>
                    <label className="upload-label" htmlFor="docket-file">
                      Current Status:{" "}
                      <span
                        className={
                          equipment.status === "Completed"
                            ? "status-completed"
                            : equipment.status === "active"
                              ? "status-active"
                              : equipment.status === "overdue"
                                ? "status-overdue"
                                : equipment.status === "not in tenure"
                                  ? "status-not-in-tenure"
                                  : equipment.status === "Cancelled"
                                    ? "status-cancelled"
                                    : equipment.status === "In Progress"
                                      ? "status-progress"
                                      : equipment.status === "Hold"
                                        ? "status-hold"
                                        : equipment.status === "not in tenure"
                                          ? "status-schedule"
                                          : ""
                        }
                      >
                        {capitalizeFirstLetter(equipment.status)}
                      </span>
                    </label>
                  </div>
                ))}

                <div className="status-content">
                  <div className="form-group-select">
                    <label htmlFor="docket-file">Change Status:</label>
                    <Select
                      options={statusOptions}
                      value={statusOptions.find((option) => option.value === status)}
                      onChange={handleStatusChange}
                      placeholder="Select status"
                    />
                  </div>
                  {status === "Completed" && (
                    <>
                      <div className="form-group">
                        <label>Service Date:</label>
                        <div className="input-group-datepicker">
                          <DatePicker
                            selected={completedDate}
                            onChange={(date) => setCompletedDate(date)}
                            placeholderText="Select Service date"
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            maxDate={today}
                          />
                        </div>
                        {dateError && <div className="error-message">{dateError}</div>}
                      </div>
                    </>
                  )}
                  {status === "Completed" && (
                    <div className="form-group">
                      <label className="upload-label" htmlFor="docket-file">
                        Upload Maintenance Report:
                      </label>
                      {schedulesData.map((equipment, index) => (
                        <div key={index}>
                          <div className="">
                            {equipment.attachments?.map((file) => (
                              <div key={file.file_id} className="file-name-lists">
                                <span> File Name:{" "}</span>
                                <span
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={() => handlePreviewClick(file.preview_url)}
                                >
                                  {file.filename} ({file.file_size} KB)
                                </span>
                                <a
                                  href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                  download
                                // className="download-attachment"
                                >
                                  <AiOutlineDownload className="download-icon" />
                                </a>
                              </div>
                            ))}
                          </div>
                          <div className="chalann-input">
                            <div className="input-group-attachments">
                              <button
                                type="button"
                                className="challan-btn"
                                onClick={handleUploadButtonClick}
                              >
                                <FiUpload /> Upload File
                              </button>
                              <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <Modal
                        open={!!previewUrl}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                      >
                        <Box sx={modalStyle}>
                          {previewUrl && (
                            <embed
                              src={previewUrl}
                              width="100%"
                              height="100%"
                              type="application/pdf"
                            />
                          )}
                        </Box>
                      </Modal>
                    </div>
                  )}


                </div>

                <div className="schedules-button">
                  <button className="data-form-save-button" onClick={handleSubmit}>
                    Save
                  </button>
                  <button className="data-form-cancel-button" onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className="reset-button" onClick={handleDefaultStatus}>
                    Reset Status to Default
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PreventiveSchedulesView;