import React, { useState, useEffect, useRef } from "react";
import "./ArchivedUser.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import Snackbar from '@mui/material/Snackbar'; // Import Snackbar
import MuiAlert from '@mui/material/Alert';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import API_URL from "../../src/Config";

function ArchivedEquipementType({ isSidebarExpanded }) {
    const [users, setUsers] = useState([]);
    const [equipmentSubTypeVariants, setEquipmentSubTypeVariants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeletePopup, setIsDeletePopup] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [isSortPopup, setIsSortPopup] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showActionHeader, setShowActionHeader] = useState(false);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const navigate = useNavigate();
    const [isAscending, setIsAscending] = useState(true);
    const [equipments, setEquipments] = useState([]);
    const [sortOrders, setSortOrders] = useState({
        Name: true,
        Designation: true,
        Department: true,
    });
    useEffect(() => {
        fetchData();
    }, []);

    const containerRef = useRef(null);

    useEffect(() => {
        fetchData();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsDeletePopup(false);
            setIsDeletePopupOpen(false);
            setIsSortPopup(false);
            setSelectedRowIndex(null);
        }
    };

    const handleButtonClick = (index) => {
        setSelectedRowIndex(index === selectedRowIndex ? null : index);
    };



    const handleDeleteButtonClick = () => {
        setIsDeletePopupOpen(!isDeletePopupOpen);
    };

    const fetchData = async () => {
        try {
            const authKey = localStorage.getItem("authKey");

            const response = await axios.get(`${API_URL}/api/get_archived_sub_type_variants`, {
                headers: {
                    Authorization: authKey,
                },
            });

            setEquipmentSubTypeVariants(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching user data:", error.message);
            setLoading(false);
        }
    };

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
        setChecked(newSelectAll);
    };

    const handleSelectRow = (userID) => {
        const updatedSelectedRows = selectedRows.includes(userID)
            ? selectedRows.filter((id) => id !== userID)
            : [...selectedRows, userID];

        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === users.length);
        setChecked(updatedSelectedRows.length > 0);
    };

    const handleRestoreUser = async () => {
        try {
            const authKey = localStorage.getItem("authKey");
            const apiUrl = `${API_URL}/api/restore_archived_users`;

            const response = await fetch(apiUrl, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authKey,
                },
                body: JSON.stringify({ ids: selectedRows }),
            });

            if (response.ok) {

                const updatedUsers = users.filter(
                    (user) => !selectedRows.includes(user.ID)
                );
                setUsers(updatedUsers);
                showSnackbar('User restored successfully');
                setSelectedRows([]);
                setSelectAll(false);
                setChecked(false);
            } else {
                console.error("Failed to delete users");
            }
        } catch (error) {
            console.error("An error occurred while deleting users:", error);
        }
    };


    const handleCloseButtonClick = () => {
        setChecked(false);
        setSelectedRows([]);
        setShowActionHeader(false);
    };

    const handleSortpopup = () => {
        setIsSortPopup(!isSortPopup);
    };

    const handleSort = (column) => {
        setSortOrders(prevSortOrders => ({
            ...prevSortOrders,
            [column]: !prevSortOrders[column]
        }));
        setIsSortPopup(false);
        if (column === sortColumn) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

   
    const handleBackCompany = () => {
        navigate('/equipment-sub-type-variant');

    };
    const handleSortOrderToggle = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleRefresh = () => {
        fetchData();
    };
    return (
        <>
            <div
                className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div className="data-list-content" ref={containerRef}>
                    {isChecked ? (
                        <div className="action-header">
                            <div className="action-header-content">
                                <div className="action-header-content-leftside">
                                    <div className="action-button">
                                        <button className="print">
                                            <IoPrintOutline className="print-icon" />
                                        </button>
                                        <button className="bulk-update">Bulk Update</button>
                                        <button
                                            className="delete"
                                            onClick={handleDeleteButtonClick}
                                        >
                                            <HiOutlineDotsHorizontal className="delete-icon" />
                                        </button>
                                        {isDeletePopupOpen && (
                                            <div className="delete-popup">
                                                <div className="delete-popup-content">
                                                    <button onClick={handleRestoreUser}>Unarchive</button>
                                                </div>
                                            </div>
                                        )}



                                    </div>
                                </div>
                                <div className="action-header-content-rightside">
                                    <button
                                        className="action-header-close-button"
                                        onClick={handleCloseButtonClick}
                                    >
                                        <IoCloseOutline className="action-header-close-icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="data-list-header">
                            <div className="data-list-header-content">
                                <div className="data-list-heading-content-left-side">
                                    <Tooltip title="Back" arrow>      <IoMdArrowBack className="back-company" onClick={handleBackCompany} /> </Tooltip>Archived Equipment Sub Type Variant
                                </div>
                                <div className="data-list-heading-content-right-side">
                                    <Tooltip title="Refresh List" arrow>
                                        <button className="refresh-button" onClick={handleRefresh}>
                                            <TbRefresh className="refresh-icon" />
                                        </button>
                                    </Tooltip>
                                    <button className="sort-button" onClick={handleSortpopup}>
                                        <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                                    </button>
                                    {isSortPopup && (
                                        <div className="sort-popup-container">
                                            <div className="sort-popup-content">
                                                <div className="sort-by" onClick={handleSortOrderToggle}>SORT BY{isAscending ? '(Ascending)' : '(Descending)'}</div>
                                                <div className="sort-by-button">
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="data-list-body">
                        <div className="data-list-scroll-body">
                            <div className="data-list-table">
                                {loading ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                        <div>Loading...</div>
                                    </div>
                                ) : equipmentSubTypeVariants.length > 0 ? (
                                    <table className="table multilevel-data">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                    />
                                                </th>
                                                <th>SR. NO.</th>
                          <th>EQUIPMENT SUB TYPE</th>
                          <th>EQUIPMENT SUB TYPE VARIANT</th>
                          <th>APPLICABLE OISD</th>
                          <th>SUBSECTION</th>
                          <th>FREQUENCY</th>
                                            </tr>
                                        </thead>
                                        <tbody className="data-list-scroll-bodys">
                                        
                                        {equipmentSubTypeVariants.map((equipmentSubTypeVariant, index) => (
                          <tr key={equipmentSubTypeVariant.ID} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(equipmentSubTypeVariant.id)}
                                onChange={() => handleSelectRow(equipmentSubTypeVariant.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{equipmentSubTypeVariant.EquipmentSubType || "NA"}</td>
                            <td>{equipmentSubTypeVariant.VariantName || "NA"}</td>
                           
                            <td>{equipmentSubTypeVariant.ApplicableOISD || "NA"}</td>
                            <td>{equipmentSubTypeVariant.Subsection || "NA"}</td>
                            <td>{equipmentSubTypeVariant.frequency || "NA"}</td>
                          


                          </tr>

                        ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="no-users-container">
                                        <div className="no-users-message">No archived sub type variant found</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                style={{ marginTop: '35px', marginLeft: '20px' }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default ArchivedEquipementType;
