import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";

function EditBreakdown({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [breakdowntData, setBreakdowntData] = useState({
    department_name: "",
    problem_desc: "",
    tag_no: "",
    
  });
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch Breakdown Data
  useEffect(() => {
    const fetchBreakdownData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_breakdown_schedule/${ID}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;

        setBreakdowntData({
          department_name: responseData.department_name || "",
          problem_desc: responseData.problem_desc || "",
          tag_no: responseData.tag_no || "",
        });
        setSelectedOption({
          value: responseData.tag_no,
          label: responseData.tag_no,
        });
        setSelectedDepartment({
          value: responseData.department_id,
          label: responseData.department_name,
        });
      } catch (error) {
        console.error("Error fetching breakdown data:", error.message);
        setError("An error occurred while fetching breakdown data");
      }
    };
    fetchBreakdownData();
  }, [ID]);

  // Fetch Departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_departments`, {
          headers: {
            Authorization: authKey,
          },
        });
        const departmentsData = response.data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(departmentsData);
      } catch (error) {
        console.error("An error occurred while fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Fetch Tags
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_equipment`, {
          headers: {
            Authorization: authKey,
          },
        });
        const tagsData = response.data.Items.map((item) => ({
          value: item.TagNo,
          label: item.TagNo,
        }));
        setOptions(tagsData);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchTags();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBreakdowntData({ ...breakdowntData, [name]: value });
  };

  const handleFileChange = (e) => {
    setBreakdowntData({ ...breakdowntData, attachment: e.target.files[0] });
  };

  const handleSaveClick = async () => {
    const errors = {};
    let error = false;

    if (!error) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");

        const formData = new FormData();
        formData.append("department_name", selectedDepartment ? selectedDepartment.label : "");
        formData.append("tag_no", selectedOption ? selectedOption.value : "");
        formData.append("problem_desc", breakdowntData.problem_desc);
        

        const response = await axios.put(
          `${API_URL}/api/update_breakdown_schedule/${ID}`,
          formData,
          {
            headers: {
              Authorization: authKey,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setSeverity("success");
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/breakdown-schedules");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating breakdown data:", error.message);
        setError("An error occurred while updating breakdown data");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackProject = () => {
    navigate("/breakdown-schedules");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCancelClick = () => {
    navigate("/breakdown-schedules");
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Breakdown
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={setSelectedDepartment}
                    placeholder="Select Department"
                    isSearchable
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="tag_no">
                    Tag Selection<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="tag_no"
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    placeholder="Select a tag"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="problem_desc">
                    Problem Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                  <textarea
                    name="problem_desc"
                    rows={4}

                    value={breakdowntData.problem_desc}
                    onChange={handleInputChange}
                    placeholder="Description"
                  />
                   </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachment">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="attachment"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditBreakdown;
