import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CiExport } from "react-icons/ci";
import { TfiExport } from "react-icons/tfi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import TextField from "@mui/material/TextField";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiAlertTriangle } from "react-icons/fi";

function Expence({ isSidebarExpanded }) {
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [expenses, setExpenses] = useState([]);
  const [technikPersons, setTechnikPersons] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  // const [selected, setSelected] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [projects, setProjects] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [searchInput, setSearchInput] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    Person: true,
    Project: true,
  });
  const navigate = useNavigate();

  const containerRef = useRef(null);
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const userName = localStorage.getItem("userName");
  const options = [
    { value: "current", label: "Current Month" },
    { value: "previous", label: "Previous Month" },
  ];


  const filteredExpenses = expenses.filter((expense) => {
    const personNameMatch = (expense.person_name?.toLowerCase() || "").includes(
      searchInput.toLowerCase()
    );
    const projectMatch = (expense.project_name?.toLowerCase() || "").includes(
      searchInput.toLowerCase()
    );
    const expenseTypeMatch = (
      expense.expense_type?.toLowerCase() || ""
    ).includes(searchInput.toLowerCase());
    const dateMatch = (expense.date?.toLowerCase() || "").includes(
      searchInput.toLowerCase()
    );
    const valueMatch = (expense.value?.toLowerCase() || "").includes(
      searchInput.toLowerCase()
    );
    const descriptionMatch = (
      expense.description?.toLowerCase() || ""
    ).includes(searchInput.toLowerCase());
    const entryDateMatch = (expense.created_at?.toLowerCase() || "").includes(
      searchInput.toLowerCase()
    );

    return (
      personNameMatch ||
      projectMatch ||
      expenseTypeMatch ||
      dateMatch ||
      valueMatch ||
      descriptionMatch ||
      entryDateMatch
    );
  });

  const sortedexpenses = () => {
    if (sortColumn) {
      return filteredExpenses.slice().sort((a, b) => {
        const first = (a[sortColumn] || "").toUpperCase();
        const second = (b[sortColumn] || "").toUpperCase();

        if (first < second) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (first > second) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredExpenses;
  };

  const handleSort = (column) => {
    setSortOrders((prevSortOrders) => ({
      ...prevSortOrders,
      [column]: !prevSortOrders[column],
    }));
    setIsSortPopup(false);
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsDeletePopup(false);
      setIsDeletePopupOpen(false);
      setIsSortPopup(false);
      setSelectedRowIndex(null);
    }
  };

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const fetchTechnikPersons = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_user`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      console.log("Response data:", response.data);

      if (response.status === 200) {
        const users = response.data;
        const technikPersonsNames = users.map((person) => ({
          label: person.Name,
          value: person.ID,
        }));
        setTechnikPersons(technikPersonsNames);
      } else {
        console.error("Failed to fetch sales persons");
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    fetchTechnikPersons();
  }, []);

  const fetchTotalExpenseByPerson = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const personName = localStorage.getItem("userName");
      const apiUrl = `${API_URL}/api/count_expenses_by_person/${personName}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        console.log(response.data);
      } else {
        console.error("Failed to fetch total expenses by person");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching total expenses by person:",
        error
      );
    }
  };

  useEffect(() => {
    fetchTotalExpenseByPerson();
  }, []);

  const fetchTotalExpenseByMonth = async (selectedMonth) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const personName = localStorage.getItem("userName");
      const apiUrl = `${API_URL}/api/count_expenses_by_person_and_month/${personName}/${selectedMonth}`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        console.log(response.data); // Log or use the data as needed
      } else {
        console.error("Failed to fetch total expenses by person and month");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching total expenses by person and month:",
        error
      );
    }
  };

  useEffect(() => {
    fetchTotalExpenseByMonth();
  }, []);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
    setSelectedRowIndex(false);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_expenses`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        setExpenses(response.data);
      } else {
        console.error("Failed to fetch expenses");
      }
      setLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching expenses:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? expenses.map((expense) => expense.id) : []);
    setChecked(newSelectAll);
    setSelectedRowIndex(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/delete_expenses`;
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data delete successfully");
        const updatedExpenses = expenses.filter(
          (expense) => !selectedRows.includes(expense.id)
        );
        setExpenses(updatedExpenses);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete users");
      }
    } catch (error) {
      console.error("An error occurred while deleting users:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
    setSelectedRowIndex(false);
  };

  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleRefresh = () => {
    fetchExpenses();
  };
  const handleSelectRow = (expenseID) => {
    const updatedSelectedRows = selectedRows.includes(expenseID)
      ? selectedRows.filter((id) => id !== expenseID)
      : [...selectedRows, expenseID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === expenses.length);
    setChecked(updatedSelectedRows.length > 0);
    setSelectedRowIndex(false);
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
    setIsDeletePopupOpen(false);
  };

  const handlePersonChange = async (selectedOption) => {
    setSelectedOption(selectedOption);
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_expenses_by_name`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
        params: {
          person_name: selectedOption.label,
        },
      });

      if (response.status === 200) {
        setExpenses(response.data);
      } else {
        console.error("Failed to fetch expenses by person name");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching expenses by person name:",
        error
      );
    }
  };

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_projects`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
      });

      if (response.status === 200) {
        setProjects(response.data);
      } else {
        console.error("Failed to fetch projects");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching projects:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchExpensesByProject = async (selectedProject) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_expenses_by_project`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
        params: {
          project_id: selectedProject.value,
        },
      });

      if (response.status === 200) {
        setExpenses(response.data);
      } else {
        console.error("Failed to fetch expenses by project name");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while fetching expenses by project name:",
        error
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      fetchExpensesWithinDateRange(selectedStartDate, selectedEndDate);
    }
  }, [selectedStartDate, selectedEndDate]);

  const fetchExpensesWithinDateRange = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_expenses_by_date_range`;

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
        },
        params: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        },
      });

      if (response.status === 200) {
        setExpenses(response.data);
      } else {
        console.error("Failed to fetch expenses within date range");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while fetching expenses within date range:",
        error
      );
      setIsLoading(false);
    }
  };

 


  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="print">
                      <IoPrintOutline className="print-icon" />
                    </button>
                    <button className="bulk-update">Bulk Update</button>
                    <button
                      className="delete"
                      onClick={handleDeleteButtonClick}
                    >
                      <HiOutlineDotsHorizontal className="delete-icon" />
                    </button>
                    {isDeletePopupOpen && (
                      <div className="delete-popup">
                        <div className="delete-popup-content">
                          <button onClick={handleDeletePopup}>Delete</button>
                        </div>
                      </div>
                    )}
                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content w-30">
                              <div className="confirm-model-content-body">
                                <p className="archive-icon">
                                  <FiAlertTriangle />
                                  Are you sure want to delete permanently?
                                </p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  onClick={handleConfirmDelete}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side new-ute">
                  All Expenses
                </div>
                <div className="data-list-heading-content-right-side">
          
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>
                  <div >

                  </div>
                  <Link to="/expensesform" className="data-list-new">
                    <Tooltip title="New Expens" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" /> New
                      </button>
                    </Tooltip>
                  </Link>

                  <Tooltip title="Refresh List" arrow>
                    <button className="refresh-button" onClick={handleRefresh}>
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button className="sort-button" onClick={handleSortpopup}>
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div className="sort-popup-container">
                      <div className="sort-popup-content">
                        <div
                          className="sort-by"
                          onClick={handleSortOrderToggle}
                        >
                          SORT BY {isAscending ? "(Ascending)" : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button onClick={() => handleSort("person_name")}>
                            Person{" "}
                            {sortOrders.person_name ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button onClick={() => handleSort("project_name")}>
                            Project{" "}
                            {sortOrders.project_name ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-body">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : expenses.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th>SR. NO.</th>
                        <th>PERSON NAME</th>
                        <th>PROJECT</th>
                        <th>EXPENSE TYPE</th>
                        <th>DATE</th>
                        <th>VALUE</th>
                        <th>DESCRIPTION</th>
                        <th>ENTRY DATE</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {sortedexpenses().map((expense, index) => (
                        // {expenses.map((expense, index) => (
                        <tr key={expense.id} className="hoverable-row">
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(expense.id)}
                              onChange={() => handleSelectRow(expense.id)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{expense.person_name}</td>
                          <td>{expense.project_name}</td>

                          <td className="project-types">
                         
                          </td>

                          <td>{formatDate(expense.date)}</td>
                          <td>{expense.value}</td>
                          <td>{expense.description}</td>
                          <td>{expense.created_at}</td>
                          <td className="edit-pop">
                            <button
                              className="edit-data-button"
                              onClick={() => handleButtonClick(index)}
                            >
                              <div className="edit-button">
                                <MdExpandMore />
                              </div>
                            </button>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-expense-detail/${expense.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>

                          {/* <td className="edit-pop">
                            <button className="edit-data-button" onClick={() => handleButtonClick(index)}>
                              <div className="edit-button">
                                {selectedRowIndex === index ? <MdExpandLess /> : <MdExpandMore />}
                              </div>
                            </button>
                          </td>
                          {selectedRowIndex === index && (
                            <div className="edit-popup">
                              <div className="edit-popup-contant">
                                <div className="edit-popup-button">
                                  <Link to={`/edit-expense-detail/${expense.id}`} className="inquiry-new">
                                    <button className="edit-button-inquiry">
                                      <MdOutlineModeEditOutline /> Edit
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No Expenses Found</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Expence;
