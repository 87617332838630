import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { CiCirclePlus } from "react-icons/ci";

const { Control, Menu } = components;

function EditSubDepartment({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [subDepartmentData, setSubDepartmentData] = useState({
    DepartmentName: "",
    DepartmentId: "",
    SubDepartment: "",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSubDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    navigate("/sub-department");
  };

  const handleSaveClick = async () => {

    const errors = {};
    let error = false;
    Object.keys(subDepartmentData).forEach( (key) => {
      if (!subDepartmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning")
        showSnackbar("Required fields are empty");
        error = true;
      } 
    });

    if(error === false) {
    const updatedSubDepartmentData = {
      ...subDepartmentData,
    };

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.put(
        `${API_URL}/api/update_sub_department/${ID}`,
        updatedSubDepartmentData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success")
        showSnackbar("Sub Department updated successfully");
        setTimeout(() => {
          navigate("/sub-department");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving user:", error.message);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const CustomControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );

  const CustomMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewDepartment}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add New Department
      </button>
    </Menu>
  );

  const handleAddNewDepartment = () => {
    // Add logic for adding a new department
  };

  const handleDepartmentChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedDepartment(selectedOption);
      setSubDepartmentData((prevFormData) => ({
        ...prevFormData,
        DepartmentName: selectedOption.label,
        DepartmentId: selectedOption.value,
      }));
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_departments`;

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          const options = data.map((department) => ({
            label: department.DepartmentName,
            value: department.department_id,
          }));
          setDepartments(options);
        } else {
          console.error("Failed to fetch departments");
        }
      } catch (error) {
        console.error("An error occurred while fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchSubDepartment = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_sub_department_by_id/${ID}`;

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: authKey,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          setSubDepartmentData({
            DepartmentName: data[0].DepartmentName,
            DepartmentId: data[0].department_id,
            SubDepartment: data[0].Name,
          });
          setSelectedDepartment({
            label: data[0].DepartmentName,
            value: data[0].department_id,
          });
        } else {
          console.error("Failed to fetch sub-department");
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching sub-department:",
          error
        );
      }
    };

    fetchSubDepartment();
  }, [ID]);

  const handleBackProject = () => {
    navigate("/sub-department");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Sub-Department
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="DepartmentName">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="departmentName"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select Department"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="SubDepartment">
                    Sub Department<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="SubDepartment"
                      value={subDepartmentData.SubDepartment}
                      onChange={handleInputChange}
                      placeholder="Enter Sub Department"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditSubDepartment;
