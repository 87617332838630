import React, { useState, useEffect } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";

function DepartmentForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const { id } = useParams();
  const [positionOptions, setPositionOptions] = useState([]);
  const [manPowerData, setManPowerData] = useState({
    project_id: id || "",
    person_name: "",
    position: "",
    contact_no: "",
    email: "",
    status: "",
    // qualification: "",
    // passing_year: "",
    joining_expected_date: "",
  });

  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setManPowerData((prevState) => ({
      ...prevState,
      status: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevState) => ({
      ...prevState,
      status: "", // Clear the error message for this field
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManPowerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        joining_expected_date: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setManPowerData((prevState) => ({
        ...prevState,
        passing_year: year,
      }));
    }
  };

  const handleCancelClick = () => {
    navigate(`/actualmanpower/${id}`);
  };

  const statusOptions = [
    // { value: "Did not join", label: "Did not join" },
    { value: "Present at site", label: "Present at site" },
    // { value: "Resigned", label: "Resigned" },
    {
      value: "Appointment issued (yet to join)",
      label: "Appointment issued (yet to join)",
    },
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!manPowerData.person_name)
      newErrors.person_name = "Person name is required";
    if (!manPowerData.contact_no)
      newErrors.contact_no = "Contact number is required";
    if (!manPowerData.status) newErrors.status = "Current status is required";
    if (!manPowerData.qualification)
      newErrors.qualification = "Qualification is required";
    if (!manPowerData.passing_year)
      newErrors.passing_year = "Year of passing is required";
    if (!manPowerData.joining_expected_date)
      newErrors.joining_expected_date = "Expected date of joining is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = async () => {
    // if (!validateForm()) return;

    // Include all fields in the payload

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const payload = {
        project_id: selectedPosition.project_id, // Ensure project_id is included
        person_name: manPowerData.person_name || "",
        contact_no: manPowerData.contact_no || "",
        email: manPowerData.email || "",
        status: manPowerData.status || "",
        joining_expected_date: manPowerData.joining_expected_date || "",
        man_power_id: selectedPosition ? selectedPosition.value : null, // Add position ID if selected
      };

      const response = await axios.post(
        `${API_URL}/api/add_actual_manpower`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Actual manpower added successfully");
        setTimeout(() => {
          navigate(`/actualmanpower/${id}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving actual manpower:", error.message);
      setSeverity("error");
      showSnackbar("Failed to save actual manpower");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const fetchPositionData = async (currentPage, itemsPerPage) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/manpower_requirements/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setManPowerData(response.data.Items);

      // Extract positions and format them for react-select
      const options = response.data.Items.map((item) => ({
      value: item.id,
      label: item.position, // Use position as the label
      project_id: item.project_id, // Include other properties as needed
    }));

      setPositionOptions(options);
    } catch (error) {
      console.error("Error fetching position data:", error.message);
    }
  };

  useEffect(() => {
    fetchPositionData();
  }, []);

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Actual Manpower
              </div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="person_name">
                    Person Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="person_name"
                      value={manPowerData.person_name}
                      onChange={handleInputChange}
                      placeholder="Enter person name"
                    />
                    {errors.person_name && (
                      <div className="error-message">{errors.person_name}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={manPowerData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact number"
                    />
                    {errors.contact_no && (
                      <div className="error-message">{errors.contact_no}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      value={manPowerData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                </div>
                <div className="form-group-select">
                  <label htmlFor="DepartmentCode">
                    Position<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="EquipmentSubType"
                    options={positionOptions}
                    placeholder="Select position"
                    isSearchable
                    onChange={setSelectedPosition}
                    value={selectedPosition}
                  />
                </div>

                <div className="form-group-select">
                  <label htmlFor="status">
                    Current Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="currentStatus"
                    value={selectedStatus}
                    onChange={handleChange}
                    options={statusOptions}
                    placeholder="Select status..."
                    isClearable
                  />
                  {errors.status && (
                    <div className="error-message">{errors.status}</div>
                  )}
                </div>
             
                <div className="form-group">
                  <label htmlFor="joining_expected_date">
                    Expected Date of Joining{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="joining_expected_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="datesss"
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setSelectedDate(null);
                        }
                      }}
                    />
                    {errors.joining_expected_date && (
                      <div className="error-message">
                        {errors.joining_expected_date}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default DepartmentForm;
