import React, { useState } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

function ContractorCompanyForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [contractorCompanyData, setContractorCompanyData] = useState({
    contractor_name: "",
    address: "",
    start_date: "",
    end_date: "",
    locations: [
      {
        location: "",
        contact_person_name: "",
        location_contact_no: "",
        location_email: "",
        location_address: "",
      },
    ],
  });

  const handleInputChange = (event, index = null, field = null) => {
    const { name, value } = event.target;
    if (index !== null) {
      const newLocations = [...contractorCompanyData.locations];
      if (field) {
        newLocations[index][field] = value;
      } else {
        newLocations[index] = value;
      }
      setContractorCompanyData((prevState) => ({
        ...prevState,
        locations: newLocations,
      }));
    } else {
      setContractorCompanyData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleAddLocation = () => {
    setContractorCompanyData((prevState) => ({
      ...prevState,
      locations: [
        ...prevState.locations,
        {
          location: "",
          contact_person_name: "",
          location_contact_no: "",
          location_email: "",
          location_address: "",
        },
      ],
    }));
  };

  const handleRemoveLocation = (index) => {
    const newLocations = contractorCompanyData.locations.filter(
      (_, i) => i !== index
    );
    setContractorCompanyData((prevState) => ({
      ...prevState,
      locations: newLocations,
    }));
  };

  const handleCancelClick = () => {
    navigate("/contractor-company");
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     // Filter out empty locations
  //     const filteredLocations = contractorCompanyData.locations.filter(
  //       (location) => {
  //         return (
  //           location.location.trim() !== "" ||
  //           location.contact_person_name.trim() !== "" ||
  //           location.location_contact_no.trim() !== "" ||
  //           location.location_email.trim() !== "" ||
  //           location.location_address.trim() !== ""
  //         );
  //       }
  //     );

  //     const payload = {
  //       ...contractorCompanyData,
  //       locations: filteredLocations,
  //     };

  //     const response = await axios.post(
  //       `${API_URL}/api/add_contractor_company`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("Contractor company added successfully");
  //       setTimeout(() => {
  //         navigate("/contractor-company");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving contractor company:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Error saving contractor company");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    // Validate required fields
    const { start_date, end_date, contractor_name } = contractorCompanyData;
    if (!start_date || !end_date || !contractor_name) {
      setSeverity("error");
      showSnackbar("Company name, Start date and End date are required");
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      // Filter out empty locations
      const filteredLocations = contractorCompanyData.locations.filter(
        (location) => {
          return (
            location.location.trim() !== "" ||
            location.contact_person_name.trim() !== "" ||
            location.location_contact_no.trim() !== "" ||
            location.location_email.trim() !== "" ||
            location.location_address.trim() !== ""
          );
        }
      );
  
      const payload = {
        ...contractorCompanyData,
        locations: filteredLocations,
        start_date: format(new Date(selectedStartDate), "yyyy-MM-dd"),
        end_date: format(new Date(selectedEndDate), "yyyy-MM-dd"),
      };
  
      const response = await axios.post(
        `${API_URL}/api/add_contractor_company`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Contractor company added successfully");
        setTimeout(() => {
          navigate("/contractor-company");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving contractor company:", error.message);
      setSeverity("error");
      showSnackbar("Error saving contractor company");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedStartDate(date);
      setContractorCompanyData((prevFormData) => ({
        ...prevFormData,
        start_date: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedEndDate(date);
      setContractorCompanyData((prevFormData) => ({
        ...prevFormData,
        end_date: formattedDate,
      }));
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Contractor Company
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="contractor_name">
                    Company Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="contractor_name"
                      value={contractorCompanyData.contractor_name}
                      onChange={handleInputChange}
                      placeholder="Enter company name"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="StartDate">
                    Contract Start Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedStartDate}
                      onChange={handleStartDateChange}
                      name="contract_start_date"
                      id="contract_start_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a start date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="EndDate">
                    Contract End Date <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedEndDate}
                      onChange={handleEndDateChange}
                      name="contract_end_date"
                      id="contract_end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select an end date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address
                  </label>
                  <div className="input-group">
                    <textarea
                      name="address"
                      rows={4}
                      value={contractorCompanyData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Locations
                  </label>
                  <div className="new-frequency-rowss">
                    {contractorCompanyData.locations.map((location, index) => (
                      <div key={index} className="location-group-content">
                        <div className="location-group">
                          <div className="input-group frequency-row">
                            <textarea
                              className="point-fr"
                              name={`Location_${index}`}
                              value={location.location}
                              onChange={(e) =>
                                handleInputChange(e, index, "location")
                              }
                              placeholder={`Enter location ${index + 1}`}
                              cols="30"
                              rows="2"
                            ></textarea>
                          </div>
                          <div className="flex-group">
                            <div>
                          <div className="input-group frequency-row">
                            <input
                              type="text"
                              className="point-fr"
                              name={`ContactPersonName_${index}`}
                              value={location.contact_person_name}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  "contact_person_name"
                                )
                              }
                              placeholder={`Enter contact person name`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="number"
                              className="point-fr"
                              name={`LocationContactNo_${index}`}
                              value={location.location_contact_no}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  "location_contact_no"
                                )
                              }
                              placeholder={`Enter location contact no`}
                            />
                          </div>
                          <div className="input-group frequency-row">
                            <input
                              type="email"
                              className="point-fr"
                              name={`LocationEmail_${index}`}
                              value={location.location_email}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_email")
                              }
                              placeholder={`Enter location email`}
                            />
                          </div>
                          </div>
                          <div>
                          <div className="input-group frequency-row">
                            <textarea
                              className="point-fr"
                              name={`LocationAddress_${index}`}
                              value={location.location_address}
                              onChange={(e) =>
                                handleInputChange(e, index, "location_address")
                              }
                              placeholder={`Enter location address`}
                             cols="30"
                                  rows="6.9"
                            ></textarea>
                          </div>
                          </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="add-close-button"
                            onClick={() => handleRemoveLocation(index)}
                            disabled={
                              contractorCompanyData.locations.length === 1
                            }
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="add-new-frequency-customer">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddLocation}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContractorCompanyForm;
