import React, { useState } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from "../../src/Config";
import "./AddDepartmentPopup.css";

const AddDepartmentPopup = ({ closePopup, handleNewDepartment }) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState({
    DepartmentName: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError(''); // Clear error message when user starts typing
  };

  const handleSaveClick = async () => {
    // Validate department name
    if (departmentData.DepartmentName.trim() === '') {
      setError('Department name is required.');
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem('authKey');

      const response = await axios.post(
        `${API_URL}/api/add_department`,
        departmentData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.status === 200) {
        const newDepartment = {
          label: departmentData.DepartmentName,
          value: response.data.id,
        };
        handleNewDepartment(newDepartment);
        closePopup();
      }
    } catch (error) {
      console.error('Error saving department:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <div className="inquiry-customerperson-popup-content">
      <div className="inquiry-customerperson-modal">
        <div className="inquiry-customerperson-modal-content">
          <div className='customerpersonpopupform-header'>
            <h4 className='customerpersonpopupform-title'>New Department</h4>
            <span className='customerpersonpopupform-btn' onClick={closePopup}><IoCloseOutline /></span>
          </div>
          <div className="customerpersonpopupform-body">
            <form>
              <div className="customerpersonpopup-form">
                <div className="form-group">
                  <label htmlFor="DepartmentName">Name<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="DepartmentName"
                      value={departmentData.DepartmentName}
                      onChange={handleInputChange}
                      placeholder="Enter department"
                    />
                  </div>
                  {error && <span className="error-message">{error}</span>}
                </div>
              </div>
            </form>
          </div>
          <div className="customerpersonpopupform-footer">
            <div className="button-group">
              <button className="customerpersonpopupform-save-button" onClick={handleSaveClick} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
              <button className="customerpersonpopupform-cancel-button" onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDepartmentPopup;
