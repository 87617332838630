import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select, { components } from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import { GiGearHammer } from "react-icons/gi";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { PiArrowElbowDownRightFill } from "react-icons/pi";
import { TbPointFilled } from "react-icons/tb";
const { Control } = components;


function EditNewEquipmentForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [equipmentData, setEquipmentData] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success")
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMaintanceDate, setSelectedMaintanceDate] = useState(null);
  const [frequencyData, setFrequencyData] = useState([]);
  const [selectedWarrantyDate, setselectedWarrantyDate] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [tagNoError, setTagNoError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [maintenanceDateError, setMaintenanceDateError] = useState('');
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null);
  const [model, setModel] = useState("");
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);

  const [selectedSubTypeVariant, setSelectedSubTypeVariant] = useState(null);
  const handleMaintanaceDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedMaintanceDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        LastMaintenanceDate: formattedDate,
      }));
    }
  };

  const CustomVariantControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );


  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        InstallationYear: year,
      }));
    }
  };

  const handleWarrantyDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setselectedWarrantyDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        WarrantyExp: formattedDate,
      }));
    }
  };

  useEffect(() => {
    const fetchEquipmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_equipment/${ID}`, {
          headers: {
            Authorization: authKey,
          },
        });
  
        if (response.data && response.data.length > 0) {
          const equipment = response.data[0];
          setEquipmentData((prevFormData) => ({
            ...prevFormData,
            Category: equipment.Category || "",
            SubTypeVariant: equipment.SubTypeVariant !== "undefined" ? equipment.SubTypeVariant : "",
            VariantNameId: equipment.variant_id || null,
            DepartmentId: equipment.department_id || null,
            DepartmentName: equipment.DepartmentName || "",
            EquipmentTypeId: equipment.equipment_type_id || null,
            SubDepartmentId: equipment.sub_department_id || null,
            SubDepartmentName: equipment.SubDepartmentName || "",
            EquipmentType: equipment.EquipmentType || "",
            EquipmentSubTypeId: equipment.equipment_sub_type_id || null,
            EquipmentSubType: equipment.EquipmentSubType || "",
            frequency_id: equipment.frequency_id || null,
            TagNo: equipment.TagNo || null,
            Make: equipment.Make || null,
            Model: equipment.Model || null,
            Description: equipment.Description || null,
            Location: equipment.Location || null,
            InstallationYear: equipment.InstallationYear || null,
           
          }));
          const labelParts = [
            equipment.DepartmentName,
            equipment.SubDepartmentName,
            equipment.EquipmentType,
            equipment.EquipmentSubType,
            equipment.SubTypeVariant !== "undefined" ? equipment.SubTypeVariant : "", // Replace "undefined" string with empty string
          ].filter(part => part); 
  
          setSelectedDepartment({
            label: equipment.DepartmentName,
            value: equipment.department_id,
          });
          setSelectedEquipmentTypes({
            label: equipment.EquipmentType,
            value: equipment.equipment_type_id,
          });
          setSelectedEquipmentSubType({
            label: equipment.EquipmentSubType,
            value: equipment.equipment_sub_type_id,
          });
          setSelectedSubDepartment({
            label: equipment.SubDepartment,
            value: equipment.sub_department_id,
          });
          setSelectedSubTypeVariant({
            label: equipment.SubTypeVariant !== "undefined" ? equipment.SubTypeVariant : "Select sub type variant",
            value: equipment.variant_id || null,
          });
  
          const selectedOption = {
            label: labelParts.join(' >> '), // Join parts with '>>'
            value: equipment.ID,
            ...equipment,
            frequency: equipment.Frequencies,
          };
  
          setSelectEdequipmentTypeVariants(selectedOption);
          setSelectedMaintanceDate(equipment.LastMaintenanceDate ? new Date(equipment.LastMaintenanceDate) : null);
          setselectedWarrantyDate(equipment.WarrantyExp ? new Date(equipment.WarrantyExp) : null);
          setModel(equipment.Model || "");
          setSelectedInstallationDate(equipment.InstallationYear ? new Date(`${equipment.InstallationYear}-01-01`) : null);
        } else {
          setError("No equipment data found");
        }
      } catch (error) {
        console.error("Error fetching equipment data:", error.message);
        setError("An error occurred while fetching equipment data");
      }
    };
    fetchEquipmentData();
  }, [ID]);
  
  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectEdequipmentTypeVariants(selectedOption);
  
    const {
      DepartmentName,
      SubDepartmentName,
      VariantName,
      EquipmentSubType,
      EquipmentType,
      frequency,
      id,
    } = selectedOption;

    
    let category = "";
    if (VariantName) {
      category = VariantName;
    } else if (EquipmentSubType) {
      category = EquipmentSubType;
    } else if (EquipmentType) {
      category = EquipmentType;
    } else if (SubDepartmentName) {
      category = SubDepartmentName;
    } else if (DepartmentName) {
      category = DepartmentName;
    }
  
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      Category: category,
      SubTypeVariant: selectedOption.SubTypeVariant !== "undefined" ? selectedOption.SubTypeVariant : "",
      VariantNameId: selectedOption.variant_id,
      DepartmentId: selectedOption.department_id,
      DepartmentName: selectedOption.DepartmentName,
      EquipmentTypeId: selectedOption.equipment_type_id,
      SubDepartmentId: selectedOption.sub_department_id,
      SubDepartmentName: selectedOption.SubDepartmentName,
      EquipmentType: selectedOption.EquipmentType,
      EquipmentSubTypeId: selectedOption.equipment_sub_type_id,
      EquipmentSubType: selectedOption.EquipmentSubType,
      frequency_id: id,
    }));
  
    setFrequencyData(frequency);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEquipmentData({ ...equipmentData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/new-equipment");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  const handleSaveClick = async () => {
    setTagNoError('');
    setCategoryError('');
    setMaintenanceDateError('');

    // Validate required fields
    let isValid = true;

    if (!equipmentData.TagNo) {
      setTagNoError('Tag No is required');
      isValid = false;
    }

    if (!selectedEquipmentSubTypeVariants) {
      setCategoryError('Category is required');
      isValid = false;
    }

    if (!selectedMaintanceDate) {
      setMaintenanceDateError('Last Maintenance Date is required');
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Format data with optional fields
      const formattedData = {
        ...equipmentData,
        // Convert dates to YYYY-MM-DD format or null if not provided
        WarrantyExp: selectedWarrantyDate ? format(new Date(selectedWarrantyDate), 'yyyy-MM-dd') : null,
        LastMaintenanceDate: selectedMaintanceDate ? format(new Date(selectedMaintanceDate), 'yyyy-MM-dd') : null,
        InstallationYear: selectedInstallationDate ? format(new Date(selectedInstallationDate), 'yyyy') : null,
      };

      const response = await axios.put(
        `${API_URL}/api/update_equipment/${ID}`,
        formattedData,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/new-equipment");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating equipment:", error.message);
      setError("An error occurred while updating equipment data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/new-equipment");
  };

  const fetchEquipmentSubTypeVariants = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_all_frequency_configs`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        // Directly set the data in state as it is
        setFrequencyData(data);

        const options = data.map((equipmentSubTypeVariant) => {
          const {
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            VariantName,
            id,
            department_id,
            sub_department_id,
            equipment_type_id,
            equipment_sub_type_id,
            variant_id,
            frequency,
            Frequencies, 
          } = equipmentSubTypeVariant;
          const labelParts = [
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            VariantName,
          ];
          const label = labelParts.filter((part) => part).join(" >> ");
          return {
            label,
            value: id,
            variant_id,
            id,
            frequency,
            DepartmentName,
            SubDepartmentName,
            EquipmentType,
            EquipmentSubType,
            department_id,
            VariantName,
            equipment_type_id,
            sub_department_id,
            equipment_sub_type_id,
            Frequencies, 
          };
        });
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentSubType) {
      fetchEquipmentSubTypeVariants(selectedEquipmentSubType.value);
    }
  }, [selectedEquipmentSubType]);


  const handleModelChange = (e) => {
    const { value } = e.target;
    setModel(value);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      Model: value,
    }));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Equipment
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="newperson-user-form">
                <div className="form-header-seperator">
                  <div className="form-header-seperator-content equip">
                    <div className="equipment-image equip1">
                      <div className="box">
                        <GiGearHammer />
                      </div>
                    </div>
                    <div className="equipment-image equip2">
                      <div className="form-group-top-select">
                        <label htmlFor="ContactNo">
                          Category <span className="text-danger">*</span>
                        </label>

                        <Select
                          id="EquipmentSubTypeVariant"
                          // options={[selectedEquipmentSubTypeVariants]}
                          options={equipmentSubTypeVariants}
                          value={selectedEquipmentSubTypeVariants}
                          onChange={handleTypeSubTypeVariantsChange}
                          components={{
                            Control: CustomVariantControl,

                          }}
                          placeholder="Select category "
                          isSearchable
                        />
                        {categoryError && <p className="error-message">{categoryError}</p>}
                      </div>

                      <div className="form-group-top">
                        <label htmlFor="TagNo">
                          Tag No.<span className="text-danger">*</span>
                        </label>
                        <div className="input-group-top">
                          <input
                            type="text"
                            name="TagNo"
                            value={equipmentData.TagNo || ""}
                            onChange={handleInputChange}
                            placeholder="Enter tag number"
                          />
                        </div>
                      </div>
                      {tagNoError && <p className="error-message">{tagNoError}</p>}
                    </div>
                    {selectedEquipmentSubTypeVariants && (
                      <div className="selected-category equip3">
                        <div className="equip3-box">
                          <div className="equip-category">
                            {selectedEquipmentSubTypeVariants.DepartmentName && (
                              <div className="parent">
                                {selectedEquipmentSubTypeVariants.DepartmentName}
                                <div className="equip-icon">
                                  <TbPointFilled />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.SubDepartmentName && (
                              <div
                                className={`child ${!selectedEquipmentSubTypeVariants.EquipmentType &&
                                  !selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                  !selectedEquipmentSubTypeVariants.VariantName
                                  ? "bold-text"
                                  : ""
                                  }`}
                              >
                                {selectedEquipmentSubTypeVariants.SubDepartmentName}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.EquipmentType && (
                              <div
                                className={`child ${!selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                  !selectedEquipmentSubTypeVariants.VariantName
                                  ? "bold-text"
                                  : ""
                                  }`}
                              >
                                {selectedEquipmentSubTypeVariants.EquipmentType}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.EquipmentSubType && (
                              <div
                                className={`child ${!selectedEquipmentSubTypeVariants.VariantName
                                  ? "bold-text"
                                  : ""
                                  }`}
                              >
                                {selectedEquipmentSubTypeVariants.EquipmentSubType}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.VariantName && (
                              <div className="child bold-text">
                                {selectedEquipmentSubTypeVariants.VariantName}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                          </div>

                          {selectedEquipmentSubTypeVariants && (
                            <div className="frequency-table-container">
                              <table className="frequency-table">
                                <thead>
                                  <tr>
                                    <th>OISD Point</th>
                                    <th>Frequency (In days)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedEquipmentSubTypeVariants.Frequencies && selectedEquipmentSubTypeVariants.Frequencies.length > 0 ? (
                                    selectedEquipmentSubTypeVariants.Frequencies.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.oisd_point}</td>
                                        <td>{item.frequency}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="2">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-seperator">
                  <div className="form-seperator-content">
                    Asset Information
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Make">
                    Make
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Make"
                      value={equipmentData.Make || ""}
                      onChange={handleInputChange}
                      placeholder="Enter make"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Model">
                    Model
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Model"
                      value={model}
                      onChange={handleModelChange}
                      placeholder="Enter model"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Installation">
                    Year Of Installation
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedInstallationDate}
                      onChange={handleInstallationDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      isClearable={false}
                      showYearPicker // This will display only the year picker
                      todayButton="Today"
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setSelectedInstallationDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Warranty">
                    Warranty Expiry
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedWarrantyDate}
                      onChange={handleWarrantyDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a expiry date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          setselectedWarrantyDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Maintenance_Date">
                    Last Maintenance Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedMaintanceDate}
                      onChange={handleMaintanaceDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a maintenance date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                      maxDate={new Date()} // Set the max date to today
                    />
                  </div>
                  {maintenanceDateError && <p className="error-message">{maintenanceDateError}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="Location">
                    Location
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Location"
                      value={equipmentData.Location || ""}
                      onChange={handleInputChange}
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">
                    Description
                  </label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      value={equipmentData.Description || ""}
                      onChange={handleInputChange}
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditNewEquipmentForm;
