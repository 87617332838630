import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Tooltip } from '@mui/material';
import "./Sidebar.css";
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";

function importAll(r) {
  let icons = {};
  r.keys().forEach((item, index) => { icons[item.replace('./', '')] = r(item); });
  return icons;
}

const icons = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

function Sidebar({ onSidebarToggle }) {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showSide, setShowSide] = useState(true);
  const [dropdownState, setDropdownState] = useState({});

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    setShowSide(!showSide);
  };

  useEffect(() => {
    onSidebarToggle(isExpanded);
  }, [isExpanded, onSidebarToggle]);

  // Fetch menu items and departments only once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_menu_items`, {
          headers: {
            Authorization: authKey,
          },
        });

        console.log("Menu items fetched:", response.data);
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchDepartments = async () => {
      try {
        setLoading(true);
        const authKey = localStorage.getItem("authKey");

        const response = await axios.get(`${API_URL}/api/get_departments`, {
          headers: {
            Authorization: authKey,
          },
        });

        console.log("Departments fetched:", response.data);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchDepartments();
  }, []); // Empty dependency array ensures it only runs once when the component mounts

  const handleLinkClick = (path) => {
    if (activeLink !== path) { // Prevent unnecessary state updates
      setActiveLink(path);
    }
  };

  const toggleDropdown = (menuItemId) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [menuItemId]: !prevState[menuItemId],
    }));
  };

  const renderIcon = (icon) => {
    const extensions = ['png', 'jpg', 'jpeg', 'svg'];
    const className = icon === "dot" ? "sidebar-dot-icon" : "sidebar-icon";

    for (let ext of extensions) {
      try {
        const IconComponent = icons[`${icon}.${ext}`];
        if (IconComponent) {
          return <img src={IconComponent} className={className} alt={icon} />;
        }
      } catch (error) {
        console.error(`Icon not found: ${icon}.${ext}`);
      }
    }
    return null;
  };

  const renderExpandCollapseIcon = (isOpen) => {
    return isOpen ? (
      <IoIosArrowDown className="expand-collapse-icon" />
    ) : (
      <IoIosArrowForward className="expand-collapse-icon" />
    );
  };

  const renderMenuItems = (items) => {
    return (
      <div className="submenu">
        {items.map(({ id, name, route, icon, sub_menu }) => {
          if (name === "Preventive schedules" && sub_menu.length === 0) {
            const dynamicSubmenu = [
              {
                id: "all-schedules",
                name: "All",
                route: "/all-schedules",
                sub_menu: [],
              },
              ...departments.map((department) => ({
                id: department.ID,
                name: department.DepartmentName,
                route: `/department/${department.ID}`,
                sub_menu: [],
              })),
            ];
            sub_menu = dynamicSubmenu;
          }

          return (
            <div key={id}>
              {route ? (
                <Link
                  to={route}
                  onClick={() => handleLinkClick(route)}
                  className={`user-link ${activeLink === route ? "active" : ""}`}
                >
                  <div className="user sidebar-hover">
                    <div className="user-icon">{renderIcon(icon)}</div>
                    {isExpanded && <div className="user-font">{name}</div>}
                  </div>
                </Link>
              ) : (
                <div
                  className={`user sidebar-hover ${dropdownState[id] ? "active" : ""}`}
                  onClick={() => toggleDropdown(id)}
                >
                  <div className="user-icon">{renderIcon(icon)}</div>
                  {isExpanded && <div className="user-font">{name}</div>}
                  {isExpanded && (
                    <div className="expand-collapse-icon">
                      {renderExpandCollapseIcon(dropdownState[id])}
                    </div>
                  )}
                </div>
              )}

              {dropdownState[id] && sub_menu.length > 0 && isExpanded && (
                <div className="submenu-menu">
                  {renderMenuItems(sub_menu)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="product-content-container">
        <div className="sidebar-container">
          {showSide && (
            <div className="sidebar-content">{renderMenuItems(menuItems)}</div>
          )}
          {!showSide && (
            <div className="sidebar-content2">
              {menuItems.map((menuItem) => (
                <Tooltip title={menuItem.name} arrow placement="right"> {/* Add Tooltip */}
                  <Link
                    key={menuItem.id}
                    to={menuItem.route} // Ensure the icon is wrapped with a Link
                    onClick={() => handleLinkClick(menuItem.route)}
                    className="user sidebar-hover"
                  >
                    {renderIcon(menuItem.icon)} {/* Only render the icon */}
                  </Link>
                </Tooltip>
              ))}
            </div>
          )}
          <div
            className="sidebar-footer"
            style={{
              width: isExpanded ? "250px" : "50px",
              transition: "width 0.3s ease-in-out",
              zIndex: 2,
            }}
            onClick={toggleSidebar}
          >
            <div className="expand-sidebar">
              {isExpanded ? <IoIosArrowBack /> : <IoIosArrowForward />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
}

export default Sidebar;
