import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select, { components } from "react-select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import { CiCirclePlus } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";


function EditEquipment({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { ID } = useParams();
  const [equipmentData, setEquipmentData] = useState({});
  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success")
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMaintanceDate, setSelectedMaintanceDate] = useState(null);
  const [selectedWarrantyDate, setselectedWarrantyDate] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState(null);
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
  const [model, setModel] = useState("");
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [selectedSubDepartmentId, setSelectedSubDepartmentId] = useState(null);
  const [frequencyOfMaintenance, setFrequencyOfMaintenance] = useState("");
  const [selectedSubTypeVariant, setSelectedSubTypeVariant] = useState(null);
  const [selectedEquipmentSubTypeVariant, setSelectedEquipmentSubTypeVariant] =
    useState("");
  const handleMaintanaceDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedMaintanceDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        LastMaintenanceDate: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        InstallationYear: year,
      }));
    }
  };

  const handleWarrantyDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setselectedWarrantyDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        WarrantyExp: formattedDate,
      }));
    }
  };

  useEffect(() => {
    const fetchEquipmentData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(`${API_URL}/api/get_equipment/${ID}`, {
          headers: {
            Authorization: authKey,
          },
        });
        if (response.data && response.data.length > 0) {
          const equipment = response.data[0];
          setEquipmentData(equipment);
          setSelectedDepartment({
            label: equipment.Department,
            value: equipment.DepartmentId,
          });
          setSelectedEquipmentTypes({
            label: equipment.EquipmentType,
            value: equipment.EquipmentTypeId,
          });
          setSelectedEquipmentSubType({
            label: equipment.EquipmentSubType,
            value: equipment.EquipmentSubTypeId,
          });
          setSelectedSubDepartment({
            label: equipment.SubDepartmentName,
            value: equipment.EquipmentSubTypeId,
          });
          setSelectedSubTypeVariant({
            label: equipment.SubTypeVariant || "Select sub type variant",
            value: equipment.SubTypeVariantId || null,
          });
          setSelectedMaintanceDate(new Date(equipment.LastMaintenanceDate));
          setselectedWarrantyDate(new Date(equipment.WarrantyExp));
          setModel(equipment.Model || "");
          setFrequencyOfMaintenance(equipment.FrequencyOfMaintenance || "");
          setSelectedInstallationDate(
            new Date(`${equipment.InstallationYear}-01-01`)
          );
        } else {
          setError("No equipment data found");
        }
      } catch (error) {
        console.error("Error fetching equipment data:", error.message);
        setError("An error occurred while fetching equipment data");
      }
    };
    fetchEquipmentData();
  }, [ID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEquipmentData({ ...equipmentData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    navigate("/new-equipment");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    // Check for empty fields

    const errors = {};
    let error = false;
    Object.keys(equipmentData).forEach(async (key) => {
      if (!equipmentData[key]) {
        errors[key] = `${key} is required`;
        setSeverity("warning")
        showSnackbar("Required fields are empty");
        error = true;
      } 
    });

    if(error === false) {
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem("authKey");
        const formattedData = {
          ...equipmentData,
          // Ensure that the dates are in YYYY-MM-DD format
          WarrantyExp: selectedWarrantyDate.toISOString().slice(0, 10),
          LastMaintenanceDate: selectedMaintanceDate.toISOString().slice(0, 10),
          InstallationYear: selectedInstallationDate.getFullYear(),
        };
        const response = await axios.put(
          `${API_URL}/api/update_equipment/${ID}`,
          formattedData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log(response.data.message);
        if (response.status === 200) {
          setSeverity("success")
          showSnackbar("Data updated successfully");
          setTimeout(() => {
            navigate("/equipment");
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating company:", error.message);
        setError("An error occurred while updating company data");
        setIsLoading(false);
      }
    }
  };

  const handleBackProject = () => {
    navigate("/equipment");
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      DepartmentName: selectedOption.label,
      DepartmentId: selectedOption.value,
    }));
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const fetchSubDepartments = async (departmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_department/${departmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const options = data.map((subDepartment) => ({
          label: subDepartment.Name,
          value: subDepartment.id,
        }));
        setSubDepartments(options);
      } else {
        console.error("Failed to fetch sub-departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching sub-departments:", error);
    }
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchSubDepartments(selectedDepartment.value);
    }
  }, [selectedDepartment]);

  const handleSubDepartmentChange = (selectedOption) => {
    setSelectedSubDepartment(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      SubDepartmentName: selectedOption.label,
      SubDepartmentId: selectedOption.value,
    }));
    fetchEquipmentTypes(selectedOption.value);
  };

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectedEquipmentTypes(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentType: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    fetchEquipmentSubType(selectedOption.value);
  };

  const fetchEquipmentTypes = async (subDepartmentId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types_by_sub_department/${subDepartmentId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Types");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Types:", error);
    }
  };

  useEffect(() => {
    if (selectedSubDepartment) {
      fetchEquipmentTypes(selectedSubDepartment.value);
    }
  }, [selectedSubDepartment]);

  const handleTypeSubTypeChange = (selectedOption) => {
    setSelectedEquipmentSubType(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      EquipmentSubType: selectedOption.label,
      EquipmentSubTypeId: selectedOption.value,
    }));
  };

  const fetchEquipmentSubType = async (equipmentTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_sub_types_by_type/${equipmentTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubType) => ({
          label: equipmentSubType.EquipmentSubType,
          value: equipmentSubType.id,
        }));
        setEquipmentSubTypes(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentTypes) {
      fetchEquipmentSubType(selectedEquipmentTypes.value);
    }
  }, [selectedEquipmentTypes]);

  const fetchEquipmentSubTypeVariants = async (equipmentSubTypeId) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_sub_type_variants_by_sub_type/${equipmentSubTypeId}`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((equipmentSubTypeVariant) => ({
          label: equipmentSubTypeVariant.VariantName,
          value: equipmentSubTypeVariant.id,
        }));
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    if (selectedEquipmentSubType) {
      fetchEquipmentSubTypeVariants(selectedEquipmentSubType.value);
    }
  }, [selectedEquipmentSubType]);

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectedSubTypeVariant(selectedOption);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      SubTypeVariant: selectedOption.label,
      VariantNameId: selectedOption.value,
    }));
  };
  const handleModelChange = (e) => {
    const { value } = e.target;
    setModel(value);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      Model: value,
    }));
  };

  const handleFrequencyOfMaintenanceChange = (e) => {
    const { value } = e.target;
    setFrequencyOfMaintenance(value);
    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      FrequencyOfMaintenance: value,
    }));
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Equipment
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="">
              <div className="new-data-edit-form">
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    options={departments}
                    placeholder="Select a department"
                    isDisabled={!selectedDepartment} // Disable until department data is loaded
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubDepartment">
                    Sub Department <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedSubDepartment} // Corrected variable name
                    onChange={handleSubDepartmentChange}
                    options={subDepartments}
                    placeholder="Select sub department"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="equipment">
                    Equipment Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedEquipmentTypes} // Corrected variable name
                    onChange={handleEquipmentTypeChange}
                    options={equipmentTypes}
                    placeholder="Select equipment type"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="Department">
                    Equipment Sub Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    options={equipmentSubTypes}
                    placeholder="Select equipment sub type"
                  />
                </div>
                <div className="form-group-select">
                  <label htmlFor="SubTypeVariant">
                    Sub Type Variant <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedSubTypeVariant}
                    onChange={handleTypeSubTypeVariantsChange}
                    options={equipmentSubTypeVariants}
                    placeholder="Select equipment sub type variant"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="TagNo">
                    Tag No.<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="TagNo"
                      value={equipmentData.TagNo || ""}
                      onChange={handleInputChange}
                      placeholder="Enter tag number"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Make">
                    Make<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Make"
                      value={equipmentData.Make || ""}
                      onChange={handleInputChange}
                      placeholder="Enter make"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Model">
                    Model<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Model"
                      value={model}
                      onChange={handleModelChange}
                      placeholder="Enter model"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Installation">
                    Year Of Installation<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedInstallationDate}
                      onChange={handleInstallationDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      isClearable={false}
                      showYearPicker // This will display only the year picker
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Warranty">
                    Warranty Expiry<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedWarrantyDate}
                      onChange={handleWarrantyDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a expiry date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="FrequencyOfMaintenance">
                    Frequency of Maintenance
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="FrequencyOfMaintenance"
                      value={frequencyOfMaintenance}
                      onChange={handleFrequencyOfMaintenanceChange}
                      placeholder="Enter frequency of maintenance"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Maintenance_Date">
                    Last Maintenance Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedMaintanceDate}
                      onChange={handleMaintanaceDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a maintenance date"
                      className="datesss"
                      isClearable={false}
                      showYearDropdown
                      showMonthDropdown
                      todayButton="Today"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Location">
                    Location<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Location"
                      value={equipmentData.Location || ""}
                      onChange={handleInputChange}
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">
                    Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      value={equipmentData.Description || ""}
                      onChange={handleInputChange}
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditEquipment;
