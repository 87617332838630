import React, { useState, useEffect, useRef } from "react";
import "./PreventiveSchedulesView.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function BreakdownView({ isSidebarExpanded }) {
  const { breakdown_id } = useParams();
  const [breakdownData, setBreakdownData] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };


  const fetchBreakdownData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(
        `${API_URL}/api/get_breakdown_schedule/${breakdown_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setBreakdownData(data || {});
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  };

  useEffect(() => {
    fetchBreakdownData();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/breakdown-schedules");
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);

    setOfferDocumentError("");
  };

  const handleIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const element = document.getElementById(`docket-file`);
    if (element) {
      element.click();
    }
  }, []);

  const handleStatusChange = async (e) => {
    // Implement status change logic
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                Breakdown Scheduler View
              </div>
            </div>
            <div className="action-purchase-header">
              <div className="action-purchase-header-content">
                <div className="invoice-request-details">
                  <div className="project-detail">
                    <div className="detail-header">Department Name:</div>
                    <div className="detail-part">
                      {breakdownData.department_name}
                    </div>
                  </div>
                  <div className="project-detail">
                    <div className="detail-header">Problem Description:</div>
                    <div className="detail-part">
                      {breakdownData.problem_desc}
                    </div>
                  </div>
                  <div className="project-detail">
                    <div className="detail-header">Tag No:</div>
                    <div className="detail-part">{breakdownData.tag_no}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-body-container">
              <div className="invoice-body-container-content">
                <div className="invoice-upload-files">
                  <label className="upload-label" htmlFor="docket-file">
                    Attachments
                  </label>
                  <div className="file-name-label">
                    <label htmlFor="" className="file-name-lists">
                      File Name:
                    </label>
                    <span className="file-name"></span>
                  </div>
                  <div className="chalann-input">
                    <div className="input-group-attachments">
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={handleUploadButtonClick}
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                      {selectedFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleIconClick}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedFiles.length}`}
                        </span>
                      )}
                      {isPopupOpen && (
                        <div className="attach-popups">
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleCloseAttachment}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div className="file-delete-btn">
                                        {/* <span> <MdDeleteOutline className="file-delete-icon" /></span> */}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="invoice-upload-files">
                  <div>
                    <label className="upload-label" htmlFor="docket-file">
                      Current Status :
                      <span
                        className={
                          breakdownData.status === "Completed"
                            ? "status-completed"
                            : breakdownData.status === "Cancelled"
                            ? "status-cancelled"
                            : "status-pending"
                        }
                      >
                        {breakdownData.status}
                      </span>
                    </label>
                  </div>
                  <div className="file-name-label"></div>
                  <div className="chalann-input">
                    <div className="status-select-input">
                      <select
                        className="select-file-dropdown"
                        onChange={handleStatusChange}
                      >
                        <option value="Open">Open</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="invoice-upload-files">
                  <div>
                    <label className="upload-label" htmlFor="docket-file">
                      Breakdown remarks :
                    </label>
                    <span>{breakdownData.remarks}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default BreakdownView;
