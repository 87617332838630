import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignUp from "./components/SignUp";
// import Pagination from "./components/Pagination";
import Login from "./components/Login";
import UserView from "./view/UserView";
import ContractorView from "./view/ContractorView";
import CustomerView from "./view/CustomerView";
import FrequencyConfigView from "./view/FrequencyConfigView";
import ProjectView from "./view/ProjectView";
import EquipmentView from "./view/EquipmentView";
import StatutoryCompliancesView from "./view/StatutoryCompliancesView";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import MenuConfig from "./components/MenuConfig";
import UserForm from "./forms/UserForm";
import User from "./tables/User";
import PRRequest from "./tables/PRRequest";
import VendorForm from "./forms/VendorForm";
import Vendor from "./tables/Vendor";
import AmcSchedules from "./tables/AmcSchedules";
import AllPreventiveSchedules from "./AllSchedules/AllPreventiveSchedules";
import BreakDownSchedules from "./AllSchedules/BreakDownSchedules";
import AllAmcSchedules from "./AllSchedules/AllAmcSchedules";
import ElectricalDepartment from "./AllSchedules/ElectricalDepartment";
import Equipment from "./tables/Equipment";
import NewEquipment from "./tables/NewEquipment";
import SidePettyCash from "./tables/SidePettyCash";
import SidePettyCashForm from "./forms/SidePettyCashForm";
import EditSidePettyCash from "./editforms/EditSidePettyCash";
import ContractorCompany from "./tables/ContractorCompany";
import EquipmentSubType from "./tables/EquipmentSubType";
import EquipmentType from "./tables/EquipmentType";
import EquipmentTypevariant from "./tables/EquipmentTypevariant";
import ReminderDetails from "./tables/ReminderDetails";
import EquipmentForm from "./forms/EquipmentForm";
import ReportPage from "./Pages/ReportPage";
import ScheduleReportview from "./view/ScheduleReportview";
import ScheduleGenerator from "./Pages/ScheduleGenerator";
import AmcSchedulesForm from "./forms/AmcSchedulesForm";
import NewEquipmentForm from "./forms/NewEquipmentForm";
import ProjectForm from "./forms/ProjectForm";
import PRRequestForm from "./forms/PRRequestForm";
import CustomerForm from "./forms/CustomerForm";
import BreakDownForm from "./forms/BreakDownForm";
import EquipmentTypeMatCode from "./tables/EquipmentTypeMatCode";
import LevelConfig from "./Matcode/LevelConfig";
import Level1Form from "./Matcode/Level1Form";
import UpdateMatCodeLevel1 from "./Matcode/UpdateMatCodeLevel1";
import UpdateMatCodeLevel2 from "./Matcode/UpdateMatCodeLevel2";
import UpdateMatCodeLevel3 from "./Matcode/UpdateMatCodeLevel3";
import MatCodeConfigForm from "./Matcode/MatCodeConfigForm";
import Level2Form from "./Matcode/Level2Form";
import Level3Form from "./Matcode/Level3Form";
import Project from "./tables/Project";
import Designation from "./tables/Designation";
import Customer from "./tables/Customer";
import Expense from "./tables/Expense";
import ExpenseForm from "./forms/ExpenseForm";
import EditDesignation from "./editforms/EditDesignation";
import ManpowerRequirmentForm from "./forms/ManpowerRequirmentForm";
import ActualManpowerForm from "./forms/ActualManpowerForm";
import ManpowerRequirment from "./tables/ManpowerRequirment";
import ActualManpower from "./tables/ActualManpower";
import DesignationForm from "./forms/DesignationForm";
import ContractorCompanyForm from "./forms/ContractorCompanyForm";
import EquipmentSubTypeForm from "./forms/EuipmentSubTypeForm";
import FrequencyConfig from "./forms/FrequencyConfig";
import Frequency from "./tables/Frequency";
import EquipmentTypeForm from "./forms/EquipmentTypeForm";
import EditFrequency from "./editforms/EditFrequency";
import EquipmentTypevariantForm from "./forms/EquipmentTypevariantForm";
import UserType from "./tables/UserType";
import PRRequestList from "./tables/PRRequestList";
import ApprovedPRList from "./tables/ApprovedPRList";
import UserTypeForm from "./forms/UserTypeForm";
import SchedulesReport from "./Pages/SchedulesReport";
import AmcReportView from "./view/AmcReportView";
import ManpowerHistory from "./view/ManpowerHistory";
import History from "./view/History";
import Breakdownreportview from "./view/Breakdownreportview";
import ProcurementreportView from "./view/ProcurementreportView";
import Department from "./tables/Department";
import MatCode from "./tables/MatCode";
import MatCodeForm from "./forms/MatCodeForm";
import AmcSchedulesView from "./view/AmcSchedulesView";
import BreakdownView from "./view/BreakdownView";
import PRRequestView from "./view/PRRequestView";
import VendorView from "./view/VendorView" ;
import PreventiveSchedulesView from "./view/PreventiveSchedulesView";
import SubDepartment from "./tables/SubDepartment";
import ReportManpower from "./tables/ReportManpower";
import DepartmentForm from "./forms/DepartmentForm";
import SubDepartmentForm from "./forms/SubDepartmentForm";
import EditCustomer from "./editforms/EditCustomer";
import EditPRRequest from "./editforms/EditPRRequest";
import EditProject from "./editforms/EditProject";
import EditContractor from "./editforms/EditContractor";
import EditUserForm from "./editforms/EditUserForm";
import EditAmcForm from "./editforms/EditAmcForm";
import EditManpower from "./editforms/EditManpower";
import EditActualManpower from "./editforms/EditActualManpower";
import EditVendor from "./editforms/EditVendor";
import EditMatCode from "./editforms/EditMatCode";
import StatutoryCompliance from "./tables/StatutoryCompliance";
import StatutoryComplianceForm from "./forms/StatutoryComplianceForm";
import EditStatutoryCompliance from "./editforms/EditStatutoryCompliance";
import EditBreakdown from "./editforms/EditBreakdown";
import EditEquipmentType from "./editforms/EditEquipmentType";
import EditNewEquipment from "./editforms/EditNewEquipment";
import EditEquipmentSubType from "./editforms/EditEquipmentSubType";
import EditEquipment from "./editforms/EditEquipment";
import EditEquipmentSubTypeVariant from "./editforms/EditEquipmentSubTypeVariant";
import EditDepartment from "./editforms/EditDepartment";
import EditSubDepartment from "./editforms/EditSubDepartment";
import ArchivedUser from "./archivedtables/ArchivedUser";
import ArchivedCustomer from "./archivedtables/ArchivedCustomer";
import ArchivedProject from "./archivedtables/ArchivedProject";
import ArchivedContractorCompany from "./archivedtables/ArchivedContractorCompany";
import ArchivedDepartment from "./archivedtables/ArchivedDepartment";
import ArchivedSubDepartment from "./archivedtables/ArchivedSubDepartment";
import ArchivedEquipment from "./archivedtables/ArchivedEquipment";
import ArchivedEquipmentType from "./archivedtables/ArchivedEquipmentType";
import ArchivedEquipmentSubType from "./archivedtables/ArchivedEquipmentSubType";
import ArchivedEquipmentSubTypevariant from "./archivedtables/ArchivedEquipmentSubTypevariant";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  useEffect(() => {
    const authKey = localStorage.getItem("authKey");
    const name = localStorage.getItem("userName");

    if (authKey && name) {
      setLoggedIn(true);
      setUserName(name);
    } else {
      setLoggedIn(false);
      setUserName("");
    }

    // Simulate a delay to see the loader
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleLogin = (authKey, name, email, company_id) => {
    if (authKey && name) {
      localStorage.setItem("authKey", authKey);
      localStorage.setItem("userName", name);
      localStorage.setItem("email", email);
      localStorage.setItem("company_id", company_id);
      setLoggedIn(true);
      setUserName(name);
    }
  };

  const handleSidebarToggle = (expanded) => {
    setIsSidebarExpanded(expanded);
  };

  return (
    <Router>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <div>Loading...</div>
        </div>
      ) : (
        <Routes>
          {!loggedIn && <Route path="*" element={<Navigate to="/login" />} />}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/signup" element={<SignUp />} />
          {loggedIn && (
            <>
              <Route
                path="/user"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <User isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/all-schedules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AllPreventiveSchedules
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/reports"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ReportPage
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
               <Route
                path="/schedules-reports"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <SchedulesReport
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
               
                <Route
                path="/mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <MatCode
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
               <Route
                path="/maintenance-history"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <History
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
                <Route
                path="/mat-code-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <MatCodeForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              
              <Route
                path="/amc-configuration"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AmcSchedules isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/pr-request"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <PRRequest isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/user-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UserView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/contractor-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ContractorView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <CustomerView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/frequency-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <FrequencyConfigView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/projeect-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ProjectView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
               <Route
                path="/joining-history/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ManpowerHistory isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/statutory-view-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <StatutoryCompliancesView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/equipment-view"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Dashboard isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/pr-request-view/:pr_request_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <PRRequestView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-mat-code-l1/:l1_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UpdateMatCodeLevel1 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code-l2/:l2_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UpdateMatCodeLevel2 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-mat-code-l3/:l3_id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UpdateMatCodeLevel3 isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/new-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <MatCodeConfigForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              {/* <Route
                path="/equipment-type-mat-code-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentTypeMatCodeForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              /> */}
               <Route
                path="/equipment-type-mat-code-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <LevelConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/level1-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Level1Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/level2-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Level2Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/level3-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Level3Form isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/equipment-type-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentTypeMatCode isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              
              <Route
                 path="/schedule-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ScheduleReportview isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                 path="/amc-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AmcReportView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                 path="/breakdown-report-view/:department_id/:filter_option/:status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Breakdownreportview isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                 path="/procurement-report-view/:department_id/:filter_option/:pr_status"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ProcurementreportView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/pr-request-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <PRRequestForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-pr-request-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditPRRequest isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-schedules"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AllAmcSchedules isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/pr-requests-list"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <PRRequestList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />

            
               <Route
                path="/approved-pr-requests"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ApprovedPRList isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/amc-schedules-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AmcSchedulesForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/userform"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UserForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-statutory-compliance/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditStatutoryCompliance
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/statutory-compliance-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <StatutoryComplianceForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/statutory-compliance"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <StatutoryCompliance
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/menu-configuration"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <MenuConfig isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Equipment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/breakdown-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <BreakDownForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/new-equipment"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <NewEquipment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/vendor-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <VendorForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/vendors"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Vendor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/schedule_generator"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ScheduleGenerator isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-vendor/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditVendor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/contractor-Company"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ContractorCompany isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/new-equipment-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <NewEquipmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/designations"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Designation isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-designation/:ID"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditDesignation isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/designation-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <DesignationForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/side-petty-cash"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <SidePettyCash isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/side-petty-cash-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <SidePettyCashForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-side-petty-cash"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditSidePettyCash isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentTypeForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/expense"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Expense isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/expense-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ExpenseForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/contractor-company-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ContractorCompanyForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentSubType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentSubTypeForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-variant"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentTypevariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/equipment-sub-type-variant-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EquipmentTypevariantForm
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </div>
                }
              />
              <Route
                path="/user-type"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UserType isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/manpowerrequirment-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ManpowerRequirmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/man-powers/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ManpowerRequirment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/actualmanpower/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ActualManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/actualmanpower-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ActualManpowerForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                <Route
                path="/edit-man-powers-form/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/edit-actual-man-powers/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditActualManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
               <Route
                path="/report-manpower/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ReportManpower isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-contractor/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditContractor isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-amc/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditAmcForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
                
                  <Route
                path="/edit-mat-code"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditMatCode isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              
              <Route
                path="/vendor-view/:id"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <VendorView isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/user-type-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <UserTypeForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/department"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Department isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/sub-department"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <SubDepartment isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/department-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <DepartmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/sub-department-form"
                element={
                  <div>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <SubDepartmentForm isSidebarExpanded={isSidebarExpanded} />
                  </div>
                }
              />
              <Route
                path="/edit-user/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditUserForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-equipment-type/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditEquipmentType isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-frequency/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditFrequency isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-breakdown/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditBreakdown isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-new-equipment/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditNewEquipment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
               <Route
                path="/reminders/:department_id/:due_type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ReminderDetails isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/electrical-department/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ElectricalDepartment
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/frequency"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Frequency isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/project-form"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ProjectForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer-form"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <CustomerForm isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-customer/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditCustomer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-project/:id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditProject isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/project"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Project isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/breakdown-schedules"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <BreakDownSchedules isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/customer"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <Customer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/frequency-config"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <FrequencyConfig isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-equipment-sub-Type/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditEquipmentSubType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/edit-equipment/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditEquipment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-equipment-sub-type-variant/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditEquipmentSubTypeVariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/edit-department/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/edit-sub-department/:ID"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <EditSubDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-user"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedUser isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-department"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedDepartment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/scheduler-view/:scheduler_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <PreventiveSchedulesView
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/breakdown-scheduler-view/:breakdown_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <BreakdownView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/amc-scheduler-view/:schedule_id"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <AmcSchedulesView isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-sub-department"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedSubDepartment
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              ArchivedContractorCompany
              <Route
                path="/archive-contractor-company"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedContractorCompany
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedEquipment isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-customer"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedCustomer isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-project"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedProject isSidebarExpanded={isSidebarExpanded} />
                  </>
                }
              />
              <Route
                path="/archive-equipment-type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedEquipmentType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment-sub-type"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedEquipmentSubType
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
              <Route
                path="/archive-equipment-sub-type-variant"
                element={
                  <>
                    <Sidebar onSidebarToggle={handleSidebarToggle} />
                    <ArchivedEquipmentSubTypevariant
                      isSidebarExpanded={isSidebarExpanded}
                    />
                  </>
                }
              />
            </>
          )}
        </Routes>
      )}
    </Router>
  );
};

export default App;
