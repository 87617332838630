import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from "react-icons/io5";
import Select, { components } from 'react-select';
import API_URL from "../../src/Config";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import "./DepartmentPopup.css";
import { CiCirclePlus } from "react-icons/ci";
import axios from 'axios';

const { Control, Menu } = components;

const AddSubTypeVariant = ({ closePopup, parentSelectedEquipmentSubType, handleNewEquipmentSubTypeVariant }) => {
    const [equipmentSubTypes, setEquipmentSubTypes] = useState([]);
    const [selectedEquipmentSubType, setSelectedEquipmentSubType] = useState(parentSelectedEquipmentSubType);
    const [EquipmentSubTypeMenuIsOpen, setEquipmentSubTypeMenuIsOpen] = useState(false);
    const [equipmentSubTypeVariantData, setEquipmentSubTypeVariantData] = useState({
      EquipmentSubType: parentSelectedEquipmentSubType ? parentSelectedEquipmentSubType.label : '',
      EquipmentSubTypeId: parentSelectedEquipmentSubType ? parentSelectedEquipmentSubType.value : '',
      VariantName: '',
      Frequency: '',
      ApplicableOISD: '',
      Subsection: '',
        
    });
  
    const [snackbarMessage, setSnackbarMessage] = useState(''); // New state for Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setEquipmentSubTypeVariantData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
  
    const handleSaveClick = async () => {
      const updatedSubTypeVariantData = {
        ...equipmentSubTypeVariantData,
      };
  
      try {
        setIsLoading(true);
        const authKey = localStorage.getItem('authKey');
  
  
        const response = await axios.post(
          `${API_URL}/api/add_sub_type_variant`,
          updatedSubTypeVariantData,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
  
        console.log(response.data.message);
        if (response.status === 200) {
          const newSubTypeVariant = {
            label: equipmentSubTypeVariantData.VariantName,
            value: response.data.id,
          };
          handleNewEquipmentSubTypeVariant(newSubTypeVariant)
          setTimeout(() => {
            closePopup();
          }, 1000);
  
        }
      } catch (error) {
        console.error('Error saving user:', error.message);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    
    };
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };
  
    const showSnackbar = (message) => {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    };
  
  
    const handleEquipmentSubTypeMenuOpen = () => {
      setEquipmentSubTypeMenuIsOpen(true);
    };
  
    const CustomControl = ({ children, ...props }) => (
      <Control {...props}>{children}</Control>
    );
    // const CustomMenu = ({ children, ...props }) => (
    //   <Menu {...props}>
    //     {children}
    //     <button
    //       type="button"
    //       className="add-company-button"
    //       // onClick={handleAddNewPerson}
    //     >
    //       <CiCirclePlus className="new-plus-circle" />
    //       Add Equipment Sub Type
    //     </button>
    //   </Menu>
    // );
  
    const handleTypeSubTypeChange = (selectedOption) => {
      setSelectedEquipmentSubType(selectedOption);
      setEquipmentSubTypeVariantData((prevFormData) => ({
        ...prevFormData,
        EquipmentSubType: selectedOption.label, // Update EquipmentSubType
        EquipmentSubTypeId: selectedOption.value, // Update EquipmentSubTypeId
      }));
    };
    
  
    const fetchEquipmentSubType = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const apiUrl = `${API_URL}/api/get_equipment_sub_types`;
    
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authKey,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          // Map the API response to the format expected by react-select
          const options = data.map((equipmentSubType) => ({
            label: equipmentSubType.EquipmentSubType,
            value: equipmentSubType.id
          }));
          setEquipmentSubTypes(options);
        } else {
          console.error("Failed to fetch Equipment Type");
        }
      } catch (error) {
        console.error("An error occurred while fetching Equipment Type:", error);
      }
    };
    useEffect(() => {
      fetchEquipmentSubType();
    }, []);
 
  return (
    <div className="inquiry-customerperson-popup-content">
      <div className="inquiry-customerperson-modal">
        <div className="inquiry-customerperson-modal-content">
          <div className='customerpersonpopupform-header'>
            <h4 className='customerpersonpopupform-title'> New Equipement Sub Type Variant</h4>
            <span className='customerpersonpopupform-btn' onClick={closePopup}><IoCloseOutline /></span>
          </div>
          <div className="customerpersonpopupform-body">
          <form>
          <div className="customerpersonpopup-form">
          <div className="form-group-select">
                  <label htmlFor="EquipmentSubType">Sub Type<span className="text-danger">*</span></label>
               
                  <Select
                    id="EquipmentSubTypess"
                    options={equipmentSubTypes}
                    value={selectedEquipmentSubType}
                    onChange={handleTypeSubTypeChange}
                    components={{ Control: CustomControl, }}
                    placeholder="Select Sub Type"
                    isSearchable
                    menuIsOpen={EquipmentSubTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentSubTypeMenuOpen}
                    onMenuClose={() => setEquipmentSubTypeMenuIsOpen(false)}
                  />
                 
                
                </div>
                <div className="form-group">
                  <label htmlFor="VariantName">Name<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="VariantName"
                      value={equipmentSubTypeVariantData.VariantName}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
                <div className="form-group">
                  <label htmlFor="ApplicableOISD">Applicable OISD<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="ApplicableOISD"
                      value={equipmentSubTypeVariantData.ApplicableOISD}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
                <div className="form-group">
                  <label htmlFor="Subsection">Subsection<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="Subsection"
                      value={equipmentSubTypeVariantData.Subsection}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
                <div className="form-group">
                  <label htmlFor="Frequency">Frequency<span className="text-danger">*</span></label>
                  <div className="input-groups">
                    <input
                      type="text"
                      name="Frequency"
                      value={equipmentSubTypeVariantData.Frequency}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                    />

                  </div>
                
                </div>
              </div>
            </form>
          </div>
          <div className="customerpersonpopupform-footer">
            <div className="button-group">
              <button className="customerpersonpopupform-save-button"   onClick={handleSaveClick}
                disabled={isLoading} >
                {isLoading ? 'Saving...' : 'Save'}
              </button>
              <button className="customerpersonpopupform-cancel-button"     onClick={closePopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="succeess"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default AddSubTypeVariant;
