import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './SignUp.css';
import API_URL from "../../src/Config";
import { Link } from "react-router-dom";

const SignUp = ({ onSignUp }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Add state variables for validation messages
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [alertType, setAlertType] = useState("");

  const validateInputs = () => {
    let isValid = true;

    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      setEmailError("");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    }

    if (!username) {
      setUsernameError("Username is required");
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!rePassword) {
      setRePasswordError("Please re-enter your password");
      isValid = false;
    } else {
      setRePasswordError("");
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least 8 characters, at least one uppercase letter, one lowercase letter, at least one number, and at least one special character"
      );
      isValid = false;
    } else if (password !== rePassword) {
      setRePasswordError("Passwords do not match");
      isValid = false;
    }

    return isValid;
  };

  const handleSignUp = async () => {
    if (!validateInputs()) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_URL}/api/signup`, {
        name,
        email,
        username,
        password,
      }
      );
      setIsLoading(false);
      const { message } = response.data;
      setAlertMessage(message);
      onSignUp();
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 409) {
        const message = error.response.data.message;
        console.error("Sign up failed:", message);
        setAlertMessage(message);
      } else if (error.response && error.response.status === 500) {
        const message = error.response.data.message;
        console.error("Internal server error:", message);
        setAlertMessage(message);
      } else {
        console.error("An error occurred during sign up:", error.message);
        setAlertMessage("An error occurred during sign up");
      }
    }
  };

  function handleBack() {
    window.history.back();
  }

  return (
    <div className="signup-container">
      <div className="signup-contant">
        <div className="signup-image">
          <img src="/logo101.png" alt="Logo" className="signup-logo" />
        </div>
        <div className="signup">
          <div className="card-body">
            <h3 className="signup-card">Create Account</h3>
            {alertMessage && (
              <div className={`alert ${alertType === 'success' ? 'success-message' : 'error-message'}`}>
                {alertMessage}
              </div>
            )}

            <div>
              <form>
                <div className="signup-form-groups">
                  <label className="signup-lable">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError("");
                    }}
                  />
                  {nameError && (
                    <div className="text-danger">{nameError}</div>
                  )}
                </div>
                <div className="signup-form-groups">
                  <label className="signup-lable">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                  {emailError && (
                    <div className="text-danger">{emailError}</div>
                  )}
                </div>
                <div className="signup-form-groups">
                  <label className="signup-lable">
                    Username<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setUsernameError("");
                    }}
                  />
                  {usernameError && (
                    <div className="text-danger">{usernameError}</div>
                  )}
                </div>
                <div className="signup-form-groups">
                  <label className="signup-lable">
                    Password<span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                  />
                  {passwordError && (
                    <div className="text-danger">{passwordError}</div>
                  )}
                </div>
                <div className="signup-form-groups">
                  <label className="signup-lable">
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-enter password"
                    value={rePassword}
                    onChange={(e) => {
                      setRePassword(e.target.value);
                      setRePasswordError("");
                    }}
                  />
                  {rePasswordError && (
                    <div className="text-danger">{rePasswordError}</div>
                  )}
                </div>
                <div className="signup-btn">
                  <button
                    type="button"
                    className="continue"
                    onClick={handleSignUp}
                    disabled={isLoading}
                  >
                    {isLoading ? "Registering..." : "Sign Up"}
                  </button>
                </div>
                <div className="last">
                  <p>
                    Already have an account?{" "}
                    <Link to="/signin" onClick={handleBack}>
                      Sign in
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
