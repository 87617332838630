import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { IoMdArrowBack } from "react-icons/io";
import { FaHistory } from "react-icons/fa";
import Pagination from "../components/Pagination";
function Department({ isSidebarExpanded }) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);


  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);

    // Store values in local storage
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    const storedItemsPerPage = localStorage.getItem("itemsPerPage");

    const page = storedPage ? parseInt(storedPage, 10) : 0;
    const itemsPerPage = storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 50;
    setPage(page);
    setItemsPerPage(itemsPerPage);

    fetchData(page, itemsPerPage);
  }, [page, itemsPerPage]);

  const containerRef = useRef(null);

  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  const handleViewHistory = (departmentID) => {
    navigate(`/joining-history/${departmentID}`);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_actual_manpower_by_project/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      setDepartments(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching department data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage, projectId) => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_actual_man_power`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
          project_id: projectId,
        },
      });

      if (response.status === 200) {
        setDepartments(response.data.Items);
        setTotalItems(response.data.TotalItems);
        setPage(response.data.CurrentPage);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     handleSearch();
  //   }, 600);
  //   return () => clearTimeout(delay);
  // }, [searchInput]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchInput.trim() !== "") {
        setIsSearching(true);
        handleSearch(page, itemsPerPage, id); // Pass projectId here
      } else {
        setIsSearching(false);
        fetchData(page, itemsPerPage); 
      }
    }, 600); // Adding delay for debouncing
  
    return () => clearTimeout(delay); 
  }, [searchInput, page, itemsPerPage, id]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? departments.map((department) => department.ID) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (departmentID) => {
    const updatedSelectedRows = selectedRows.includes(departmentID)
      ? selectedRows.filter((id) => id !== departmentID)
      : [...selectedRows, departmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === departments.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchiveDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDepartments = departments.filter(
          (department) => !selectedRows.includes(department.ID)
        );
        setDepartments(updatedDepartments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting departments:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchiveDeparment = () => {
    navigate("/archive-department");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };


  const handleRefresh = () => {
    fetchData(page, itemsPerPage);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleNew = () => {
    if (id) {
      navigate(`/actualmanpower-form/${id}`);
    } else {
      console.error("Project ID is undefined");

    }
  };

  const handleBackView = () => {
    navigate(`/project`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  
  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                      <button className="print">
                        <IoPrintOutline className="print-icon" />
                      </button>
                      <button className="bulk-update">Bulk Update</button>
                      <button
                        className="delete"
                        onClick={handleDeleteButtonClick}
                      >
                        <HiOutlineDotsHorizontal className="delete-icon" />
                      </button>
                      {isDeletePopupOpen && (
                        <div className="delete-popup">
                          <div className="delete-popup-content">
                            <button onClick={handleDeletePopup}>
                              Move to archive
                            </button>
                          </div>
                        </div>
                      )}
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchiveDepartment}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    <Tooltip title="Back" arrow>
                      <IoMdArrowBack
                        className="back-company"
                        onClick={handleBackView}
                      />
                    </Tooltip>{" "}
                    All Actual Manpower
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    {/* <Link to="/actualmanpower-form" className="data-list-new"> */}
                    <Tooltip title="New Department" arrow>
                      <button className="new-button" onClick={handleNew}>
                        <HiOutlinePlus className="new-plus-icon" /> New
                      </button>
                    </Tooltip>
                    {/* </Link> */}

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container">
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button"></div>
                          <button
                            className="delete-item"
                            onClick={handleArchiveDeparment}
                          >
                            Archived Manpower
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : departments.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>PERSON NAME</th>
                          <th>CONTACT NO</th>
                          <th>POSITION</th>
                          <th>CURRENT STATUS</th>
                          <th>EXPECTED DATE OF JOINING</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {departments.map((department, index) => (
                          <tr key={department.ID} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(department.ID)}
                                onChange={() => handleSelectRow(department.ID)}
                              />
                            </td>

                            <td>{index + 1}</td>
                            <td>{department.person_name || "NA"}</td>
                            <td>{department.contact_no || "NA"}</td>
                            <td>{department.position || "NA"}</td>
                            <td className="status-schedules">
                              <div
                                style={{
                                  backgroundColor:
                                    department.status === "Did not join"
                                      ? "rgb(219, 145, 33)" // Orange background
                                      : department.status === "Present at site"
                                        ? "blue" // Blue background
                                        : department.status === "Resigned"
                                          ? "red" // Red background
                                          : department.status ===
                                            "Appointment issued (yet to join)"
                                            ? "green" // Green background
                                            : "transparent", // Default background
                                  color: department.status ? "#fff" : "#888", // Adjust text color if needed
                                }}
                              >
                                {department.status || "NA"}
                              </div>
                              <div>
                                <div
                                  className="history-button"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    handleViewHistory(department.id)
                                  }
                                >
                                  <FaHistory />
                                </div>
                              </div>
                            </td>

                            <td>
                              {formatDate(
                                department.joining_expected_date || "NA"
                              )}
                            </td>

                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-actual-man-powers/${department.id}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Actual Manpower Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default Department;
