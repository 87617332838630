import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./PRRequestForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns";
import { IoCloseOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";

function PRRequestForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const navigate = useNavigate();
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [matCodes, setMatCodes] = useState([]);
  const [selectedMatCode, setSelectedMatCode] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [prRequestData, setPrRequestData] = useState({
    department_name: "",
    department_id: "",
    pr_type: "",
    description: "",
    mat_code: "",
    qty: "",
    preferred_vendor: "",
    unit: "",
    est_unit_rate: "",
    vendor_name: "",
    vendor_id: "",
  });
  const [vendorType, setVendorType] = useState("");



  const handleVendorTypeChange = (event) => {
    const vendorTypeValue = event.target.value;
    setVendorType(vendorTypeValue);
    setPrRequestData((prevState) => ({
      ...prevState,
      preferred_vendor: vendorTypeValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      preferred_vendor: '',
    }));
  };
  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_mat_code_details`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Transform data to match Select component's expected format
      const transformedData = response.data.mat_code_details.map(matCode => ({
        value: matCode.mat_code_id,
        label: matCode.mat_code,
      }));

      setMatCodes(transformedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMatCodeChange = (selectedOption) => {
    setSelectedMatCode(selectedOption);
    setPrRequestData((prevState) => ({
      ...prevState,
      mat_code: selectedOption ? selectedOption.label : "",
    }));
  };
  
  const unitOptions = [
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "m", label: "Meter (m)" },
    { value: "liters", label: "Liters (L)" },
    { value: "centimeter", label: "Centimeter (cm)" },
    { value: "millimeter", label: "Millimiter (mm)" },
    { value: "inch", label: "Inch (in)" },
    { value: "gram", label: "Gram (g)" },
    { value: "milligram", label: "Milligram (mg)" },
    { value: "kilometer", label: "Kilometer (km)" },
  ];


  const prTypeOptions = [
    { value: "AMC", label: "AMC" },
    { value: "Spare", label: "Spare" },
    { value: "Service", label: "Service" },
    { value: "Work Order", label: "Work Order" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPrRequestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCancelClick = () => {
    navigate("/pr-request");
  };

  const handleUnitChange = (selectedOption) => {
    setPrRequestData((prevState) => ({
      ...prevState,
      unit: selectedOption ? selectedOption.value : "",
    }));
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const payload = {
  //       ...prRequestData,

  //       expected_delivery_date: selectedDeliveryDate
  //         ? format(selectedDeliveryDate, "yyyy-MM-dd")
  //         : "",
  //     };
  //     const formData = new FormData();
  //     Object.keys(payload).forEach((key) => {
  //       formData.append(key, payload[key]);
  //     });
  //     // Append attachment files to FormData
  //     if (attachmentFile) {
  //       attachmentFile.forEach((file) => {
  //         formData.append("attachments", file);
  //         formData.append("filetype", "pr_specification");
  //       });
  //     }

  //     const response = await axios.post(
  //       `${API_URL}/api/add_pr_request`,
  //       formData, // Send FormData instead of JSON
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data", // Set content type for file upload
  //         },
  //       }
  //     );

  //     console.log(response.data.message);
  //     if (response.status === 200) {
  //       setSeverity("success");
  //       showSnackbar("PR Request added successfully");
  //       setTimeout(() => {
  //         navigate("/pr-request");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error saving PR request:", error.message);
  //     setSeverity("error");
  //     showSnackbar("Failed to add PR Request");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const validateForm = () => {
    const newErrors = {};
    if (!prRequestData.department_name) {
      newErrors.department_name = "Department is required";
    }
    if (!prRequestData.pr_type) {
      newErrors.pr_type = "PR Type is required";
    }
    if (!prRequestData.qty) {
      newErrors.qty = "Quantity is required";
    }
    if (!prRequestData.est_unit_rate) {
      newErrors.est_unit_rate = "Estimated Unit Rate is required";
    }
    if (!prRequestData.vendor_name) {
      newErrors.vendor_name = "Preferred Vendor is required";
    }
    if (!selectedDeliveryDate) {
      newErrors.expected_delivery_date = "Expected Delivery Date is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      // Stop if validation fails
      showSnackbar("Please fill all required fields");
      return;
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      const payload = {
        ...prRequestData,
        expected_delivery_date: selectedDeliveryDate
          ? format(selectedDeliveryDate, "yyyy-MM-dd")
          : "",
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      // Append attachment files to FormData
      if (attachmentFile) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
          formData.append("filetype", "pr_specification");
        });
      }

      const response = await axios.post(
        `${API_URL}/api/add_pr_request`,
        formData, // Send FormData instead of JSON
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data", // Set content type for file upload
          },
        }
      );

      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("PR Request added successfully");
        setTimeout(() => {
          navigate("/pr-request");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving PR request:", error.message);
      setSeverity("error");
      showSnackbar("Failed to add PR Request");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setPrRequestData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      department_name: '',
    }));
  };

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setPrRequestData((prevFormData) => ({
      ...prevFormData,
      vendor_name: selectedOption ? selectedOption.label : "",
      vendor_id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      vendor_name: '',
    }));
  };
  const handleSelectChange = (selectedOption) => {
    setPrRequestData({
      ...prRequestData,
      pr_type: selectedOption.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      pr_type: '',
    }));
  };

  const fetchVendors = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_vendors`, {
        headers: {
          Authorization: authKey,
        },
      });
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    setAttachmentFile((prevFiles) => [...(prevFiles || []), ...files]);
  };

  const handleIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleDeliveryDateChange = (date) => {
    setSelectedDeliveryDate(date);
    setPrRequestData((prevState) => ({
      ...prevState,
      expected_delivery_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expected_delivery_date: '',
    }));
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    setSelectedFiles(
      selectedFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleGenerateRequest = async () => {
    if (!validateForm()) {
      // Stop if validation fails
      showSnackbar("Please fill all required fields");
      return;
    }
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Prepare payload with all necessary data
      const payload = {
        ...prRequestData,
        expected_delivery_date: selectedDeliveryDate
          ? format(selectedDeliveryDate, "yyyy-MM-dd")
          : "",
      };
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      // Append attachment files to FormData
      if (attachmentFile) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
          formData.append("filetype", "pr_specification");
        });
      }

      const response = await axios.post(
        `${API_URL}/api/add_direct_pr_request`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Request sent successfully");
        setTimeout(() => {
          navigate("/pr-request");
        }, 1000);
      } else {
        console.error("Error sending request");
      }
    } catch (error) {
      console.error("An error occurred while sending the request:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New PR Request
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                    {errors.department_name && <div className="error-message">{errors.department_name}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="pr_type">
                    PR Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="pr_type"
                    value={prTypeOptions.find(
                      (option) => option.value === prRequestData.pr_type
                    )}
                    onChange={handleSelectChange}
                    options={prTypeOptions}
                    placeholder="Select PR Type"
                  />
                   {errors.pr_type && <div className="error-message">{errors.pr_type}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="mat_code">Mat Code</label>
                 
                    {/* <input
                      type="text"
                      name="mat_code"
                      value={prRequestData.mat_code}
                      onChange={handleInputChange}
                      placeholder="Enter mat code"
                    /> */}
                      <Select
                    id="mat_code"
                    options={matCodes}
                    value={selectedMatCode}
                    onChange={handleMatCodeChange}
                    placeholder="Select Mat code"
                    isSearchable
                  />
                  {errors.mat_code && <div className="error-message">{errors.department_name}</div>}
                </div>
                <div className="form-group-select">
                  <label>Unit</label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find((option) => option.value === prRequestData.unit)}
                    onChange={handleUnitChange}
                    placeholder="Select Unit"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="qty">
                    Quantity <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="qty"
                      value={prRequestData.qty}
                      onChange={handleInputChange}
                      placeholder="Enter quantity"
                    />
                    
                  </div>
                  {errors.qty && <div className="error-message">{errors.qty}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="est_unit_rate">
                    Estimated Unit Rate (E.U.R)
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="est_unit_rate"
                      value={prRequestData.est_unit_rate}
                      onChange={handleInputChange}
                      placeholder="Enter unit rate"
                    />
                  </div>
                  {errors.est_unit_rate && <div className="error-message">{errors.est_unit_rate}</div>}
                </div>
                <div className="form-group-select">
                  <label htmlFor="preferred_vendor">
                    Preferred Vendor<span className="text-danger">*</span>
                  </label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="preferred_vendor"
                      value="OEM"
                      // className="radio-check"
                      checked={prRequestData.preferred_vendor === "OEM"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="oem">OEM</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="preferred_vendor"
                      value="Open Market"
                      checked={prRequestData.preferred_vendor === "Open Market"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="openMarket">OpenMarket</label>
                  </div>
                  {errors.preferred_vendor && <div className="error-message">{errors.preferred_vendor}</div>}
                </div>
                {vendorType === "OEM" && (
                  <div className="form-group-select">
                    <label htmlFor="vendor_name">
                      Preferred Vendor Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="vendor_name"
                      options={vendors}
                      value={selectedVendor}
                      onChange={handleVendorChange}
                      placeholder="Select vendor name"
                      isSearchable
                    />
                        {errors.vendor_name && <div className="error-message">{errors.vendor_name}</div>}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="expected_delivery_date">
                    Expected Delivery Date{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDeliveryDate}
                      onChange={handleDeliveryDateChange}
                      name="expected_delivery_date"
                      id="expected_delivery_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select delivery date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                    
                  </div>
                  {errors.expected_delivery_date && <div className="error-message">{errors.expected_delivery_date}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Description
                  </label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      value={prRequestData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Technical Specification Attachement{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save as Draft"}
              </button>
              <button
                className="request-button"
                onClick={() => handleGenerateRequest()}
              >
                Send Request
              </button>

              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequestForm;
