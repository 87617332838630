import React, { useState } from "react";
import AddMenuItemAccess from "./AddMenuItemAccess";
import UpdateMenuItemAccess from "./UpdateMenuItemAccess";

const MenuItemAccessPage = () => {
  const [isAddMode, setIsAddMode] = useState(true);
  const [accessId, setAccessId] = useState(null);

  const handleSwitchMode = () => {
    setIsAddMode(!isAddMode);
    setAccessId(null);
  };

  const handleEditAccess = (id) => {
    setIsAddMode(false);
    setAccessId(id);
  };

  return (
    <div>
      <button onClick={handleSwitchMode}>
        {isAddMode ? "Switch to Update Mode" : "Switch to Add Mode"}
      </button>
      {isAddMode ? (
        <AddMenuItemAccess onClose={() => setIsAddMode(true)} />
      ) : (
        <UpdateMenuItemAccess accessId={accessId} onClose={() => setIsAddMode(true)} />
      )}
    </div>
  );
};

export default MenuItemAccessPage;
